import React from "react";
import StatusChip from "./StatusChip";

const loanStatus = {
    TO_TRANSFER: 'to transfer',
    TO_COLLECT: 'to collect',
    COLLECTING: 'collecting',
    PAID: 'paid',
    PARTIAL_PAID: 'partial paid',
    LOST: 'lost'
}

/**
 * # LoanStatusChip
 * @param {String} status to transfer | to collect | collecting | paid | partial paid | lost 
 * @returns 
 */
const LoanStatusChip = ({status}) => {
    function getChipType(status) {
        return status == loanStatus.LOST ? 'error' : 
               status == loanStatus.COLLECTING ? 'info' : 
               status == loanStatus.PAID ? 'success' : 
               status == loanStatus.PARTIAL_PAID ? 'warning' : 
               'others'
    }
    return <StatusChip type={getChipType(status)} text={status} />
}

export default LoanStatusChip;