import React from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import './quill.css'

const ReactQuillCustom = (props) => {
    const [val, setVal] = React.useState('');
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "list",
        "link",
        "video",
        "image",
        "color",
    ];

    const modules = {
        toolbar: {
            container: "#toolbar",
        },
        clipboard: {
            matchVisual: false,
        },
    };
    const handleOnChange = (value) => {
        const text = value.replace(/<[^>]+>/g, '').trim();
        if (text.length <= props.maxCharacters) {
            props.handler(value)
        } else {
            props.onExceedCharacter()
        }
        setVal(value)
    }

    React.useEffect(() => {
        setVal(props.initialValue);
    }, [props.initialValue])

    return (
        <div>
            <div id="toolbar">
                <select className="ql-header">
                    <option value="1"></option>
                    <option value="2"></option>
                    <option value="3"></option>
                    <option selected></option>
                </select>
                <button className="ql-bold"></button>
                <button className="ql-italic"></button>
                <select className="ql-color"></select>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-link"></button>
                <button className="ql-image"></button>
                <button className="ql-video"></button>
            </div>
            <ReactQuill
                theme="snow"
                className={props.error ? "ql-error" : "ql-box"}
                value={val}
                formats={formats}
                modules={modules}
                style={{
                    width: "100%",
                    minWidth: '450px',
                    borderColor: props.error ? "red" : "",
                }}
                placeholder={props.placeholder}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default
    ReactQuillCustom