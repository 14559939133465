import { useSelector } from 'react-redux';
import useSettlementActions from './_actions';

export const useSettlement = () => {
    const {
        downloadOrderImage,
        deleteOrderImage,
        uploadOrderImage,
        fetchSettlementList,
        completeSettlement,
        getSettlementDetails,
        getSettlementTransactionHistory,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeStatusFilter,
        changeCommitteeFilter,
        fetchSettlementListByShopId,
        fetchSettlementListByCommitteeId,
        createNewSettlement,
        createNewOrder,
        editSettlement,
        changeCurrentPage, 
        changeRowsPerPage,
        refreshDialogState,
        changeToReady,
    } = useSettlementActions();

    const settlements = useSelector(state => state.settlement.data);
    const searchTerm = useSelector(state => state.settlement.searchTerm);
    const regionCodeFilter = useSelector(state => state.settlement.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.settlement.filters.township_code);
    const statusFilter = useSelector(state => state.settlement.filters.status)
    const committeeFilter = useSelector(state => state.settlement.filters.committee_id);
    const startDate = useSelector(state => state.settlement.filters.startDate);
    const endDate = useSelector(state => state.settlement.endDate);
    const currentPage = useSelector(state => state.settlement.currentPage);
    const totalRows = useSelector(state => state.settlement.totalRows);
    const rowsPerPage = useSelector(state => state.settlement.rowsPerPage);
    const isLoading = useSelector(state => state.settlement.isLoading);
    const isError = useSelector(state => state.settlement.isError);
    const errorMessage = useSelector(state =>state.settlement.errorMessage);
    const apiAction = useSelector(state => state.settlement.action);

    return {
        downloadOrderImage,
        deleteOrderImage,
        uploadOrderImage,
        fetchSettlementList,
        completeSettlement,
        getSettlementDetails,
        getSettlementTransactionHistory,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeStatusFilter,
        changeCommitteeFilter,
        fetchSettlementListByShopId,
        fetchSettlementListByCommitteeId,
        createNewSettlement,
        createNewOrder,
        editSettlement,
        changeCurrentPage,
        changeRowsPerPage,
        refreshDialogState,
        changeToReady,

        settlements,
        searchTerm,
        regionCodeFilter,
        townshipCodeFilter,
        statusFilter,
        committeeFilter,
        startDate,
        endDate,
        currentPage,
        rowsPerPage,
        totalRows,
        isLoading,
        isError,
        errorMessage,
        apiAction
    }
}

export { default as settlementReducer } from './_reducers';