import { createSlice } from "@reduxjs/toolkit";

const villageSlice = createSlice({
    name: 'village',
    initialState: {
        data: [],
        totalRows: 0,
        currentPage: 0, 
        rowsPerPage: 5,
        searchTerm: '',
        filters: {
            region_code: '',
            township_code: '',
            villageTract_code: '',
        },
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        },
        isLoading: false,
        isError: false,
        errorMessage: ''
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setRegionCodeFilter: (state, action) => {
            state.filters.region_code = action.payload;
        },
        setTownshipCodeFilter: (state, action) => {
            state.filters.township_code = action.payload;
        },
        setVillageTractCodeFilter: (state, action) => {
            state.filters.villageTract_code = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            action.payload.data && (state.data = action.payload.data);
            action.payload.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },

        fetchingDataForDownload: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchingDataForDownloadSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
        },
        fetchingDataForDownloadFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },

        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
    }
});

export const {
    setData,
    setTotalRows,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageTractCodeFilter,
    
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,

    fetchingDataForDownload,
    fetchingDataForDownloadSuccess,
    fetchingDataForDownloadFail,

    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState,
} = villageSlice.actions;

export default villageSlice.reducer;