import { createSlice } from "@reduxjs/toolkit";

const fundReceivingSlice = createSlice({
      name: 'fundReceiving',
      initialState: {
            data: [],

            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,

            searchTerm: '',
            filters: {
                  start_date: '',
                  end_date: ''
            },

            isLoading: false,
            isError: false,
            errorMessage: '',
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },

            allocation:{
                  totalRows: 0,
                  currentPage: 0,
                  rowsPerPage: 5,

                  searchTerm: '',
                  filters: {
                        start_date: '',
                        end_date: '',
                        branch_id: '',
                  },

                  isLoading: false,
                  isError: false,
                  errorMessage: '',
                  action: {
                        isLoading: false,
                        isError: false,
                        errorMessage: ''
                  },
            }
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            // Fetching Data 
            fetchingData: (state) => {
                  state.isLoading = true;
                  state.isError = false;
            },
            fetchDataSuccess: (state, action) => {
                  action.payload.data && (state.data = action.payload.data);
                  action.payload.total_items && (state.totalRows = action.payload.total_items);
                  state.isLoading = false;
                  state.isError = false;
            },
            fetchDataFail: (state, action) => {
                  state.isLoading = false;
                  state.isError = true;
                  state.errorMessage = action.payload;
            },

            // Create New
            submittingData: (state) => {
                  state.action.isLoading = true;
                  state.action.isError = false;
            },
            submitDataSuccess: (state) => {
                  state.action.isLoading = false;
                  state.action.isError = false;
                  state.action.errorMessage = '';
            },
            submitDataFail: (state, action) => {
                  state.action.isLoading = false;
                  state.action.isError = true;
                  state.action.errorMessage = action.payload;
            },

            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            setStartDate: (state, action) => {
                  state.filters.start_date = action.payload;
            },
            setEndDate: (state, action) => {
                  state.filters.end_date = action.payload;
            },
            reloadDialogState: (state) => {
                  state.action = { isLoading: false, isError: false, errorMessage: '' };
            },



            // fundAllocation

            setAllocationData: (state, action) => {
                  state.allocation.data = action.payload;
            },
            // Fetching Data 
            fetchingAllocationData: (state) => {
                  state.allocation.isLoading = true;
                  state.allocation.isError = false;
            },
            fetchAllocationDataSuccess: (state, action) => {
                  action.payload.data && (state.allocation.data = action.payload.data);
                  action.payload.total_items && (state.allocation.totalRows = action.payload.total_items);
                  state.allocation.isLoading = false;
                  state.allocation.isError = false;
            },
            fetchAllocationDataFail: (state, action) => {
                  state.allocation.isLoading = false;
                  state.allocation.isError = true;
                  state.allocation.errorMessage = action.payload;
            },

            setAllocationCurrentPage: (state, action) => {
                  state.allocation.currentPage = action.payload;
            },
            setAllocationRowsPerPage: (state, action) => {
                  state.allocation.rowsPerPage = action.payload;
            },
            setAllocationSearchTerm: (state, action) => {
                  state.allocation.searchTerm = action.payload;
            },
            setAllocationStartDate: (state, action) => {
                  state.allocation.filters.start_date = action.payload;
            },
            setAllocationEndDate: (state, action) => {
                  state.allocation.filters.end_date = action.payload;
            },
            setBranchFilter: (state, action) => {
                  state.allocation.filters.branch_id = action.payload;
            },
            
      }
});

export const {
      // Fetching List
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Create New
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setData,
      setSearchTerm,
      setCurrentPage,
      setRowsPerPage,
      setStartDate,
      setEndDate,

      setBranchFilter,

      setAllocationData,
      fetchingAllocationData,
      fetchAllocationDataSuccess,
      fetchAllocationDataFail,
      setAllocationCurrentPage,
      setAllocationRowsPerPage,
      setAllocationSearchTerm,
      setAllocationStartDate,
      setAllocationEndDate,
      
} = fundReceivingSlice.actions;

export default fundReceivingSlice.reducer;