import React from 'react';
import { Card } from '@mui/material';
import LoadingBox from '../Box/LoadingBox';
import ErrorBox from '../Box/ErrorBox';

const style = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: 220,
      width: '100%',
      marginBottom: 30,
}

/**
 * # SessionBox
 * @param {*} width 
 * @param {*} isLoading
 * @param {Boolean} isError
 * @param {Function} onRetry
 * @param {*} height
 * @param {*} disableHeader
 * @param {*} title
 * @returns SessionBox
 */
const SessionBox = ({ children, height, isLoading, isError, errorMessage, onRetry }) => {
      return (
            <Card id="SessionBox" 
                  sx={{...style, backgroundColor: (isLoading || isError) ? 'transparent' : 'default', marginBottom: '40px'}} 
                  style={{ height: height ?? (isLoading || isError) ? '90%' : '' }} 
                  elevation={1} 
            >
                  {
                        isLoading ? <LoadingBox/> : 
                              isError ? <ErrorBox errorMsg={errorMessage} onError={() => { typeof onRetry === 'function' && onRetry() }} /> : 
                                    children

                  }
            </Card >
      );
};

export default SessionBox;
