import React from 'react';
import { Box } from '@mui/system';
import { List } from '@mui/material';
//LOCAL
import MenuListItem from '../components/Menu/MenuListItem';
import { useEcommerceOrder, useUser } from '../features';
import { useLocation } from 'react-router-dom';
// import ROUTES from "../configs/_routes";

// const schema = [
//       {
//             menuName: 'Finance Dashboard',
//             iconName: 'dashboard',
//             isMenuShow: true,
//             isDefault: true,
//             fe_route: 'finance-dashboard'
//       },
//       {
//             menuName: 'Admin Dashboard',
//             iconName: 'dashboard_customize',
//             isMenuShow: true,
//             fe_route: 'admin-dashboard'
//       },
//       {
//             menuName: 'Locations',
//             iconName: 'place',
//             isMenuShow: true,
//             children: [
//                   {
//                         collectionName: 'region',
//                         menuName: 'Region',
//                         isMenuShow: true,
//                         iconName: 'public',
//                         fe_route: 'regions',
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'township',
//                         menuName: 'Township',
//                         isMenuShow: true,
//                         iconName: 'location_city',
//                         fe_route: 'township',
//                         actions: {
//                               TownshipList: true,
//                               TownshipDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'villageTract',
//                         menuName: 'Village Tract',
//                         isMenuShow: true,
//                         iconName: 'park',
//                         fe_route: 'village-tracts',
//                         actions: {
//                               TownshipList: true,
//                               TownshipDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'office',
//                         menuName: 'office',
//                         isMenuShow: true,
//                         iconName: 'work',
//                         fe_route: 'office',
//                         actions: {
//                               TownshipList: true,
//                               TownshipDetail: true
//                         }
//                   },
//             ],
//       },
//       {
//             menuName: 'Manage Accounts',
//             iconName: 'manage_accounts',
//             isMenuShow: true,
//             children: [
//                   {
//                         collectionName: 'portalUsers',
//                         menuName: 'Web Users',
//                         iconName: 'account_circle',
//                         fe_route: 'portal-users',
//                         isMenuShow: true,
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'committees',
//                         menuName: 'Committee',
//                         iconName: 'diversity_3',
//                         fe_route: 'committees',
//                         isMenuShow: true,
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'members',
//                         menuName: 'Member',
//                         iconName: 'diversity_2',
//                         fe_route: 'members',
//                         isMenuShow: true,
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'shop',
//                         menuName: 'Shop',
//                         iconName: 'store',
//                         fe_route: 'shops',
//                         isMenuShow: true,
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   },
//             ]
//       },
//       {
//             menuName: 'Permission',
//             iconName: 'admin_panel_settings',
//             isMenuShow: true,
//             children: [
//                   {
//                         collectionName: 'portalUserRoles',
//                         menuName: 'Roles',
//                         iconName: 'settings_account_box',
//                         fe_route: 'portalUserRoles',
//                         isMenuShow: true,
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   },
//                   {
//                         collectionName: 'schema',
//                         menuName: 'Schema',
//                         iconName: 'account_tree',
//                         fe_route: 'schema',
//                         isMenuShow: false,
//                         actions: {
//                               RegionList: true,
//                               RegionDetail: true
//                         }
//                   }
//             ]
//       },

//       {
//             menuName: 'Deposit',
//             iconName: 'monetization_on',
//             isMenuShow: true,
//       },
//       {
//             menuName: 'Loan',
//             iconName: 'savings',
//             isMenuShow: true,
//       },
//       {
//             menuName: 'Settlement',
//             iconName: 'currency_exchange',
//             isMenuShow: true,
//       },
//       {
//             menuName: 'Manage Product',
//             iconName: 'inventory_2',
//             isMenuShow: true,
//             children: [
//                   {
//                         collectionName: 'globalProduct',
//                         menuName: 'Products List',
//                         isMenuShow: true,
//                         iconName: 'list_alt',
//                         actions: {
//                         }
//                   },
//                   {
//                         collectionName: 'productCategory',
//                         menuName: 'Category',
//                         isMenuShow: true,
//                         iconName: 'category',
//                         actions: {
//                         }
//                   },
//             ],
//       },
// ]

const LeftNavigation = () => {
      const {permission_schema, userData} = useUser();
      const {pathname} = useLocation();
      const {getNewOrderCount} = useEcommerceOrder();

      React.useEffect(() => {
            if(userData.role === 'E-Commerce Admin' || userData.role === 'Admin') {
                 getNewOrderCount();
            }
      }, [pathname])

      return (
            <Box sx={{ flexGrow: 1, flexDirection: 'column', overflowX: 'hidden', overflowY: 'auto', '::-webkit-scrollbar': { display: 'none' } }}>
                  <nav>
                        <List sx={{ width: '100%', height: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                              {
                                    permission_schema.map((item, index) =>
                                          item.isMenuShow &&
                                          <MenuListItem
                                                key={index}
                                                route={item.fe_route}
                                                icon={item.iconName}
                                                title={item.menuName}
                                                child={item.children?.filter(x => x.isMenuShow === true) ?? []} />
                                    )
                              }
                        </List>
                  </nav>
            </Box >
      )
}

export default LeftNavigation;
