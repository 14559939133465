import { createSlice } from "@reduxjs/toolkit";

const standardSchemaSlice = createSlice({
      name: 'standard_schema',
      initialState: {
            data: [],
            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,
            // searchTerm: '',
            isLoading: false,
            isError: false,
            errorMsg: '',
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setTotalRows: (state, action) => {
                  state.totalRows = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            fetchingData: (state) => {
                  state.isLoading = true;
                  state.isError = false;
            },
            fetchDataSuccess: (state) => {
                  state.isLoading = false;
                  state.isError = false;
            },
            fetchDataFail: (state, action) => {
                  state.isLoading = false;
                  state.isError = true;
                  state.errorMsg = action.payload
            }
      }
});

export const {
      setData,
      setCurrentPage,
      setRowsPerPage,
      setTotalRows,
      setSearchTerm,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail
} = standardSchemaSlice.actions;

export default standardSchemaSlice.reducer;