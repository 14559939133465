import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { formatVal } from '../../utils/string';

const ReadOnlyTextBox = ({ label, value, valueType, style={} }) => {
      return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px', ...style }}>
                  <Typography variant="h5" sx={{ textTransform: 'capitalize' }} mb={0} fontSize="14px" fontWeight={400}>{label}</Typography>
                  <Typography variant="body1" mb={1} mt={0}color={'gray'}>{formatVal(value, valueType)}</Typography>
            </Box>
      )
}

export default ReadOnlyTextBox;