import { useSelector } from "react-redux";
import useFundAllocationActions from "./_actions";

export const useFundAllocation = () => {
      const {
            // Actions
            getFundAllocationListByContractId,
            fetchFundAllocationList,
            fetchFundAllocationTransaction,
            getFundAllocationDetail,
            createFundAllocation,
            refreshDialogState,
            getAllocationByOfficeId,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeBranchFilter
      } = useFundAllocationActions();

      const fundAllocation = useSelector(state => state.fundAllocation.data);
      const currentPage = useSelector(state => state.fundAllocation.currentPage);
      const totalRows = useSelector(state => state.fundAllocation.totalRows);
      const rowsPerPage = useSelector(state => state.fundAllocation.rowsPerPage);
      const isLoading = useSelector(state => state.fundAllocation.isLoading);
      const isError = useSelector(state => state.fundAllocation.isError);
      const errorMessage = useSelector(state => state.fundAllocation.errorMessage);
      const searchTerm = useSelector(state => state.fundAllocation.searchTerm);
      const start_date = useSelector(state => state.fundAllocation.filters.start_date);
      const end_date = useSelector(state => state.fundAllocation.filters.end_date);
      const apiAction = useSelector(state => state.fundAllocation.action);
      const branchFilter = useSelector(state => state.fundAllocation.filters.branch_id);

      const dialogStates = useSelector(state => state.fundAllocation.dialogStates);

      return {
            //state
            fundAllocation,
            currentPage,
            totalRows,
            rowsPerPage,
            isLoading,
            isError,
            errorMessage,
            searchTerm,

            start_date,
            end_date,
            apiAction,
            dialogStates,
            branchFilter,

            //actions
            getFundAllocationListByContractId,
            fetchFundAllocationList,
            fetchFundAllocationTransaction,
            getFundAllocationDetail,
            createFundAllocation,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            refreshDialogState,
            changeBranchFilter,
            getAllocationByOfficeId
      }
}

export { default as fundAllocationReducers } from './_reducers';