import { useSelector } from 'react-redux';
import useStaticPageActions from './_actions';

export const useStaticPage = () => {
    const {
        fetchStaticPageList,
        createStaticPage,
        updateStaticPage,
        changeCurrentPage,
        changeRowsPerPage,
        refreshDialogState
    } = useStaticPageActions();

    const staticPages = useSelector(state => state.static_page.data);
    const currentPage = useSelector(state => state.static_page.currentPage);
    const totalRows = useSelector(state => state.static_page.totalRows);
    const rowsPerPage = useSelector(state => state.static_page.rowsPerPage);
    const isLoading = useSelector(state => state.static_page.isLoading);
    const isError = useSelector(state => state.static_page.isError);
    const errorMessage = useSelector(state => state.static_page.errorMessage);
    const apiAction = useSelector(state => state.static_page.action);

    return {
        staticPages,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        apiAction,

        fetchStaticPageList,
        createStaticPage,
        updateStaticPage,
        changeCurrentPage,
        changeRowsPerPage,
        refreshDialogState
    }
}

export { default as staticPageReducer } from './_reducers';