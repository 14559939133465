import { useDispatch, useSelector } from "react-redux";
import {
    // resetData,
    setCurrentPage,
    setSearchTerm,
    setRowsPerPage,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setStartDate,
    setEndDate,
    setStatusFilter,
    setOfficeFilter,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState
} from './_reducers';
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import { useNoti } from "../../providers";
// import { requestOneTimeToken } from "../../utils/helper";

const useRevenueTransfersActions = () => {
    const { showNoti } = useNoti();

    const dispatch = useDispatch();

    
    // const revenueTransfers = useSelector(state => state.revenueTransfers.data);
    const currentPage = useSelector(state => state.revenueTransfers.currentPage);
    const rowsPerPage = useSelector(state => state.revenueTransfers.rowsPerPage);
    const searchTerm = useSelector(state => state.revenueTransfers.searchTerm);
    const statusFilter = useSelector(state => state.revenueTransfers.filters.status);
    const start_date = useSelector(state => state.revenueTransfers.filters.start_date);
    const end_date = useSelector(state => state.revenueTransfers.filters.end_date);
    const officeFilter = useSelector(state => state.revenueTransfers.filters.office_id);

    function getRevenueTransfersList() {
        dispatch(fetchingData());

        const getRevenueTransfersListUrl = `${API_URL.RevenueTransfers}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}&filters[office_id]=${officeFilter}`;
        ApiRequest.get(getRevenueTransfersListUrl).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;

            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

    // async function fetchRevenueList() {
    //     dispatch(resetData());
    //     const url = `${API_URL.RevenueTransfers}/revenue`;
    //     fetchingData();
    //     ApiRequest.get(url).then(res => {
    //         const data = res.data.payload.data;
    //         const { total_items } = res.data.payload.metadata;
    //         dispatch(fetchDataSuccess({ data, total_items }));
    //     }).catch(err => {
    //         console.error(err);
    //         dispatch(fetchDataFail(err.message));
    //     })
    // }

    async function createNewRevenueTransfers(revenue_data) {
        dispatch(submittingData());

        try {
            const url = API_URL.RevenueTransfers;
            const res = await ApiRequest.post(url, revenue_data);
            const data = res.data.payload.data;
            dispatch(submitDataSuccess());
            showNoti('Revenue transfer success', 'success');
            getRevenueTransfersList();
            return data;
        }
        catch(err) {
            // dispatch(submitDataFail());
            let errMsg = err.response?.data.errors.message ?? err.message;
            const errData = err.response?.data.errors.data;
            if(errData) {
                errMsg = Object.entries(errData).reduce((str, [key, val]) => {
                    str += val;
                    console.error(key, val);
                    return str;
                }, "");
            }
            // showNoti(errMsg, 'error');
            dispatch(submitDataFail(errMsg));
        }
    }

    // function getRevenueTransfersDetails(id) {
    //     return revenueTransfers.filter((d) => d._id == id)[0]
    // }

    async function getRevenueTransfersDetails(revenueId,  apiOptions={}) {

        dispatch(fetchingData());
        try {
              const url = `${API_URL.RevenueTransfers}/${revenueId}`;
              const res = await ApiRequest.get(url,  {...apiOptions});
              const revenueTransfers = res.data.payload.data;
              dispatch(fetchDataSuccess());
              return revenueTransfers;
        }
        catch(err) {
            dispatch(fetchDataFail(`Failed to get revenue transfer detail! ${err.message}`));
            return Promise.reject();
        }
  }


    async function completeRevenueTransfer(revenueId) {
        dispatch(submittingData());
        try {
              const url = `${API_URL.RevenueTransfers}/${revenueId}/submit`;
              await ApiRequest.post(url);
              showNoti('Revenue Transfer is successfully submitted', 'success');
              dispatch(submitDataSuccess());
              return Promise.resolve();
        }
        catch (err) {
              console.error(err);
              const errMsg = `Error! ${err.response?.data.errors.message ?? err.message}`;
              showNoti(errMsg, 'error');
              dispatch(submitDataFail());
              return Promise.reject();
        }
    }

    async function getRevenueTransferHistory(revenueId) {
        try {
            const url = `${API_URL.RevenueTransfers}/${revenueId}/transaction_history?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
            const res = await ApiRequest.get(url);
            const revenueData = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ revenueData, total_items }));
            return revenueData;
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            showNoti(errMsg, 'error');
        }
    }

    function changeStatusFilter(status) {
        dispatch(setStatusFilter(status));
    }

    function changeOfficeFilter(officeName) {
        dispatch(setCurrentPage(0));
        dispatch(setOfficeFilter(officeName));
    }

    function changeSearchTerm(search_key) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key));
    }

    function changeStartDate(date) {
        dispatch(setStartDate(date));
    }

    function changeEndDate(date) {
        dispatch(setEndDate(date));
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }


    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        getRevenueTransferHistory,
        getRevenueTransfersList,
        getRevenueTransfersDetails,
        // fetchRevenueList,
        completeRevenueTransfer,
    
        createNewRevenueTransfers,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeStatusFilter,
        changeOfficeFilter,
        refreshDialogState
    }
}

export default useRevenueTransfersActions;