import { createSlice } from "@reduxjs/toolkit";

const breadCrumbSlice = createSlice({
    name: 'breadCrumb',
    initialState: {
        steps: { A: null, B: null, C: null, D: null }
    },
    reducers: {
        setStep: (state, action) => {
            state.steps = action.payload
        }
    }
});

export const {
    setStep,
} = breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;