import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ErrorBox = (props) => {
      return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: 'transparent'}}>
                  <img style={{ width: 120, height: 120 }} alt={'img'} src={'/images/totoro/crying.gif'} />
                  <Typography>{props.errorMsg}</Typography>
                  <Button variant='contained' color='error' onClick={props.onError}>Try Again</Button>
            </Box>
      )
};

export default ErrorBox;