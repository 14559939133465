import { useDispatch, useSelector } from "react-redux";
import ApiRequest from '../../utils/apiRequest';
import { API_URL } from '../../configs/_api';
import { useNoti } from '../../providers';
import { asymmetricEncrypt } from '../../utils/crypt';
import * as XLSX from 'xlsx';

import {
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setStatusFilter,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageCodeFilter,
    setVillageTractCodeFilter,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState,

    setSettlementCurrentPage,
    setSettlementRowsPerPage,
    // setSettlementData,
    setSettlementSearchTerm,


    fetchSettlementDataFail,
    fetchSettlementDataSuccess,
    fetchingSettlementData,

} from "./_reducers";
import { committeeColumns } from "../../configs/_dataColumnConfig" // for column
import useDataExportActions from "../data_exports/_actions";
import { writeOutExcelFile } from "../../utils/xlsxWorkBook";

const useCommitteeActions = () => {
    const dispatch = useDispatch();
    const { showNoti } = useNoti();
    const { moduleParam, getFilteredData } = useDataExportActions();

    const currentPage = useSelector(state => state.committee.currentPage);
    const rowsPerPage = useSelector(state => state.committee.rowsPerPage);
    const searchTerm = useSelector(state => state.committee.searchTerm);
    const statusFilter = useSelector(state => state.committee.filters.status);
    const regionCodeFilter = useSelector(state => state.committee.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.committee.filters.township_code);
    const villageTractCodeFilter = useSelector(state => state.committee.filters.village_tract_code);
    const villageCodeFilter = useSelector(state => state.committee.filters.village_code);

    // Settlement
    const settlementSearchTerm = useSelector(state => state.committee.settlement.searchTerm);
    const settlementCurrentPage = useSelector(state => state.committee.settlement.currentPage);
    const settlementRowsPerPage = useSelector(state => state.committee.settlement.rowsPerPage);
    // const settlementCommitteeFilter = useSelector(state => state.committee.settlement.filters.committee_id);


    async function fetchSettlementListByCommitteeId(committeeId) {
        // dispatch(resetData());
        const url = `${API_URL.Settlement}/?search_term=${settlementSearchTerm}&page_no=${settlementCurrentPage + 1}&page_size=${settlementRowsPerPage}&filters[committee_id]=${committeeId}`;
        fetchingSettlementData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchSettlementDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchSettlementDataFail(err.message));
        })
    }
    

    function fetchCommitteeList() {
        dispatch(fetchingData());

        let url = `${API_URL.Committee}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}&filters[village_tract_code]=${villageTractCodeFilter}&filters[village_code]=${villageCodeFilter}`;

        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;
            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

    async function getCommitteeListByLocation(regionCode='', townshipCode='', villageTractCode='', villageCode='') {
        const url = `${API_URL.Committee}?filters[region_code]=${regionCode}&filters[township_code]=${townshipCode}&filters[village_tract_code]=${villageTractCode}&filters[village_code]=${villageCode}`;
        try {
            const res = await ApiRequest.get(url);
            return res.data.payload?.data;
        }
        catch(err) {
            return Promise.reject(err);
        }
    }

    async function addCommittee(committee) {
        const url = API_URL.Committee;
        const data = { ...committee,  pin_code: asymmetricEncrypt(committee.pin_code)};

        dispatch(submittingData());
        try {
            let id = null;
            await ApiRequest.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => id = res.data.payload.data._id);
            showNoti('New committee created successfully!', 'success');
            dispatch(submitDataSuccess());
            fetchCommitteeList();
            return Promise.resolve(id);
        }
        catch(err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            dispatch(submitDataFail(err.response?.data.errors.message ?? 'Creating Committee failed!'));
            return Promise.reject(errors);
        }
    }

    async function updateCommittee(committee) {
        let edit_committee_url = API_URL.Committee + "/edit";

        try {
            const res = await ApiRequest.post(edit_committee_url, committee,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            const editCommittee = res.data;
            return editCommittee;
        } catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `${err.message}. Fail to Edit!`;
            return Promise.reject(errMsg);
        }
    }

    async function createMultipleCommittees(file) {
        const url = `${API_URL.Committee}/batch`;
        const data = new FormData();
        data.append('file', file);

        try {
            dispatch(submittingData());
            const res = await ApiRequest.post(url, data);
            dispatch(submitDataSuccess());
            return res.data;
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            dispatch(submitDataFail());
            return Promise.reject(errMsg);
        }
    }

    /**
     * 
     * @param {String} committeeId 
     */
    async function getCommitteeDetail(committeeId, apiOptions={}) {
        const url = `${API_URL.Committee}/${committeeId}`;
        dispatch(fetchingData());
        try {
            const res = await ApiRequest.get(url, {...apiOptions});
            const committee = res.data.payload.data;
            dispatch(fetchDataSuccess());
            return committee;
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? err.message;
            dispatch(fetchDataFail(errMsg));
            return Promise.reject(errMsg);
        }
    }

    async function getCommitteeDashboardData(committeeId, apiOptions={}) {
        const url = `${API_URL.Committee}/${committeeId}/dashboard`;
        dispatch(fetchingData());
        try {
            const res = await ApiRequest.get(url, {...apiOptions});
            const committee = res.data.payload.data;
            dispatch(fetchDataSuccess());
            return committee;
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? err.message;
            dispatch(fetchDataFail(errMsg));
            return Promise.reject(errMsg);
        }
    }

    async function getCommitteeBalance(committeeId, apiOptions={}) {
        const url = `${API_URL.Committee}/${committeeId}/balance`;
        try {
            const res = await ApiRequest.get(url, {...apiOptions});
            return res.data.payload.data;
        }
        catch(err) {
            console.error(err);
            showNoti('Getting balance failed', 'error');
        }
    }

    async function getCommitteeActiveMemberCount(committee_id, apiOptions={}) {
        const url = `${API_URL.Committee}/${committee_id}/active_member_count`;
        try {
            const res = await ApiRequest.get(url, {...apiOptions});
            return res.data.payload.data;
        } catch(err){
            showNoti('Getting active member count failed', 'error');
        }
    }

    /**
     * 
     * @param {String} committeeId 
     */
    async function resetCommitteePin(committeeId) {
        const url = `${API_URL.Committee}/${committeeId}/reset-pin`;
        dispatch(submittingData());
        try {
            const res = await ApiRequest.patch(url);
            const statusCode = res.status;
            if (statusCode == 200) {
                showNoti('Committee pin reset successfully', 'success');
            }
            else if (statusCode == 207) {
                showNoti('Committee pin has been reset successfully but fail to SMS to committee', 'warning');
            }
            dispatch(submitDataSuccess());

            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? err.message;
            showNoti(errMsg, 'error');
            dispatch(submitDataFail());
            return Promise.reject();
        }
    }

    /**
     * 
     * @param {String} committeeId 
     */
    async function deactivateCommittee(committeeId) {
        const url = `${API_URL.Committee}/${committeeId}/deactivate`;
        dispatch(submittingData());
        try {
            const res = await ApiRequest.patch(url);
            const statusCode = res.status;
            if (statusCode == 200) {
                showNoti('Deactivated committee successfully!', 'success');
            }
            else if (statusCode == 304) {
                    showNoti('Committee already deactivated!', 'error');
            }
            dispatch(submitDataSuccess());
            fetchCommitteeList();
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? `Fail to deactivate committee! ${err.message}`;
            showNoti(errMsg, 'error');
            dispatch(submitDataFail());
            return Promise.reject();
        }
    }

    /**
     * 
     * @param {String} committeeId 
     */
    async function activateCommittee(committeeId) {
        const url = `${API_URL.Committee}/${committeeId}/activate`;
        dispatch(submittingData());
        try {
            const res = await ApiRequest.patch(url);
            const statusCode = res.status;
            if (statusCode == 200) {
                showNoti('Activated committee successfully!', 'success');
            }
            else if (statusCode == 304) {
                    showNoti('Committee already activated!', 'error');
            }
            dispatch(submitDataSuccess());
            fetchCommitteeList();
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? `Fail to deactivate committee! ${err.message}`;
            showNoti(errMsg, 'error');
            dispatch(submitDataFail());
            return Promise.reject();
        }
    }

    async function changeToVerifying(committeeId) {
        const url = `${API_URL.Committee}/${committeeId}/verifying`;
        dispatch(submittingData());
        try {
            const res = await ApiRequest.patch(url);
            const statusCode = res.status;
            if (statusCode == 200) {
                showNoti('Chnage committee status to "Verifying" successfully!', 'success');
            }
            else if (statusCode == 304) {
                    showNoti('Committee already in "Verifying" status!', 'error');
            }
            dispatch(submitDataSuccess());
            fetchCommitteeList();
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? `Fail to change committee status! ${err.message}`;
            showNoti(errMsg, 'error');
            dispatch(submitDataFail());
            return Promise.reject();
        }
    }
    /**
     * @param {String} url URL that contains all required query string 
     */
    async function getRelatedMemberList(committeeId, filter={}) {
        try {
            const queryStr = Object.entries(filter).reduce((str, [key, val]) => { return `${str}&${key}=${val}` }, '');
            const url = `${API_URL.Committee}/${committeeId}/members?${queryStr}`;
            const res = await ApiRequest.get(url);
            const data = res.data.payload.data;
            return data;
        }
        catch(err) {
            console.error(err);
            return Promise.reject(err.message);
        }
    }

    async function downloadCommitteeFileUploadTemplate(columns=[]) {
        const workBook = XLSX.utils.book_new();

        const dataSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
        XLSX.utils.book_append_sheet(workBook, dataSheet, 'data');

        XLSX.writeFile(workBook, 'committeeUploadTemplate.xlsx');
    }

    async function changeCommitteePhoneNumber(committee) {
        const changePhoneNoUrl = `${API_URL.Committee}/${committee._id}/change_ph_number`;
        try {
            const res = await ApiRequest.post(changePhoneNoUrl, committee);
            return res.status;
        }
        catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `Fail to change committee phone number! ${err.message}`;
            return Promise.reject(errMsg);
        }
    }

    const exportCommitteeData = async (region_id = '', township_id='', village_id = '', village_tract_id = '') => {
        try {
            dispatch(submittingData());
            const committee = moduleParam.COMMITTEE
            const query = `module=${committee}&filter[region]=${region_id}&filter[township]=${township_id}&filter[village]=${village_id}&filter[village_tract]=${village_tract_id}`
            const url = `${API_URL.DataExport}?${query}`
            const res = await ApiRequest.post(url);
            const response = res.data;
            if(response.status === 'success') {
                const data = response.payload.data;
                const filterData = getFilteredData(data, committeeColumns)
                const sheetHeader = [committeeColumns.map(item => item.label)]

                let fileName = ''

                fileName += committee?.charAt(0).toUpperCase() + committee.slice(1)
                fileName += `.xlsx`;

                writeOutExcelFile(sheetHeader, filterData, fileName);
                dispatch(submitDataSuccess());
                showNoti('Committee list data exported successfully', 'success');
            }
        } catch (err) {
            const errorMessage = err.response?.data.errors.message || err.message;
            dispatch(submitDataFail(errorMessage));
            showNoti('Error occurred while exporting data', 'error')
            return Promise.reject(errorMessage);
        }
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }

    function changeStatusFilter(status) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(""));
        dispatch(setStatusFilter(status));
    }

    function changeRegionCodeFilter(code) {
        dispatch(setCurrentPage(0));
        dispatch(setRegionCodeFilter(code));
    }

    function changeTownshipCodeFilter(code) {
        dispatch(setCurrentPage(0));
        dispatch(setTownshipCodeFilter(code));
    }

    function changeVillageTractCodeFilter(code) {
        dispatch(setCurrentPage(0));
        dispatch(setVillageTractCodeFilter(code));
    }

    function changeVillageCodeFilter(code) {
        dispatch(setCurrentPage(0));
        dispatch(setVillageCodeFilter(code));
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    function changeSettlementSearchTerm(search_key) {
        dispatch(setSettlementCurrentPage(0));
        dispatch(setSettlementSearchTerm(search_key));
    }
    function changeSettlementCurrentPage(pageNo) {
            dispatch(setSettlementCurrentPage(pageNo));
    }

    function changeSettlementRowsPerPage(settlementRowsPerPage) {
            dispatch(setSettlementCurrentPage(0));
            dispatch(setSettlementRowsPerPage(settlementRowsPerPage));
    }

    return {
        fetchSettlementListByCommitteeId,
        getCommitteeDashboardData,
        changeCommitteePhoneNumber,
        fetchCommitteeList,
        getCommitteeListByLocation,
        addCommittee,
        updateCommittee,
        createMultipleCommittees,
        getCommitteeBalance,
        getCommitteeActiveMemberCount,
        getRelatedMemberList,
        downloadCommitteeFileUploadTemplate,
        exportCommitteeData,
        changeSearchTerm,
        changeStatusFilter,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        changeVillageCodeFilter,
        changeCurrentPage,
        changeRowsPerPage,
        resetCommitteePin,
        getCommitteeDetail,
        deactivateCommittee,
        activateCommittee,
        changeToVerifying,
        refreshDialogState,

        
        changeSettlementSearchTerm,
        changeSettlementCurrentPage,
        changeSettlementRowsPerPage
    }
}

export default useCommitteeActions;