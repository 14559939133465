import { Paper, Typography } from "@mui/material";
import { Box } from '@mui/system';
import React from "react";

const ShadowBox = ({ children, title = "", ActionComp = null }) => {
    return (
      <Paper variant="outlined" sx={{ width: '100%', padding: '20px'}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <Typography variant="h3" sx={{ marginBottom: '20px' }}>{title}</Typography>
                  {
                        ActionComp && ActionComp
                  }
            </Box>
            {children}
      </Paper>
    )
}

export default ShadowBox;