import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

/**
 * # Search Box
 * @param {Event} changedSearchTerm 
 * @param {String} initVal
 * @param {String} placeholder
 * @param {Object} sx
 * @returns 
 */
const SearchBox = ({initVal, placeholder, sx, changedSearchTerm, reset=false}) => {
      const [searchTerm, setSearchTerm] = useState(initVal ? initVal : '');

      React.useEffect(() => {
            if (searchTerm === '') {
                  changedSearchTerm('')
            }
      }, [searchTerm]);

      React.useEffect(() => {
            setSearchTerm('');
      }, [reset]);

      const keyPress = (e) => {
            if (e.keyCode == 13) {
                  changedSearchTerm(searchTerm)
            }
      }

      return (
            <OutlinedInput
                  id='searchBox'
                  size='small'
                  placeholder={placeholder}
                  sx={{ ...sx }}
                  value={searchTerm}
                  onKeyDown={(e) => keyPress(e)}
                  onChange={(e) => { setSearchTerm(e.target.value); }}
                  endAdornment={
                        <InputAdornment position="end">
                              <Box sx={{ flexWrap: 1 }}>
                                    {
                                          searchTerm !== undefined && searchTerm !== '' && (
                                                <IconButton
                                                      sx={{padding: '2px'}}
                                                      onClick={() => setSearchTerm('')}
                                                >
                                                      <CloseIcon  size="small"/>
                                                </IconButton>
                                          )
                                    }
                                    <IconButton
                                          edge="end"
                                          onClick={() => changedSearchTerm(searchTerm)}
                                    >
                                          <Search />
                                    </IconButton>
                              </Box>

                        </InputAdornment>}
            />
      )
}

export default SearchBox;