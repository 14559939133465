// Libries
import { useSelector } from 'react-redux';
import useMaintenanceMode from './_actions';
// Local


// Register all state here from the reducer to export
export const useMaintenance = () => {
      const { maintenanceModeOff, maintenanceModeOn, getMaintenanceStatus } = useMaintenanceMode();

      const mode = useSelector(state => state.maintenance.mode);

      return {
            maintenanceModeOff,
            maintenanceModeOn,
            getMaintenanceStatus,
            mode
      }
};


export { default as maintenanceReducer } from './_reducers';