import { API_URL } from "../configs/_api";
import ApiRequest from "./apiRequest";

export async function requestOneTimeToken() {
    try {
        const url = API_URL.OneTimeToken;
        const res = await ApiRequest.get(url);
        const ott = res.data.payload.data.token;
        return ott;
    }
    catch(err) {
        return Promise.reject('Token expired');
    }
}