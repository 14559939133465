import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import {
      setData,
      setCurrentPage,
      setRowsPerPage,
      setTotalRows,
      setSearchTerm,
      setRegionIdFilter,
      setClusterIdFilter,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,
      submittingData,
      submitDataFail,
      submitDataSuccess,
      dialogActionStarted,
      dialogActionSuccess,
      dialogActionFail,
      reloadDialogState
} from "./_reducers";
import { API_URL } from '../../configs/_api';
import { useNoti } from "../../providers";
import * as XLSX from 'xlsx';
import { useRegion } from "../regions";

const useTownshipActions = () => {
      const { showNoti } = useNoti();
      const { getRegionList } = useRegion();
      const dispatch = useDispatch();
      const currentPage = useSelector(state => state.township.currentPage);
      const rowsPerPage = useSelector(state => state.township.rowsPerPage);
      const searchTerm = useSelector(state => state.township.searchTerm);
      const regionIdFilter = useSelector(state => state.township.filters.region_id);

      async function fetchTownshipListByClusterId(clusterId) {
            dispatch(fetchingData());
            const get_townships_url = `${API_URL.Township}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[region_code]=${regionIdFilter}&filters[cluster_id]=${clusterId}`;
            ApiRequest.get(get_townships_url).then(res => {
                  const { data, metadata } = res.data.payload;
                  dispatch(setData(data));
                  dispatch(setTotalRows(metadata?.total_items ?? 0));
                  dispatch(fetchDataSuccess());
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.response?.data));
            });
        }

      function fetchTownshipList() {
            dispatch(fetchingData());
            const get_townships_url = `${API_URL.Township}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[region_code]=${regionIdFilter}`;
            ApiRequest.get(get_townships_url).then(res => {
                  const { data, metadata } = res.data.payload;
                  dispatch(setData(data));
                  dispatch(setTotalRows(metadata?.total_items ?? 0));
                  dispatch(fetchDataSuccess());
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      async function getTownshipList() {
            try {
                const url = API_URL.Township;
                const res = await ApiRequest.get(url);
                return res.data.payload.data;
            }
            catch(err) {
                console.error(err);
                return [];
            }
        }

      async function addNewTownship({ mm_name, en_name, region_id, mimu_code }) {
            dispatch(dialogActionStarted())
            const addTownshipUrl = API_URL.Township;
            const data = {
                  mm_name,
                  en_name,
                  region_id,
                  mimu_code
            };
            try {
                  let id = null;
                  await ApiRequest.post(addTownshipUrl, data, {
                        headers: {
                              'Content-Type': 'application/json'
                        }
                  }).then(res => id = res.data.payload.data._id);
                  fetchTownshipList();
                  dispatch(dialogActionSuccess());
                  showNoti('New township created successfully!', 'success');
                  return Promise.resolve(id);
            }
            catch (err) {
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  dispatch(dialogActionFail('Creating township failed'));
                  return Promise.reject(errors);
            }
      }

      async function editTownship(data) {
            dispatch(dialogActionStarted())
            const editTownshipUrl = API_URL.Township + "/" + data._id;
            try {
                  let id = null;
                  await ApiRequest.patch(editTownshipUrl, data, {
                        headers: {
                              'Content-Type': 'application/json'
                        }
                  }).then(res => id = res.data.payload.data._id);
                  fetchTownshipList();
                  dispatch(dialogActionSuccess());
                  showNoti('Township edited successfully!', 'success');
                  return Promise.resolve(id);
            }
            catch (err) {
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  dispatch(dialogActionFail('Editing township failed'));
                  return Promise.reject(errors);
            }
      }

      async function downloadTownshipFileUploadTemplate(columns=[]) {
            const workBook = XLSX.utils.book_new();
    
            const dataSheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
            XLSX.utils.book_append_sheet(workBook, dataSheet, 'data');

            const regionMetaSheet = await getRegionMetaSheet();
            XLSX.utils.book_append_sheet(workBook, regionMetaSheet, '(meta) region');

            XLSX.writeFile(workBook, 'townshipUploadTemplate.xlsx');

            async function getRegionMetaSheet() {
                  const regionSheet = XLSX.utils.json_to_sheet([]);
                  const regions = (await getRegionList()) || [];
                  XLSX.utils.sheet_add_aoa(regionSheet, [['id', 'mm_name', 'en_name', 'code']]);
                  const filteredTownship = regions.map(rg=> ({ id: rg._id, mm_name: rg.mm_name, en_name: rg.en_name, code: rg.code }));
                  XLSX.utils.sheet_add_json(regionSheet, filteredTownship, { origin: 'A2', skipHeader: true });
      
                  return regionSheet;
              }
      }

      async function createMultipleTownships(file) {
            const url = `${API_URL.Township}/batch`;
            const data = new FormData();
            data.append('file', file);

            try {
                  dispatch(submittingData());
                  const res = await ApiRequest.post(url, data);
                  dispatch(submitDataSuccess());
                  return res.data;
              }
              catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  dispatch(submitDataFail());
                  return Promise.reject(errMsg);
              }
        }

      async function changeCluster(data) {
            const townshipUrl = `${API_URL.Township}/change-cluster`;
            try {
                  const updateTsp =await ApiRequest.post(townshipUrl, data);
                  return updateTsp;
            }
            catch (err) {
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  dispatch(dialogActionFail('Transfer cluster failed'));
                  return Promise.reject(errors);
            }
      }


      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeSearchTerm(searchTerm) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(searchTerm));
      }

      function changeRegionIdFilter(region_code) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(""));
            dispatch(setRegionIdFilter(region_code));
      }


      function changeClusterIdFilter(clusterId) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(""));
            dispatch(setClusterIdFilter(clusterId));
      }

      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      return {
            fetchTownshipListByClusterId,
            changeClusterIdFilter,
            fetchTownshipList,
            createMultipleTownships,
            downloadTownshipFileUploadTemplate,
            addNewTownship,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            changeRegionIdFilter,
            refreshDialogState,
            editTownship,
            getTownshipList,
            changeCluster
      }
}

export default useTownshipActions;