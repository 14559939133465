import useSocketActions from "./_actions"

export const useSocket = () => {
    const { 
        connectSocket,
        disconnectSocket,
        emitSocketEvent
    } = useSocketActions();

    return {
        connectSocket,
        disconnectSocket,
        emitSocketEvent
    }
}