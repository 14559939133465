import dayjs from 'dayjs';
import React from 'react';
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

export function toCapitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
}

 
/**
 * 
 * @param {number} value 
 * @param {string} valueType {'number' or 'date'} 
 * @returns 
 */
export function formatVal(value, valueType) {
      if (valueType) {
            if (valueType == 'number') {
                  if (value < 0) {
                        return <div style={{ color: 'red', display: 'inline' }}>{value?.toLocaleString()}</div> // "1,234,567,890"
                  } else {
                        return <div style={{ display: 'inline' }}>{value?.toLocaleString()}</div> // "1,234,567,890"
                  }
            } else if (valueType == 'date') {
                  return dayjs(value).utc('my').format('DD-MMM-YYYY hh:mm A')
            }
      } else {
            return value
      }
}

export function toFixed(num, fixedPlace=1) {
      if(typeof num == 'number') return num.toFixed(fixedPlace);

      return num;
}

export function toFormattedString(key, namingCase) {
      const LetterCase = {
            CAMEL: 'camel',
            SNAKE: 'snake'
      };

      function getWordArray(str = "") {
            if (namingCase == LetterCase.SNAKE) {
                  return str.split("_");
            }

            const asciiMap = str.split("").map(char => {
                  return [char, char.charCodeAt(0)];
            });

            const spaceString = asciiMap.reduce((str, map, index) => {
                  const isCurCharUpperCase = (map[1] >= 65 && map[1] <= 90);
                  const isNextCharUpperCase = Boolean(asciiMap[index + 1]) && (asciiMap[index + 1][1] >= 65 && asciiMap[index + 1][1] <= 90);
                  str += (isCurCharUpperCase && !isNextCharUpperCase) ? ` ${map[0]}` : map[0];
                  return str;
            }, "");

            return spaceString.trim().split(" ");
      }

      function getFinalString(wordArray = []) {
            return (wordArray.reduce((str, word) => {
                  str += `  ${isAllCharUpperCase(word) ? word : toCapitalize(word)}`;
                  return str;
            }, "")).trim();
      }

      function isAllCharUpperCase(word = "") {
            return /^[A-Z]+$/.test(word);
      }

      function toCapitalize(word = "") {
            if (!word) {
                  return word;
            }

            return (word.charAt(0).toUpperCase()) + (word.slice(1, word.length).toLowerCase());
      }

      return getFinalString(getWordArray(key));
}

export function removeNonNumeric(num){
      return num?.toString().replace(/[^0-9]/g, "");
}

export function addCommas(num){
      return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}