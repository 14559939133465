import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';

const TYDatePicker = (props) => {
    const [value, setValue] = React.useState(null);

    React.useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    React.useEffect(() => {
        if(props.reset !== undefined){
           setValue(null);
           props.setDate(null);
        }
    }, [props.reset]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                {...props}
                label={props.label}
                value={value ? dayjs(value) : null}
                inputFormat="YYYY-MM-DD"
                onChange={(date) => {
                    dayjs(date).format('YYYY-MM-DD') !== 'Invalid Date' ?
                    props.setDate(dayjs(date).format('YYYY-MM-DD')) 
                    : 
                    props.setDate(null)
                }}
                slotProps={{
                    textField: {
                      helperText: props.errorMsg ?? '',
                      error: Boolean(props.error),
                      size: 'small',
                      placeholder: props.placeholder,
                      fullWidth: props.fullWidth || false
                    },
                  }}
                componentsProps={{
                    actionBar: {
                        actions: ['clear', 'today'],
                    },
                }}
            />
        </LocalizationProvider>
    )
};

export default TYDatePicker;