import React from 'react';
import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
      ({ theme }) => ({
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            height: 20,
            // color: 'primary.main',
            '&.Mui-selected': {
                  fontWeight: theme.typography.fontWeightBold,
                  // color: 'primary.secondary',
            },
            '&.Mui-focusVisible': {
                  // color: 'primary.main',
            },
      }),
);

export default StyledTab;