import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import { requestOneTimeToken } from '../../utils/helper';
import { useNoti } from '../../providers';
import { depositContractColumns } from "../../configs/_dataColumnConfig"
import {
      // setData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,
      // setTotalRows,
      setStartDate,
      setEndDate,
      setRegionCodeFilter,
      setTownshipCodeFilter,
      setVillageTractCodeFilter,
      setDueDateFilter,
      submittingData,
      submitDataFail,
      submitDataSuccess,
      setStartDateForCommittee,
      setEndDateForCommittee,
      reloadDialogState
} from "./_reducers";
import useDataExportActions from "../data_exports/_actions";
import dayjs from "dayjs";
import { writeOutExcelFile } from "../../utils/xlsxWorkBook";

const useDepositActions = () => {
      const dispatch = useDispatch();
      const { showNoti } = useNoti();

      const { moduleParam, getFilteredData } = useDataExportActions()

      // const data = useSelector(state => state.deposit.data);
      const currentPage = useSelector(state => state.deposit.currentPage);
      const rowsPerPage = useSelector(state => state.deposit.rowsPerPage);
      const searchTerm = useSelector(state => state.deposit.searchTerm);
      const regionCodeFilter = useSelector(state => state.deposit.filters.region_code);
      const townshipCodeFilter = useSelector(state => state.deposit.filters.township_code);
      const villageTractCodeFilter = useSelector(state => state.deposit.filters.village_tract_code);
      const start_date = useSelector(state => state.deposit.filters.start_date);
      const end_date = useSelector(state => state.deposit.filters.end_date);

      const startDateForCommittee = useSelector(state => state.deposit.filters.startDateForCommittee);
      const endDateForCommittee = useSelector(state => state.deposit.filters.endDateForCommittee);
      const dueDateFilter = useSelector(state => state.deposit.filters.dueDate);

      function fetchDepositList() {
            dispatch(fetchingData());

            let get_deposits_url = `${API_URL.Deposit}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}&filters[village_tract_code]=${villageTractCodeFilter}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}&filters[dueDate]=${dueDateFilter || 'all'}`;

            ApiRequest.get(get_deposits_url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      // function getLoanDepositDetail(id) {
      //       return data.filter((d) => d._id == id)[0]
      // }

      async function getLoanDepositDetail(id, apiOptions={}) {
            dispatch(fetchingData());
            try {
                const url = `${API_URL.Deposit}/${id}`;
                const res = await ApiRequest.get(url, {...apiOptions});
                const data = res.data.payload.data;
                dispatch(fetchDataSuccess(data));
                return data;
            }
            catch(err) {
                console.error(err);
                dispatch(fetchDataFail(`Failed to get loan deposit detail! ${err.message}`));
            //     return Promise.reject();
            }
        }

      async function fetchDepositDetailById(id) {
            let url = `${API_URL.Deposit}/${id}`;
            try {
                  let res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  return data;
            } catch(err){
                  console.log(err)
            }
      }

      async function getDepositTransactionList(id) {
            let url = `${API_URL.Deposit}/${id}/transaction_history?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
            try{
                  let res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ total_items }));
                  return data;
            }catch(err){
                  console.error(err);
            }
      }

      function fetchDepositListByCommitteeId(committeeId) {
            dispatch(fetchingData());
            let url = `${API_URL.Deposit}?search_term=&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}&filters[village_tract_code]=${villageTractCodeFilter}&filters[date_from]=${startDateForCommittee ?? ''}&filters[date_to]=${endDateForCommittee ?? ''}&filters[committee_id]=${committeeId}`;
            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.message));
            });
      }

      async function createNewDeposit(deposit) {
            dispatch(submittingData());
            try {
                  const url = API_URL.Deposit;
                  const res = await ApiRequest.post(url, deposit);
                  showNoti('New deposit saved as draft.', 'success');
                  dispatch(submitDataSuccess());
                  fetchDepositList();
                  return res.data.payload.data;
            }
            catch (err) {
                  let errors = {};
                  errors = err.response?.data.errors;
                  const errMsg = errors.message ?? err.message;
                  dispatch(submitDataFail(errMsg ?? "Creating Deposit failed!"));
                  return Promise.reject(errors);
            }
      }

      async function updateDeposit(depositId, deposit) {
            dispatch(submittingData());
            try {
                  const url = `${API_URL.Deposit}/${depositId}`;
                  await ApiRequest.patch(url, deposit);
                  showNoti('Deposit updated successfully', 'success');
                  dispatch(submitDataSuccess());
                  fetchDepositList();
                  return Promise.resolve();
            }
            catch (err) {
                  console.error(err);
                  dispatch(submitDataFail(`Update deposit fail! ${err.message}`));
                  return Promise.reject();
            }
      }

      async function completeDeposit(depositId) {
            dispatch(submittingData());
            try {
                  const oneTimeToken = await requestOneTimeToken();
                  const url = `${API_URL.Deposit}/${depositId}/complete`;
                  await ApiRequest.post(url, { ott: oneTimeToken });
                  showNoti('Deposit completed successfully', 'success');
                  dispatch(submitDataSuccess());
                  fetchDepositList();
                  return Promise.resolve();
            }
            catch (err) {
                  console.error(err);
                  const errMsg = `Error! ${err.response?.data.errors.message ?? err.message}`;
                  showNoti(errMsg, 'error');
                  dispatch(submitDataFail());
                  return Promise.reject();
            }
      }

      const exportDepositData = async (region, township, village_track, start_date, end_date) => {
            try {
                  dispatch(submittingData());
                  const deposit = moduleParam.DEPOSIT_CONTRACT;
                  const query = `module=${deposit}&filter[region]=${region}&filter[township]=${township}&filter[village_track]=${village_track}&filter[start_date]=${start_date}&filter[end_date]=${end_date}`
                  const url = `${API_URL.DataExport}?${query}`
                  const res = await ApiRequest.post(url)
                  const response = res.data;
                  if(response.status === 'success') {
                  const data = response.payload.data;
                  const filterData = getFilteredData(data, depositContractColumns)
                  const sheetHeader = [depositContractColumns.map(item => item.label)]

                  let fileName = ''

                  fileName += deposit?.charAt(0).toUpperCase() + deposit.slice(1);
                  if(start_date || end_date) {
                        fileName += `(${start_date} ~ ${end_date || dayjs().format('YYYY-MM-DD')})`;
                  }
                  fileName += `.xlsx`;

                  writeOutExcelFile(sheetHeader, filterData, fileName);
                  dispatch(submitDataSuccess());
                  showNoti('Deposit list data exported successfully', 'success');
            }
            } catch (err) {
                  const errorMessage = err.response?.data.errors.message || err.message;
                  dispatch(submitDataFail(errorMessage));
                  showNoti(errorMessage || 'Error occurred while exporting data', 'error')
                  return Promise.reject(errorMessage);
            }
      }

      function changeSearchTerm(search_key) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(search_key));
      }
      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeStartDate(date) {
            dispatch(setStartDate(date));
      }

      function changeEndDate(date) {
            dispatch(setEndDate(date));
      }

      function changeRegionCodeFilter(region_code) {
            dispatch(setRegionCodeFilter(region_code));
      }
      function changeTownshipCodeFilter(township_code) {
            dispatch(setTownshipCodeFilter(township_code));
      }
      function changeVillageTractCodeFilter(village_tract_code) {
            dispatch(setVillageTractCodeFilter(village_tract_code));
      }
      function changeStartDateForCommittee(date) {
            dispatch(setStartDateForCommittee(date))
      }
      function changeDueDateFilter(due) {
            dispatch(setDueDateFilter(due));
      }
      function changeEndDateForCommittee(date) {
            dispatch(setEndDateForCommittee(date))
      }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      return {
            fetchDepositList,
            fetchDepositListByCommitteeId,
            fetchDepositDetailById,
            createNewDeposit,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeStartDateForCommittee,
            changeEndDateForCommittee,
            changeRegionCodeFilter,
            changeTownshipCodeFilter,
            changeVillageTractCodeFilter,
            changeDueDateFilter,
            updateDeposit,
            completeDeposit,
            getLoanDepositDetail,
            getDepositTransactionList,
            refreshDialogState,
            exportDepositData
      }
}

export default useDepositActions;