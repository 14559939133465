import { useDispatch, useSelector } from "react-redux";
import {
  setAuth,
  setData,
  loginSuccess,
  fetchingData,
  fetchDataSuccess,
  fetchDataFail,
  verifyMailSuccess,
} from "./_reducers";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import tokenConfig from "../../configs/_token";

const useUserActions = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const permittedActions = useSelector((state) => state.user.permitted_actions);
  const feRoutes = useSelector((state) => state.user.feRoutes);

  async function logIn(data) {
    dispatch(fetchingData()); // Here will be  login api
    let login_url = `${API_URL.Login}`;
    try {
      let res = await ApiRequest.post(login_url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const login_data = res?.data?.payload?.data;
      const actions = getActions(login_data.permission_schema);
      const feRoutes = getFeRoutes(login_data.permission_schema);
      login_data.actions = actions;
      login_data.feRoutes = feRoutes;
      dispatch(loginSuccess(login_data));
      return {
        fe_route: login_data.permission_schema.filter(
          (i) => i.isDefault == true
        )[0].fe_route,
        is_first_login: login_data.userData.is_first_login,
      };
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ?? `Error - ${err.message}`;
      const errData = err.response?.data.errors.data ?? null;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject({ errMsg, errData });
    } finally {
      window.localStorage.setItem(
        "currentVersion",
        process.env.REACT_APP_VERSION
      );
    }
  }

  function getActions(permissionSchema = []) {
    let actionList = {};
    extractActions(permissionSchema);

    function extractActions(input = []) {
      input.forEach((item) => {
        if (typeof item.actions === "object") {
          Object.assign(actionList, item.actions);
        }

        if (Array.isArray(item.children)) {
          extractActions(item.children);
        }
      });
    }

    return actionList;
  }

  function getFeRoutes(permissionSchema = []) {
    let feRoutesList = {};
    extractFeRoutes(permissionSchema);

    function extractFeRoutes(input = []) {
      input.forEach((item) => {
        if (item.children && Array.isArray(item.children)) {
          item.children.forEach((child) => {
            Object.assign(feRoutesList, {
              [`${child.fe_route}`]: child.isMenuShow,
            });
          });
        } else {
          Object.assign(feRoutesList, {
            [`${item.fe_route}`]: item.isMenuShow,
          });
        }
      });
    }

    return feRoutesList;
  }

  function hasPermission(actionName) {
    const isAllowed = permittedActions[`${actionName}`.trim()] === true;
    return Boolean(isAllowed);
  }

  function hasFeRoute(path) {
    const hasRoutePermission = feRoutes[`${path.split("/")[1]}`] === true;
    return Boolean(hasRoutePermission);
  }

  async function logOutUser() {
    try {
      const url = API_URL.Logout;
      await ApiRequest.post(url, {
        access_token: localStorage.getItem(tokenConfig.ACCESS_TOKEN_NAME),
        refresh_token: localStorage.getItem(tokenConfig.REFRESH_TOKEN_NAME),
      });
    } catch (err) {
      console.error(err.stack);
    } finally {
      window.localStorage.setItem(
        "currentVersion",
        process.env.REACT_APP_VERSION
      );
      dispatch(setAuth(false));
    }
  }

  async function changePassword(data) {
    dispatch(fetchingData());
    let change_password_Url = `${API_URL.ChangePassword}`;
    try {
      await ApiRequest.post(change_password_Url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(setData({ ...userData, is_first_login: false }));
      dispatch(fetchDataSuccess());
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  async function sendMail(data) {
    dispatch(fetchingData());
    let send_forgot_mail_url = `${API_URL.SendMail}`;
    try {
      let res = await ApiRequest.post(send_forgot_mail_url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(fetchDataSuccess());
      return res;
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  async function resetPassword(data) {
    dispatch(fetchingData());
    let reset_url = `${API_URL.ResetPassword}`;
    try {
      let res = await ApiRequest.post(reset_url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(fetchDataSuccess());
      return res;
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  async function verifyEmail(data) {
    dispatch(fetchingData());

    let varify_token_url = API_URL.VerifyToken;
    try {
      const res = await ApiRequest.post(
        varify_token_url,
        { token: data.token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(verifyMailSuccess());
      return res;
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ?? `Error - ${err.message}`;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
  }

  function changeAuth(auth) {
    dispatch(setAuth(auth));
  }

  function isHeadOfficeUser() {
    return userData.is_head_office_user || false;
  }

  return {
    logIn,
    logOutUser,
    sendMail,
    changePassword,
    resetPassword,
    verifyEmail,
    changeAuth,
    hasPermission,
    hasFeRoute,
    isHeadOfficeUser,
  };
};

export default useUserActions;
