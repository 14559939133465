const ROUTES = {
  SAMPLE: "/sample",

  // App version
  APP_VERSION: "/app-versions",

  // User
  LANDING: "/",
  LOGIN: "/login",
  FORGET_PASSWORD: "/forgetPassword",
  RESET_PASSWORD: "/resetPassword/:param",
  CHANGE_PASSWORD: "/change-password",

  // Location
  REGION: "/regions",
  TOWNSHIP: "/townships",
  VILLAGE_TRACT: "/village-tracts",
  VILLAGE: "/villages",
  CLUSTER: "/clusters",

  // Manage Users
  PORTAL_USER: "/portal-users",
  OFFICE: "/offices",
  COMMITTEE: "/committees/*",
  COMMITTEE_DETAIL: "/committees/detail",
  COMMITTEE_MEMBER_DETAIL: "/committees/detail/member",
  COMMITTEE_DEPOSIT_DETAIL: "/committees/detail/deposit",
  MEMBER: "/members/*",
  MEMBER_DETAIL: "/members/detail",
  SHOP: "/shops",
  DONOR: "/donors",

  // FundReceiving
  FUNDRECEIVING: "/fund-contracts",

  // FundHandingOver
  FUNDHANDINGOVER: "/fund-transfers",

  // Deposit
  DEPOSIT: "/deposits",

  // Permission
  ROLES: "/portalUserRoles",
  SCHEMA: "/schema",

  //Dashboard
  FIN_DASHBOARD: "/finance-dashboard",
  FIN_HO_DASHBOARD: "/finance-HO-dashboard",
  ADMIN_DASHBOARD: "/admin-dashboard",

  // FundReceivingInvoices
  FUND_RECEIVING_INVOICES: "/fundReceivingInvoices",
  FUND_RECEIVING_INVOICES_DETAIL:
    "/fundReceivingInvoices/:fund_receiving_invoice_id",

  // FundAllocation
  FUND_ALLOCATION: "/fundAllocation",
  FUND_ALLOCATION_DETAIL: "/fundAllocation/:fund_allocation_id",

  // Loan
  LOAN: "/loans",
  LOAN_DETAIL: "/loans/:loan_id",

  // Settlement
  SETTLEMENT: "/settlement",

  // Invoice
  INVOICE: "/invoices",

  // Product Category
  CATALOG: "/product-list",

  // Product Category
  CATEGORY: "/product-categories",

  // Revenue Transfers
  REVENUETRANSFERS: "/revenue-transfers",
  REVENUETRANSFERS_DETAIL: "/revenue-transfers/:revenue_transfers_id",

  // Action Log
  ACTION_LOG: "/action-logs",

  // Article
  ARTICLE: "/articles",
  ARTICLE_CREATE: "/articles/create",
  ARTICLE_DETAIL: "/articles/:article_id",


  // Ecommerce Order
  ECOMMERCEORDER: "/ecommerce-orders",
  ECOMMERCEORDER_CREATE: "/ecommerce-orders/create",
  ECOMMERCEORDER_DETAIL: "/ecommerce-orders/:article_id",

  // Data Export
  DATA_EXPORT: "/data-exports",

  // Static Page
  STATIC_PAGE: "/static-pages",

  // Maintenance Page
  MAINTENANCE: "/maintenance",
};
export default ROUTES;
