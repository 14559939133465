import React from 'react';
import { Line } from 'react-chartjs-2';
import {
      Chart as ChartJS,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
} from 'chart.js';

ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
);

export default function LineChart() {
      const options = {
            responsive: true,
            plugins: {
                  legend: { position: 'bottom' },
            }
      };

      const labels = ['Committee#A', 'Committee#B', 'Committee#C', 'Committee#D', 'Committee#E', 'Committee#F', 'Committee#G', 'Committee#H', 'Committee#I', 'Committee#J', 'Committee#K', 'Committee#L', 'Committee#M'];

      const data = {
            labels,
            datasets: [
                  {
                        label: 'Lost Rate',
                        data: [65, 59, 80, 81, 56, 55, 40, 30, 48, 80, 81, 56, 55],
                        borderColor: '#F4C770',
                        backgroundColor: '#613F31',
                        // borderColor: '#613F31',
                        // backgroundColor: '#F4C770',
                        pointStyle: 'circle',
                        pointRadius: 5,
                        pointHoverRadius: 10
                  },
            ],
      };

      return (
            <Line
                  data={data}
                  options={options}
            />
      )
}