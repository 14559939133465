import { useSelector } from "react-redux";
import useOfficeActions  from './_actions';

export const useOffice = () => {
    const {
        getOfficeListByLocation,
        fetchOfficeList, 
        addOffice,
        updateOffice,
        changeCurrentPage, 
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionIdFilter,
        refreshDialogState
    } = useOfficeActions();

    const offices = useSelector(state => state.office.data);
    const currentPage = useSelector(state => state.office.currentPage);
    const totalRows = useSelector(state => state.office.totalRows);
    const rowsPerPage = useSelector(state => state.office.rowsPerPage);
    const isLoading = useSelector(state => state.office.isLoading);
    const isError = useSelector(state => state.office.isError);
    const searchTerm = useSelector(state => state.office.searchTerm);
    const regionIdFilter = useSelector(state => state.office.filters.region_id);
    const dialogStates = useSelector(state => state.office.action);



    return {
        //state
        offices,
        isLoading,
        isError,
        currentPage,
        rowsPerPage,
        totalRows,
        searchTerm,
        regionIdFilter,
        dialogStates,

        //actions
        getOfficeListByLocation,
        fetchOfficeList,
        addOffice,
        updateOffice,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionIdFilter,
        refreshDialogState
    }
}

export {default as officeReducer} from './_reducers';