
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import React from 'react';
// LOCAL
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * 
 * @param {String} title PieChart's title
 * @param {Object} data format --> { label: value, label: value }
 * @returns 
 */
function CommitteePieChart({ data, title }) {
      const theme = useTheme();
      const backgroundColor = [theme.palette.primary.main, theme.palette.secondary.main, "#B68A75", "#FFE2AE" ]
      const options = {
            responsive: true,
            plugins: {
                  legend: { position: 'none' },
            }
      }

      function getTotal(initData) {
            let total = 0;
            Object.entries(initData).map((d) => { total += d[1] })
            return total
      }

      function generateData(initData) {
            let label = [];
            let data = [];

            Object.entries(initData).map((d) => {
                  label.push(d[0]) // lable
                  data.push(d[1]) // values
            })

            const _data = {
                  labels: label,
                  datasets: [
                        {
                              data: data,
                              backgroundColor: backgroundColor,
                              borderWidth: 0,
                        },
                  ],
            };

            return _data
      }


      return (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%',  }}>
                        {
                              getTotal(data) > 0
                                    ? <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',width: '100%', height: '100%'}}>
                                          <Doughnut style={{position: 'absolute', top:0,  textAlign: 'center'}} options={options} data={generateData(data)} />
                                          <Typography position={'absolute'} sx={{color:'GrayText', fontSize: 10, margin: 0, top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} textAlign='center'>{title}</Typography>
                                    </Box>
                                    : <Box sx={{ backgroundColor: '#f3f3f3', width: 118, height: 118, borderRadius: 100 }} />
                        }
                  </Box>
      );
}

export default CommitteePieChart; 