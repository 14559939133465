import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton, StyledDialogAction, StyledDialogHeader, TextBox } from "..";
import { Box, Button, Dialog, DialogContent } from "@mui/material";


const defaultDataSchema = {
    phone_number: '',
}

const validationSchema = yup.object().shape({
    phone_number: yup
        .string()
        .matches(/^(09|01)[0-9]{8,11}$/, 'Invalid phone number')
        .required("phone number is required!"),
});

const ChangePhoneNumberDialog = (props) => {
    const shop = props.shop;

    const { register, handleSubmit, formState: { errors, isDirty } } = useForm({
        mode: 'onChange',
        defaultValues: defaultDataSchema,
        resolver: yupResolver(validationSchema),
        values: {
            phone_number: shop?.phone_number ?? '',
        }
    });

  const onHandleClose = () => {
        props.handleClose();
    }

    const onSubmit = (data) => {
        let phone_number = data.phone_number;
        props.submit(phone_number);
    }


    return (
        <Dialog open={props.isOpen} fullWidth maxWidth='sm' sx={{ height: 'auto' }}>
            <StyledDialogHeader title={props.title} />
            <DialogContent>
                <Box component={'form'} sx={{ display: 'flex', flexDirection: 'column', '& .MuiInputBase-root': { mb: 0, mt: 3 } }} noValidate autoComplete="off" gap={2}>

                    <TextBox
                        label=""
                        placeholder='e.g 09xxxxxxxx'
                        error={errors.phone_number?.message}
                        helperText={errors.phone_number?.message}
                        inputProps={register('phone_number')}
                    />

                </Box>
            </DialogContent>
            <StyledDialogAction isError={props.isError || false} errMsg={props.errorMessage || ''}>
                <Button onClick={onHandleClose} variant="outlined">Close</Button>
                <LoadingButton
                    isLoading={props.isLoading ?? false}
                    label="Confirm"
                    onClick={handleSubmit(onSubmit)}
                    loadingIconSize={20}
                    disabled={!isDirty} />
            </StyledDialogAction>
        </Dialog>
    )
}

export default ChangePhoneNumberDialog;