import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const NoPermission = () => {
      return (
            <>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '100%', mt: 10, mb: 10 }}>
                        <img style={{ width: 300, height: 300 }} alt={'img'} src={'/images/totoro/eating.gif'} />
                        <h1>Oops!</h1>
                        <Typography variant="h1">{`it looks like u don't have permission to view this page.`}</Typography>
                  </Box>
            </>
      )
}

export default NoPermission;