import ApiRequest from "../../utils/apiRequest";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import { 
    setCurrentPage, 
    setRowsPerPage, 
    setSearchTerm,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    fetchingData, 
    fetchDataSuccess, 
    fetchDataFail,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState,
} from "./_reducers";
import { useNoti } from "../../providers";
import * as XLSX from 'xlsx';
import { useRegion } from "../regions";
import { useTownship } from "../township";

const useVillageTractActions = () => {
    const dispatch = useDispatch();
    const {showNoti} = useNoti();
    const { getRegionList } = useRegion();
    const { getTownshipList } = useTownship();

    const currentPage = useSelector(state => state.village_tract.currentPage);
    const rowsPerPage = useSelector(state => state.village_tract.rowsPerPage);
    const searchTerm = useSelector(state => state.village_tract.searchTerm);
    const regionCodeFilter = useSelector(state => state.village_tract.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.village_tract.filters.township_code);

    function fetchVillageTractList() {
        dispatch(fetchingData());

        const getVillageTractsUrl = `${API_URL.VillageTract}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}`;
        ApiRequest.get(getVillageTractsUrl).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;
            
            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

    async function getVillageTractList() {
        try {
            const url = API_URL.VillageTract;
            const res = await ApiRequest.get(url);
            return res.data.payload.data;
        }
        catch(err) {
            console.error(err);
            return [];
        }
    }

    async function addVillageTract({mm_name, en_name, region_id, township_id, mimu_code}) {
        const addVillageTractUrl = API_URL.VillageTract;
        const data = {
            mm_name,
            en_name,
            mimu_code,
            region_id,
            township_id
        };
        dispatch(submittingData());
        try {
            let id= null;
            await ApiRequest.post(addVillageTractUrl, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => id = res.data.payload.data._id);
            showNoti('New village tract created successfully!', 'success');
            fetchVillageTractList();
            dispatch(submitDataSuccess());
            return Promise.resolve(id);
        }
        catch(err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            dispatch(submitDataFail('Creating village track failed'));
            return Promise.reject(errors);
        }
    }

    async function editVillageTract(data) {
        let editVillageTractUrl = API_URL.VillageTract + "/" + data._id;
        dispatch(submittingData());
        try{
            let id= null;
            await ApiRequest.patch(editVillageTractUrl, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => id = res.data.payload.data._id);
            showNoti(' village tract edited successfully!', 'success');
            fetchVillageTractList();
            dispatch(submitDataSuccess());
            return Promise.resolve(id);
        } catch(err){
            const errors = {};
            errors.data = err.response?.data.errors.data;
            dispatch(submitDataFail('Editing village track failed'));
            return Promise.reject(errors);
        }
    }

    async function downloadVillageTractFileUploadTemplate(columns=[]) {
        const workBook = XLSX.utils.book_new();

        const dataSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
        XLSX.utils.book_append_sheet(workBook, dataSheet, 'data');

        const regionMetaSheet = await getRegionMetaSheet();
        XLSX.utils.book_append_sheet(workBook, regionMetaSheet, '(meta) region');

        const townshipMetaSheet = await getTownshipMetaSheet();
        XLSX.utils.book_append_sheet(workBook, townshipMetaSheet, '(meta) township');

        XLSX.writeFile(workBook, 'villageTractUploadTemplate.xlsx');

        async function getRegionMetaSheet() {
              const regionSheet = XLSX.utils.json_to_sheet([]);
              const regions = (await getRegionList()) || [];
              XLSX.utils.sheet_add_aoa(regionSheet, [['id', 'mm_name', 'en_name', 'code']]);
              const filteredTownship = regions.map(rg=> ({ id: rg._id, mm_name: rg.mm_name, en_name: rg.en_name, code: rg.code }));
              XLSX.utils.sheet_add_json(regionSheet, filteredTownship, { origin: 'A2', skipHeader: true });
  
              return regionSheet;
          }

        async function getTownshipMetaSheet() {
            const townshipSheet = XLSX.utils.json_to_sheet([]);
            const townships = (await getTownshipList()) || [];
            XLSX.utils.sheet_add_aoa(townshipSheet, [['id', 'mm_name', 'en_name','region', 'code']]);
            const filteredVillageTract = townships.map(rg=> ({ id: rg._id, mm_name: rg.mm_name, en_name: rg.en_name, region: rg.region_mm, code: rg.code,  }));
            XLSX.utils.sheet_add_json(townshipSheet, filteredVillageTract, { origin: 'A2', skipHeader: true });

            return townshipSheet;
        }
    }

    async function createMultipleVillageTracts(file) {
        const url = `${API_URL.VillageTract}/batch`;
        const data = new FormData();
        data.append('file', file);

        try {
              dispatch(submittingData());
              const res = await ApiRequest.post(url, data);
              dispatch(submitDataSuccess());
              return res.data;
          }
          catch(err) {
              const errMsg = err.response?.data.errors.message || err.message;
              dispatch(submitDataFail());
              return Promise.reject(errMsg);
          }
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }

    function changeRegionCodeFilter(code) {
        dispatch(setSearchTerm(''));
        dispatch(setRegionCodeFilter(code));
    }

    function changeTownshipCodeFilter(code) {
        dispatch(setSearchTerm(''));
        dispatch(setTownshipCodeFilter(code));
    }

    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        fetchVillageTractList,
        createMultipleVillageTracts,
        getVillageTractList,
        addVillageTract,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        refreshDialogState,
        editVillageTract,
        downloadVillageTractFileUploadTemplate
    }
}

export default useVillageTractActions;