import React, { Suspense } from 'react';
import AppRoutes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { GlobalLoading } from './components';
import { TYThemeProvider, useNoti } from './providers';
import { useUser } from './features';

export default function App() {
      const { logOutUser } = useUser();
      const { showNoti } = useNoti();

      const ReactEnv = process.env.REACT_APP_ENV
      const ReactVer = process.env.REACT_APP_VERSION + (ReactEnv == 'development' ? '-dev' : ReactEnv == 'staging' ? '-stag' : '')
      const nodeEnv = process.env.NODE_ENV
      const newKey = process.env.REACT_APP_VERSION
      const oldKey = window.localStorage.getItem('currentVersion')


      if (newKey !== oldKey & oldKey !== null) {
            logOutUser()
            showNoti('New version is released, please login again!', 'error');
      }

      ReactEnv != 'production'
            && console.log(`✅ Ver: ${ReactVer} ✅ Web: ${ReactEnv} ✅ Node: ${nodeEnv}`);

      return (
            <Suspense fallback={<GlobalLoading />}>
                  <TYThemeProvider>
                        <BrowserRouter>
                              <AppRoutes />
                        </BrowserRouter>
                  </TYThemeProvider>
            </Suspense>
      )
}
