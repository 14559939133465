import { io } from "socket.io-client";
import { API_BASE_URL } from "../../configs/_api";
import tokenConfig from "../../configs/_token";
import { useNoti } from "../../providers";

const useSocketActions = () => {
    const { showNoti } = useNoti();
    const socket = io(API_BASE_URL, { 
        autoConnect: false, 
        auth: { token: localStorage.getItem(tokenConfig.ACCESS_TOKEN_NAME) } ,
        transports: ["websocket"]
    });

    function connectSocket() {
        socket.connect();
        listen();
    }

    function disconnectSocket() {
        socket.disconnect();
    }

    /**
     * @param {String} eventName 
     * @param {any} data 
     */
    function emitSocketEvent(eventName, data) {
        socket.emit(eventName, data);
    }

    /**
     * @param {String} eventName 
     * @param {Function} callback 
     */
    function listenSocketEvent(eventName, callback) {
        socket.on(eventName, callback);
    }

    /**
     * @param {Array<Array<string, CallbackFunction>>} events
     * @callback CallbackFunction
     * @param {*} data
     */
    function registerSocketEvents(events) {
        events.forEach(event => listenSocketEvent(event[0], event[1]));
    }

    // Register event here....
    function listen() {
        registerSocketEvents([
            ["greeting", (message) => showNoti(message, "success")],
        ]);
    }

    return {
        connectSocket,
        disconnectSocket,
        emitSocketEvent,
        listen
    }
}

export default useSocketActions;