import { API_URL } from "../../configs/_api";
import { useNoti } from "../../providers";
import ApiRequest from "../../utils/apiRequest";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import {
    committeeColumns,
    memberColumns,
    shopColumns,
    productColumns,
    invoiceListColumns,
    loanContractColumns,
    depositContractColumns,
    settlementColumns,
    fundingTransferColumns,
    fundReceivingInvoiceColumns,
    fundAllocationColumns,
    revenueTransfersColumns,
    articleListColumns,
    invoiceSummaryColumns,
    ecommerceOrderColumns,
    ecommerceOrderSummaryColumns
} from "../../configs/_dataColumnConfig"
import { getTotalAmountFromArray } from "../../utils/arrayFunc";
import { useMetaData } from "../meta_data";


const useDataExportActions = () => {
    const { showNoti } = useNoti();

    const { donors, fetchDonors } = useMetaData();

const insertDonorColumn = async (columns) => {
    await fetchDonors();
    let donor_columns = [];
    donors.forEach(donor => {
        donor_columns.push({id: donor.id, label: donor.label})
    })
    return [
        ...columns,
        ...donor_columns
    ]
};

    const excelFormat = Object.freeze({
        CSV: 'csv',
        XLSX: 'xlsx'
    });

    const moduleParam = {
        COMMITTEE: 'committee',
        MEMBER: 'member',
        SHOP: 'shop',
        DEPOSIT_CONTRACT: 'deposit_contract',
        LOAN_CONTRACT: 'loan_contract',
        SETTLEMENT: 'settlement',
        FUNDING_TRANSFER: 'funding_transfer',
        FUND_RECEIVING_INVOICE: 'fund_receiving_invoice',
        FUND_ALLOCATION: 'fund_allocation',
        REVENUE_TRANSFERS: 'revenue_transfers',
        PRODUCT: 'product',
        INVOICE_LIST : 'invoice',
        ARTICLE_LIST : 'article',
        INVOICESUMMARY : 'invoiceSummary',
        ECOMMERECE_ORDER: 'ecommerce_order',
        ECOMMERECE_ORDER_SUMMARY: 'ecommerce_orderSummary',
    }

    async function getData(moduleParamName, filter={}) {
        try {
            const { dateFrom, dateTo, committee_id, member_type, product_type } = filter;
            const queryParams = committee_id 
            ? 
            `module=${moduleParamName}&filter[start_date]=${dateFrom}&filter[end_date]=${dateTo}&filter[committee_id]=${committee_id}&filter[member_type]=${member_type}` 
            : 
            `module=${moduleParamName}&filter[start_date]=${dateFrom}&filter[end_date]=${dateTo}&filter[member_type]=${member_type}&filter[product_type]=${product_type}`;

            const url = `${API_URL.DataExport}?${queryParams}`;
            const res = await ApiRequest.post(url, null);
            return res.data.payload.data;                                                                                                                                          
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            showNoti(errMsg, "error");
            return Promise.reject();
        }
    }

    async function getColumnConfig(moduleParamName) {
        switch(moduleParamName) {
            case moduleParam.COMMITTEE: return committeeColumns;
            case moduleParam.MEMBER: return await insertDonorColumn(memberColumns);
            case moduleParam.SHOP: return shopColumns;
            case moduleParam.DEPOSIT_CONTRACT: return depositContractColumns;
            case moduleParam.LOAN_CONTRACT: return loanContractColumns;
            case moduleParam.SETTLEMENT: return settlementColumns;
            case moduleParam.FUNDING_TRANSFER: return fundingTransferColumns;
            case moduleParam.FUND_RECEIVING_INVOICE: return fundReceivingInvoiceColumns;
            case moduleParam.FUND_ALLOCATION: return fundAllocationColumns;
            case moduleParam.REVENUE_TRANSFERS: return revenueTransfersColumns;
            case moduleParam.PRODUCT: return productColumns;
            case moduleParam.INVOICE_LIST: return invoiceListColumns;
            case moduleParam.INVOICESUMMARY: return invoiceSummaryColumns
            case moduleParam.ARTICLE_LIST: return articleListColumns;
            case moduleParam.ECOMMERECE_ORDER: return ecommerceOrderColumns;
            case moduleParam.ECOMMERECE_ORDER_SUMMARY: return ecommerceOrderSummaryColumns;
            default: return null;
        }
    } 

    function getFilteredData(data=[], columnConfig=[], module = '') {
        return data.map(row => {
            const filteredRow = columnConfig.reduce((obj, column) => {
                if (module === moduleParam.MEMBER) {
                    if(
                        row?.funded_amount &&
                        Array.isArray(row?.funded_amount) &&
                        (row.funded_amount.filter(fund => fund.donor_id === column.id)).length > 0
                    ){
                        obj[column.id] = row.funded_amount.filter(fund => fund.donor_id === column.id)[0].amount;
                    } else {
                        obj[column.id] = row[column.id];
                    }
                    return obj;
                } else {
                    obj[column.id] = row[column.id];
                    if(Array.isArray(row[column.id])){
                        obj[column.id] = getTotalAmountFromArray(row[column.id]);
                    }
                    // for ecommerce order export - product_list_data is object
                    if(row && row?.product_list_data) {
                        Object.entries(row.product_list_data).map(([key, value]) => {
                            if(column.id === key) {
                                obj[key] = value;
                            }
                        })
                    }
                    return obj;
                }
            }, {});
            return filteredRow;
        })
    }

    // function mergeData(data) {
    //     const resultMap = {};

    //     data.forEach(item => {
    //       const memberId = item.member_id;
          
    //       if (!resultMap[memberId]) {
    //         resultMap[memberId] = { ...item };
    //       } else {
    //         resultMap[memberId].cash_down_amount += item.cash_down_amount;
    //         resultMap[memberId].point_pay_amount += item.point_pay_amount;
    //         resultMap[memberId].total_amount += item.total_amount;
    //       }
    //     });
      
    //     return Object.values(resultMap).map(item => {
    //       return {
    //         ...item,
    //         cash_down_amount: resultMap[item.member_id] === item ? item.cash_down_amount : 0,
    //         point_pay_amount: resultMap[item.member_id] === item ? item.point_pay_amount : 0,
    //       };
    //     });
    //   }

    async function downloadData(moduleParamName, filter, fileFormat, fileName) {
        try {
            const data = (await getData(moduleParamName, filter)) || [];
            const columnConfig = await getColumnConfig(moduleParamName);
            const filteredData = getFilteredData(data, columnConfig, moduleParamName);
            const sheetHeader = [columnConfig.map(column => column.label)];

            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_aoa(workSheet, sheetHeader);
            XLSX.utils.sheet_add_json(workSheet, filteredData, { origin: 'A2', skipHeader: true });
            XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet 1');

            if(moduleParamName === 'invoice'){
                 
                const summaryData =  Object.values(data.reduce((acc, curr) => {
                    if (!acc[curr.invoice_id]) {
                        acc[curr.invoice_id] = curr;
                    }
                    return acc;
                }, {}));
               
                const columnConfigForSummary = await getColumnConfig('invoiceSummary');

                const filteredDataForSummary = getFilteredData(summaryData, columnConfigForSummary);
                const summarySheetHeader = [columnConfigForSummary.map(column => column.label)];
                const summaryWorkSheet = XLSX.utils.json_to_sheet([]);
                XLSX.utils.sheet_add_aoa(summaryWorkSheet, summarySheetHeader);
                XLSX.utils.sheet_add_json(summaryWorkSheet, filteredDataForSummary, { origin: 'A2', skipHeader: true });
                XLSX.utils.book_append_sheet(workBook, summaryWorkSheet, 'Invoice Summary');
            }

            if(moduleParamName === 'ecommerce_order') {
                const summaryData =  Object.values(data.reduce((acc, curr) => {
                    if (!acc[curr.order_id]) {
                        acc[curr.order_id] = curr;
                    }
                    return acc;
                }, {}));

                const columnConfigForSummary = await getColumnConfig('ecommerce_orderSummary');

                const filteredDataForSummary = getFilteredData(summaryData, columnConfigForSummary);
                const summarySheetHeader = [columnConfigForSummary.map(column => column.label)];
                const summaryWorkSheet = XLSX.utils.json_to_sheet([]);
                XLSX.utils.sheet_add_aoa(summaryWorkSheet, summarySheetHeader);
                XLSX.utils.sheet_add_json(summaryWorkSheet, filteredDataForSummary, { origin: 'A2', skipHeader: true });
                XLSX.utils.book_append_sheet(workBook, summaryWorkSheet, 'Ecommerce Order Summary');
            }

            if(fileFormat == excelFormat.XLSX) {
                return XLSX.writeFile(workBook, fileName);
            }

            const buffer = XLSX.write(workBook, {bookType: "csv", type:'array'});
            const blob = new Blob([buffer], { type: "text/csv" });
            saveAs(blob, fileName);
        }
        catch(err) {
            console.error(err.stack);
            return Promise.reject();
        }
    }

    return {
        excelFormat,
        moduleParam,

        getFilteredData,
        downloadData
    }
}

export default useDataExportActions;