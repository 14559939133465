export default `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAnrmEmjWkGMtJ1/boxa5Q
hlsrFsuXoTki3Xvu4suuQWvFgN63UATsy+eQ4NdpO05qSSX3Zy3/CSc1Ds9+IYqA
8ZMk2NLu6tOVIVDLgWCNptDE8zduUn4DC1r7BXLCoHBHJLMifxhHJmuC9LNS9u57
Pra8QwJLu4Thdf/BOt0gUFzi9J90OHK9l0OExM+lGF86nBXw6veKhjhn/qD+xgjz
MJqHVDbQ/bP801PGlTtdMxlrS2OEI2oreQUQC1YLpP3ZjMGkHU4N+MUhzTCozeoi
bJI7uPnnb+kd++nZg/iOKdON6H3OBzfBZzZveApm8AQ0zqaeOqqMCqsnPn2OMOJ5
UXK1jzQwZW6YEUzn6DXq8/tA+jrrTLWWKy0I1AOdA87vxFetXtpyameDqHnO9xLp
5cANA/rmNgbm/sncPEV182DfdMX0bJh9ZcdVqwjZVlCnb0zYzoHU9IwvzLKcXti5
0CJubCqia3oqpeDLH1HJDUvQ/REJXKcYE5PbgbrldoLzpCmEJ66wKVxN2T6JqIPv
fux59LWnNS7nwejRBk0wFEkiXnIE8e5SehP1VLS3PXyKawqs0D3xg+IUGnciGhKW
fAP00B3H2UVxJip8SK5OBw7T0iOB4nJwh3q2yVKKwmq3abowIucPjTbFmXTJTlR/
4GNNGozdYfWcn/fYtyOO8/8CAwEAAQ==
-----END PUBLIC KEY-----
`;