import React from 'react';
import { Box, Divider } from '@mui/material';

const style = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '10px 30px',
      backgroundColor: 'background',
}

/**
 * # SessionBox
 * @param {*} children  
 */
const SessionBoxHeader = ({ children }) => {
      return (
            <Box id="SessionBoxHeader" sx={{ width: '100%' }}>
                  <Box sx={style} >
                        {children}
                  </Box>
                  <Divider />
            </Box>

      );
};

export default SessionBoxHeader;
