import { createSlice } from "@reduxjs/toolkit";

const staticPageSlice = createSlice({
    name: 'static page',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        isLoading: false,
        isError: false,
        errorMessage: '',

        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    },
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            action.payload?.data && (state.data = action.payload.data);
            action.payload?.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
    }
});

export const {
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState
} = staticPageSlice.actions;

export default staticPageSlice.reducer;