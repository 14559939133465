import { createSlice } from "@reduxjs/toolkit";

// Slice
const themeSettingSlice = createSlice({
      name: 'theme',
      initialState: {
            mode: 'light',
            collapseSideBar: true,
            isOpenDrawer: false,
            width: '64px',
      },
      reducers: {
            changeThemeMode: (state) => {
                  state.mode = state.mode === 'light' ? 'dark' : 'light';
            },
            toggleSideBar: (state, action) => {
                  state.collapseSideBar = action.payload;
            },
            openDrawerRedu: (state, action) => {
                  state.isOpenDrawer = action.payload;
                  state.width = action.payload ? '200px' : '64px';
            }
      },
});

// Export
export const { changeThemeMode, toggleSideBar, openDrawerRedu } = themeSettingSlice.actions;

export default themeSettingSlice.reducer;