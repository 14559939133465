import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import {
      // fetchData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      fetchingAllocationData,
      fetchAllocationDataSuccess,
      fetchAllocationDataFail,

      // Create
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,
      setStartDate,
      setEndDate,
      setAllocationCurrentPage,
      setAllocationSearchTerm,
      setAllocationRowsPerPage,
      setAllocationStartDate,
      setAllocationEndDate,
      setBranchFilter,

} from "./_reducers";
import { useNoti } from "../../providers";
import { requestOneTimeToken } from "../../utils/helper";

const useFundReceivingActions = () => {
      const { showNoti } = useNoti();
      const dispatch = useDispatch();
      // const data = useSelector(state => state.fundReceiving.data);
      const currentPage = useSelector(state => state.fundReceiving.currentPage);
      const rowsPerPage = useSelector(state => state.fundReceiving.rowsPerPage);
      const searchTerm = useSelector(state => state.fundReceiving.searchTerm);
      const start_date = useSelector(state => state.fundReceiving.filters.start_date);
      const end_date = useSelector(state => state.fundReceiving.filters.end_date);

      const allocationCurrentPage = useSelector(state => state.fundReceiving.allocation.currentPage);
      const allocationRowsPerPage = useSelector(state => state.fundReceiving.allocation.rowsPerPage);
      const allocationSearchTerm = useSelector(state => state.fundReceiving.allocation.searchTerm);
      const allocationStart_date = useSelector(state => state.fundReceiving.allocation.filters.start_date);
      const allocationEnd_date = useSelector(state => state.fundReceiving.allocation.filters.end_date);
      const branchFilter = useSelector(state => state.fundReceiving.allocation.filters.branch_id);

      function getFundAllocationListByContractId(contractId) {
            dispatch(fetchingAllocationData());

            let url = `${API_URL.FundAllocation}?search_term=${allocationSearchTerm}&page_no=${allocationCurrentPage + 1}&page_size=${allocationRowsPerPage}&filters[funding_contract_id_number]=${contractId}&filters[branch_id]=${branchFilter}&filters[date_from]=${allocationStart_date ?? ''}&filters[date_to]=${allocationEnd_date ?? ''}`;

            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;

                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchAllocationDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchAllocationDataFail(err.response?.data));
            });
      }

      function fetchFundReceivingList() {
            dispatch(fetchingData());

            let url = `${API_URL.FundReceiving}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}`;

            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      // function getFundReceivingDetail(id) {
      //       return data.filter((d) => d._id == id)[0]
      // }

      async function getFundReceivingDetail(receivingId, apiOptions={}) {       
            dispatch(fetchingData());
            try {
                const url = `${API_URL.FundReceiving}/${receivingId}`;
                const res = await ApiRequest.get(url, {...apiOptions});
                const data = res.data.payload.data;
                dispatch(fetchDataSuccess(data));
                return data;
            }
            catch(err) {
            //     console.error(err);
                const errMsg = err.response?.data.errors?.message ?? err.message;
                dispatch(fetchDataFail(errMsg));
                return Promise.reject(errMsg);
            }
      }


      async function createFundReceiving(data) {
            dispatch(submittingData());
            try {
                  const ott = await requestOneTimeToken();
                  const url = API_URL.FundReceiving;
                  const res = await ApiRequest.post(url, { ...data, ott });
                  showNoti('Record created!', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundReceivingList();
                  return res.data.payload.data;
            }
            catch (err) {
                  dispatch(submitDataFail(err.message ?? 'Creating Funding Cointract failed'));
                  showNoti(err.message, 'error');
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  // !err.response && showNoti(err.message, 'error');
                  return Promise.reject(errors);
            }
      }

      
      // async function updateFundReceiving(fundReceiving) {
      //       let edit_fund_receiving_url = API_URL.FundReceiving + "/edit";

      //       try {
      //       const res = await ApiRequest.post(edit_fund_receiving_url, fundReceiving,
      //             {
      //                   headers: {
      //                   'Content-Type': 'application/json'
      //                   }
      //             });
      //       const editFundReceiving = res.data;
      //       return editFundReceiving;
      //       } catch (err) {
      //       const errMsg = err.response?.data.errors?.message ?? `${err.message}. Fail to Edit!`;
      //       return Promise.reject(errMsg);
      //       }
      // }

      async function updateFundReceiving(id, fundReceiving) {
            const url = `${API_URL.FundReceiving}/${id}`;
            dispatch(submittingData());
            try {
                  await ApiRequest.put(url, fundReceiving);
                  showNoti(`Funding Receiving Amount successfully updated!`,'success');
                  dispatch(submitDataSuccess());
                  fetchFundReceivingList();
            }
            catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  dispatch(submitDataFail(errMsg));
                  return Promise.reject();
            }
      }

      function changeSearchTerm(search_key) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(search_key));
      }
      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeStartDate(date) {
            dispatch(setStartDate(date));
      }
      function changeEndDate(date) {
            dispatch(setEndDate(date));
      }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      function changeAllocationSearchTerm(search_key) {
            dispatch(setAllocationCurrentPage(0));
            dispatch(setAllocationSearchTerm(search_key));
      }
      function changeAllocationCurrentPage(pageNo) {
            dispatch(setAllocationCurrentPage(pageNo));
      }

      function changeAllocationRowsPerPage(allocationRowsPerPage) {
            dispatch(setAllocationCurrentPage(0));
            dispatch(setAllocationRowsPerPage(allocationRowsPerPage));
      }

      function changeAllocationStartDate(date) {
            dispatch(setAllocationStartDate(date));
      }
      function changeAllocationEndDate(date) {
            dispatch(setAllocationEndDate(date));
      }

      function changeBranchFilter(branch_id) {
            dispatch(setAllocationCurrentPage(0));
            dispatch(setBranchFilter(branch_id));
      }

      return {
            // Actions
            getFundAllocationListByContractId,
            fetchFundReceivingList,
            getFundReceivingDetail,
            createFundReceiving,
            refreshDialogState,
            updateFundReceiving,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,

            changeAllocationSearchTerm,
            changeAllocationCurrentPage,
            changeAllocationRowsPerPage,
            changeAllocationStartDate,
            changeAllocationEndDate,
            changeBranchFilter
      }
}

export default useFundReceivingActions;