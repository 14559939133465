import { useDispatch, useSelector } from "react-redux";
import {
    resetData,
    setCurrentPage,
    setSearchTerm,
    setRowsPerPage,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setStartDate,
    setEndDate,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setStatusFilter,
    setCommitteeFilter,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState
} from './_reducers';
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import { useNoti } from "../../providers";
import { requestOneTimeToken } from "../../utils/helper";

const useSettlementActions = () => {
    const { showNoti } = useNoti();

    const dispatch = useDispatch();

    const searchTerm = useSelector(state => state.settlement.searchTerm);
    const regionCodeFilter = useSelector(state => state.settlement.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.settlement.filters.township_code);
    const statusFilter = useSelector(state => state.settlement.filters.status)
    const committeeFilter = useSelector(state => state.settlement.filters.committee_id);
    const startDate = useSelector(state => state.settlement.filters.startDate);
    const endDate = useSelector(state => state.settlement.filters.endDate);
    const currentPage = useSelector(state => state.settlement.currentPage);
    const rowsPerPage = useSelector(state => state.settlement.rowsPerPage);

    async function fetchSettlementListByShopId(shopId) {
        dispatch(resetData());
        const url = `${API_URL.Shop}/${shopId}/settlements?page_no=${currentPage + 1}&page_size=${rowsPerPage}`;
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    async function editSettlement(settlement){
        dispatch(submittingData());

        try {
            const url = `${API_URL.Settlement}/edit`;
            const res = await ApiRequest.post(url, settlement);
            const data = res.data.payload.data;
            dispatch(submitDataSuccess());
            showNoti('Settlement edited successfully', 'success');
            return data;
        }
        catch(err) {
            // dispatch(submitDataFail());
            let errMsg = err.response?.data.errors.message ?? err.message;
            const errData = err.response?.data.errors.data;
            if(errData) {
                errMsg = Object.entries(errData).reduce((str, [key, val]) => {
                    str += val;
                    console.error(key, val);
                    return str;
                }, "");
            }
            // showNoti(errMsg, 'error');
            dispatch(submitDataFail(errMsg));
        }
    }

    async function fetchSettlementList() {
        dispatch(resetData());
        const url = `${API_URL.Settlement}/?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}&filters[committee_id]=${committeeFilter}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}&filters[start_date]=${startDate || ''}&filters[end_date]=${endDate || ''}`;
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    async function fetchSettlementListByCommitteeId(committeeId) {
        dispatch(resetData());
        const url = `${API_URL.Settlement}/?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[committee_id]=${committeeId}`;
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    async function createNewSettlement(settlement_data) {
        dispatch(submittingData());

        try {
            const url = API_URL.Settlement;
            const res = await ApiRequest.post(url, settlement_data);
            const data = res.data.payload.data;
            dispatch(submitDataSuccess());
            return data;
        }
        catch(err) {
            // dispatch(submitDataFail());
            let errMsg = err.response?.data.errors.message ?? err.message;
            const errData = err.response?.data.errors.data;
            if(errData) {
                errMsg = Object.entries(errData).reduce((str, [key, val]) => {
                    str += val;
                    console.error(key, val);
                    return str;
                }, "");
            }
            // showNoti(errMsg, 'error');
            dispatch(submitDataFail(errMsg));
        }
    }

    async function getSettlementDetails(id, apiOptions={}) {
        dispatch(fetchingData());
        const url = `${API_URL.Settlement}/${id}`;
        try {
            const res = await ApiRequest.get(url, {...apiOptions});
            dispatch(fetchDataSuccess());
            return res.data.payload.data;
      }
      catch(err) {
            console.error(err);
            const errMsg = `Failed to get settlement detail! ${err.message}`;
            dispatch(fetchDataFail(errMsg));
            return Promise.reject();
      }
    }

    async function completeSettlement(settleData) {
        dispatch(submittingData());
        try {
              const ott = await requestOneTimeToken();
              const url = `${API_URL.Settlement}/complete`;
              const data = {
                ...settleData,
                ott
              }
              await ApiRequest.post(url, data);
              showNoti('Settlement completed successfully', 'success');
              dispatch(submitDataSuccess());
              fetchSettlementList();
              return Promise.resolve();
        }
        catch (err) {
              console.error(err);
              const errMsg = `Error! ${err.response?.data.errors.message ?? err.message}`;
              showNoti(errMsg, 'error');
              dispatch(submitDataFail());
              return Promise.reject();
        }
    }

    async function getSettlementTransactionHistory(settlementId) {
        try {
            const url = `${API_URL.Settlement}/${settlementId}/transaction-history?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
            const res = await ApiRequest.get(url);
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            showNoti(errMsg, 'error');
        }
    }

    async function createNewOrder(order) {
        dispatch(submittingData());

        try {
            const url = `${API_URL.Settlement}/order`;
            const res = await ApiRequest.post(url, order);
            const data = res.data.payload.data;
            showNoti('New order created successfully!', 'success');
            dispatch(submitDataSuccess());
            fetchSettlementList();
            return data;
        }
        catch(err) {
            let errMsg = err.response?.data.errors.message ?? err.message;
            const errData = err.response?.data.errors.data;
            if(errData) {
                errMsg = Object.entries(errData).reduce((str, [key, val]) => {
                    str += val;
                    console.error(key, val);
                    return str;
                }, "");
            }
            dispatch(submitDataFail(errMsg));
        }
    }

    async function changeToReady(settlementId) {
        
        dispatch(submittingData());
        try {
            const url = `${API_URL.Settlement}/${settlementId}/readyToSettle`;
            await ApiRequest.patch(url);
            showNoti('Order is ready to settle', 'success');
            dispatch(submitDataSuccess());
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? `Fail to change status! ${err.message}`;
            showNoti(errMsg, 'error');
            dispatch(submitDataFail());
            return Promise.reject();
        }
    }

    async function uploadOrderImage(fileData, _id) {
        dispatch(submittingData());
        const url = `${API_URL.Settlement}/${_id}/upload_order_image`;
    
        try {
            const res = await ApiRequest.post(url, fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
              const data = res?.data?.payload?.data;
              showNoti('image uploaded successfully', 'success');
              dispatch(submitDataSuccess());
              return data;
        } catch (err) {
            const errors = {};
            errors.data = err?.response?.data?.errors?.data;
            dispatch(submitDataFail(err.response.data.errors.message ?? "Uploading order image failed!"));
            return Promise.reject(errors);
        }
    }

    async function deleteOrderImage(imageUrl, _id) {
        dispatch(submittingData());

        const url = `${API_URL.Settlement}/${_id}/delete_order_image`;
        
        const deleteData ={
            url: imageUrl,
        }
        try {
            const res = await ApiRequest.patch(url, deleteData);
            const data = res?.data?.payload?.data;
            showNoti('image delete success', 'success');
            dispatch(submitDataSuccess());
            return data;
        } catch (err) {
            const errors = {};
            errors.data = err?.response?.data?.errors?.data;
            dispatch(submitDataFail(err.response.data.errors.message ?? "Deleting order image failed!"));
            return Promise.reject(errors);
        }
    }

    async function downloadOrderImage(imageUrl) {
        dispatch(submittingData());

        const url = `${API_URL.Settlement}/download_order_image`;
        const downloadData ={
            url: imageUrl,
        }
        try {
            const res = await ApiRequest.patch(url, downloadData);
            const data = res?.data?.payload?.data?.data;
            showNoti('image download success', 'success');
            dispatch(submitDataSuccess());
            return data;
        } catch (err) {
            const errors = {};
            errors.data = err?.response?.data?.errors?.data;
            dispatch(submitDataFail(err.response.data.errors.message ?? "Downloading order image failed!"));
            return Promise.reject(errors);
        }
    }

    function changeSearchTerm(search_key) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key));
    }

    function changeStartDate(date) {
        dispatch(setStartDate(date));
    }

    function changeEndDate(date) {
        dispatch(setEndDate(date));
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeRegionCodeFilter(code){
        dispatch(setCurrentPage(0));
        dispatch(setRegionCodeFilter(code));
    }

    function changeTownshipCodeFilter(code){
        dispatch(setCurrentPage(0));
        dispatch(setTownshipCodeFilter(code));
    }
    function changeStatusFilter(status){
        dispatch(setCurrentPage(0));
        dispatch(setStatusFilter(status));
    }
    function changeCommitteeFilter(committee_id){
        dispatch(setCurrentPage(0));
        dispatch(setCommitteeFilter(committee_id));
    }
    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        createNewOrder,
        fetchSettlementList,
        completeSettlement,
        getSettlementDetails,
        getSettlementTransactionHistory,
        fetchSettlementListByShopId,
        fetchSettlementListByCommitteeId,
        createNewSettlement,
        editSettlement,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeStatusFilter,
        changeCommitteeFilter,
        refreshDialogState,
        changeToReady,
        uploadOrderImage,
        deleteOrderImage,
        downloadOrderImage
    }
}

export default useSettlementActions;