import { useSelector } from "react-redux";
import useDepositActions from "./_actions";

export const useDeposit = () => {
      const {
            fetchDepositList,
            fetchDepositDetailById,
            fetchDepositListByCommitteeId,
            getLoanDepositDetail,
            getDepositTransactionList,
            createNewDeposit,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeStartDateForCommittee,
            changeEndDateForCommittee,
            changeDueDateFilter,
            changeRegionCodeFilter,
            changeTownshipCodeFilter,
            changeVillageTractCodeFilter,
            updateDeposit,
            completeDeposit,
            refreshDialogState,
            exportDepositData
      } = useDepositActions();

      const deposits = useSelector(state => state.deposit.data);
      const currentPage = useSelector(state => state.deposit.currentPage);
      const totalRows = useSelector(state => state.deposit.totalRows);
      const rowsPerPage = useSelector(state => state.deposit.rowsPerPage);
      const isLoading = useSelector(state => state.deposit.isLoading);
      const isError = useSelector(state => state.deposit.isError);
      const errorMessage = useSelector(state => state.deposit.errorMessage);
      const searchTerm = useSelector(state => state.deposit.searchTerm);
      const regionCodeFilter = useSelector(state => state.deposit.filters.region_code);
      const townshipCodeFilter = useSelector(state => state.deposit.filters.township_code);
      const villageTractCodeFilter = useSelector(state => state.deposit.filters.village_tract_code);
      const start_date = useSelector(state => state.deposit.filters.start_date);
      const end_date = useSelector(state => state.deposit.filters.end_date);
      const apiAction = useSelector(state => state.deposit.action);

      const startDateForCommittee = useSelector(state => state.deposit.filters.startDateForCommittee);
      const endDateForCommittee = useSelector(state => state.deposit.filters.endDateForCommittee);
      const dueDateFilter = useSelector(state => state.deposit.filters.dueDate);

      const dialogStates = useSelector(state => state.deposit.dialogStates);

      return {
            //state
            deposits,
            currentPage,
            totalRows,
            rowsPerPage,
            isLoading,
            isError,
            errorMessage,
            searchTerm,
            regionCodeFilter,
            townshipCodeFilter,
            villageTractCodeFilter,
            start_date,
            end_date,
            apiAction,
            startDateForCommittee,
            endDateForCommittee,
            dialogStates,
            dueDateFilter,

            //actions
            fetchDepositList,
            fetchDepositDetailById,
            fetchDepositListByCommitteeId,
            getLoanDepositDetail,
            getDepositTransactionList,
            createNewDeposit,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeStartDateForCommittee,
            changeEndDateForCommittee,
            changeRegionCodeFilter,
            changeTownshipCodeFilter,
            changeVillageTractCodeFilter,
            updateDeposit,
            completeDeposit,
            refreshDialogState,
            changeDueDateFilter,
            exportDepositData
      }
}

export { default as depositReducers } from './_reducers';