import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import ROUTES from "../configs/_routes";
import RootLayout from "../layouts/_root";
import ProtectedRoutes from "../providers/_protectedRoutes";

// Landing - Login
const Landing = lazy(() => import("../pages/Landing/_landing"));
const ResetPassword = lazy(() => import("../pages/UserAcount/ResetPassword"));
const ChangePassword = lazy(() => import("../pages/Landing/_changePassword"));

// App Version
const AppVersion = lazy(() => import("../pages/AppVersion/index"));
const AppVersionList = lazy(() => import("../pages/AppVersion/_list"));

// Fund Receiving
const FundReceiving = lazy(() => import("../pages/FundReceiving/index"));
const FundReceivingList = lazy(() => import("../pages/FundReceiving/_list"));
const FundReceivingDetail = lazy(() =>
  import("../pages/FundReceiving/_detail")
);
const FundReceivingInvoiceDetail = lazy(() =>
  import("../pages/FundReceiving/_receivingInvoiceListDetail")
);
const FundAllocationDetail = lazy(() =>
  import("../pages/FundReceiving/_allocationListDetail")
);

// Fund Handover
const FundHandover = lazy(() => import("../pages/FundHandingOver/index"));
const FundHandoverList = lazy(() => import("../pages/FundHandingOver/_list"));
const FundHandoverDetail = lazy(() =>
  import("../pages/FundHandingOver/_detail")
);

//Dashboard
const FinanceDashboard = lazy(() => import("../pages/Dashboards/finance"));
const FinanceDashboardDetail = lazy(() =>
  import("../pages/Dashboards/finance/_dashboard")
);
const FinanceHODashboard = lazy(() => import("../pages/Dashboards/finance_HO"));
const FinanceHODashboardDetail = lazy(() =>
  import("../pages/Dashboards/finance_HO/_dashboard")
);
const AdminDashboard = lazy(() => import("../pages/Dashboards/admin"));
const AdminDashboardDetail = lazy(() =>
  import("../pages/Dashboards/admin/_dashboard")
);

//Region
const Region = lazy(() => import("../pages/Region/index"));
const RegionList = lazy(() => import("../pages/Region/_list"));

//Township
const Township = lazy(() => import("../pages/Township"));
const TownshipList = lazy(() => import("../pages/Township/_list"));

//Office
const Office = lazy(() => import("../pages/Office/index"));
const OfficeList = lazy(() => import("../pages/Office/_list"));
const OfficeDetail = lazy(() => import("../pages/Office/_details"));

//Village Tract
const VillageTract = lazy(() => import("../pages/VillageTract/index"));
const VillageTractList = lazy(() => import("../pages/VillageTract/_list"));

//Village Tract
const Village = lazy(() => import("../pages/Village/index"));
const VillageList = lazy(() => import("../pages/Village/_list"));

// Donor
const Donor = lazy(() => import("../pages/Donor/index"));
const DonorList = lazy(() => import("../pages/Donor/_list"));
const DonorDetail = lazy(() => import("../pages/Donor/_detail"));

// Portal User
const PortalUser = lazy(() => import("../pages/PortalUser/index"));
const PortalUserList = lazy(() => import("../pages/PortalUser/_list"));
const PortalUserDetail = lazy(() => import("../pages/PortalUser/_detail"));

// Committee
const Committee = lazy(() => import("../pages/Committee/index"));
const CommitteeList = lazy(() => import("../pages/Committee/_list"));
const CommitteeDetail = lazy(() => import("../pages/Committee/_detail"));
// const OrderDetail = lazy(() => import('../pages/Committee/_orderDetail'));

// Member
const Member = lazy(() => import("../pages/Member/index"));
const MemberList = lazy(() => import("../pages/Member/_list"));
const MemberDetail = lazy(() => import("../pages/Member/_detail"));

// Shop
const Shop = lazy(() => import("../pages/Shop"));
const ShopList = lazy(() => import("../pages/Shop/_list"));
const ShopDetail = lazy(() => import("../pages/Shop/_detail"));

// Fund Receiving Invoices
const FundReceivingInvoices = lazy(() =>
  import("../pages/FundReceivingInvoices")
);
const FundReceivingInvoicesList = lazy(() =>
  import("../pages/FundReceivingInvoices/_list")
);
const FundReceivingInvoicesDetail = lazy(() =>
  import("../pages/FundReceivingInvoices/_detail")
);

// Fund Allocation
const FundAllocation = lazy(() => import("../pages/FundAllocation"));
const FundAllocationList = lazy(() => import("../pages/FundAllocation/_list"));
const FundAllocationDetails = lazy(() =>
  import("../pages/FundAllocation/_detail")
);

// Deposit
const Deposit = lazy(() => import("../pages/Deposit"));
const DepositList = lazy(() => import("../pages/Deposit/_list"));
const DepositDetail = lazy(() => import("../pages/Deposit/_detail"));

// Standard Schema
const StandardSchema = lazy(() => import("../pages/StandardSchema/index"));
const StandardSchemaList = lazy(() => import("../pages/StandardSchema/_list"));

// Portal User Roles
const PortalUserRoles = lazy(() => import("../pages/PortalUserRole/index"));
const PortalUserRolesList = lazy(() => import("../pages/PortalUserRole/_list"));

//Loan
const Loan = lazy(() => import("../pages/Loan/index"));
const LoanList = lazy(() => import("../pages/Loan/_list"));
const LoanDetail = lazy(() => import("../pages/Loan/_detail"));
const LoanTransactionHistoryList = lazy(() =>
  import("../pages/Loan/_loanTransactionHistoryList")
);

// Settlement
const Settlement = lazy(() => import("../pages/Settlements/index"));
const SettlementList = lazy(() => import("../pages/Settlements/_list"));
const SettlementDetail = lazy(() => import("../pages/Settlements/_details"));

// Settlement
const Invoice = lazy(() => import("../pages/Invoice/index"));
const InvoiceList = lazy(() => import("../pages/Invoice/_list"));
const InvoiceDetail = lazy(() => import("../pages/Invoice/_details"));

// Category
const Category = lazy(() => import("../pages/Category/index"));
const CategoryList = lazy(() => import("../pages/Category/_list"));
const CreateProduct = lazy(() => import("../pages/Catalog/_createProduct"));
const CategoryCreatePage = lazy(() => import("../pages/Category/_create"));
// const SettlementDetail = lazy(() => import('../pages/Settlements/_details'));

// Catalog
const Catalog = lazy(() => import("../pages/Catalog/index"));
const CatalogList = lazy(() => import("../pages/Catalog/_list"));

// Revenue Transfers
const RevenueTransfers = lazy(() => import("../pages/RevenueTransfers/index"));
const RevenueTransfersList = lazy(() =>
  import("../pages/RevenueTransfers/_list")
);
const RevenueTransfersDetail = lazy(() =>
  import("../pages/RevenueTransfers/_detail")
);
const RevenueTransactionList = lazy(() =>
  import("../pages/RevenueTransfers/__revenueTransactionList")
);

// Action Log
const ActionLog = lazy(() => import("../pages/Log"));
const ActionLogList = lazy(() => import("../pages/Log/_list"));

// Article
const Article = lazy(() => import("../pages/Articles/index"));
const ArticlePageList = lazy(() => import("../pages/Articles/_list"));
const ArticleCreatePage = lazy(() => import("../pages/Articles/_create"));

// Data Export
const DataExport = lazy(() => import("../pages/DataExport"));
const DataExportPage = lazy(() => import("../pages/DataExport/_home"));

// Static Page
const StaticPage = lazy(() => import("../pages/StaticPage"));
const StaticPageList = lazy(() => import("../pages/StaticPage/_list"));

// Maintenance Page
const MaintenancePage = lazy(() => import("../pages/Maintenance"));
const Maintenance = lazy(() => import("../pages/Maintenance/_maintenance"));

// For Unknow Routes
const Error404 = lazy(() => import("../pages/Error404"));


// Ecommerce Order
const EcommerceOrder = lazy(() => import("../pages/EcommerceOrder/index"));
const EcommerceOrderList = lazy(() => import("../pages/EcommerceOrder/_list"));
const EcommerceOrderPage = lazy(() => import("../pages/EcommerceOrder/_details"));

// Cluster
const Cluster = lazy(() => import("../pages/Cluster/index"));
const ClusterList = lazy(() => import("../pages/Cluster/_list"));
const ClusterDetail = lazy(() => import("../pages/Cluster/_detail"));

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: ROUTES.LANDING,
      element: <Landing />,
    },
    {
      path: ROUTES.CHANGE_PASSWORD,
      element: <ChangePassword />,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: (
        <ProtectedRoutes>
          {" "}
          <RootLayout />{" "}
        </ProtectedRoutes>
      ),
      children: [
        // Dashboard
        {
          path: ROUTES.ADMIN_DASHBOARD,
          element: <AdminDashboard />,
          children: [
            {
              path: "",
              element: <AdminDashboardDetail />,
            },
          ],
        },
        {
          path: ROUTES.FIN_DASHBOARD,
          element: <FinanceDashboard />,
          children: [
            {
              path: "",
              element: <FinanceDashboardDetail />,
            },
          ],
        },
        {
          path: ROUTES.FIN_HO_DASHBOARD,
          element: <FinanceHODashboard />,
          children: [
            {
              path: "",
              element: <FinanceHODashboardDetail />,
            },
          ],
        },
        // Fund Receiving
        {
          path: ROUTES.FUNDRECEIVING,
          element: <FundReceiving />,
          children: [
            {
              path: "",
              element: <FundReceivingList />,
            },
            {
              path: ":receiving_id",
              element: <FundReceivingDetail />,
            },
            {
              path: ":receiving_id/invoice/:fund_receiving_invoice_id",
              element: <FundReceivingInvoiceDetail />,
            },
            {
              path: ":receiving_id/allocation/:fund_allocation_id",
              element: <FundAllocationDetail />,
            },
          ],
        },
        // Fund HandingOver
        {
          path: ROUTES.FUNDHANDINGOVER,
          element: <FundHandover />,
          children: [
            {
              path: "",
              element: <FundHandoverList />,
            },
            {
              path: ":handover_id",
              element: <FundHandoverDetail />,
            },
          ],
        },
        // App version
        {
          path: ROUTES.APP_VERSION,
          element: <AppVersion />,
          children: [
            {
              path: "",
              element: <AppVersionList />,
            },
          ],
        },
        // Locations
        {
          path: ROUTES.REGION,
          element: <Region />,
          children: [
            {
              path: "",
              element: <RegionList />,
            },
          ],
        },
        {
          path: ROUTES.TOWNSHIP,
          element: <Township />,
          children: [
            {
              path: "",
              element: <TownshipList />,
            },
          ],
        },
        {
          path: ROUTES.VILLAGE_TRACT,
          element: <VillageTract />,
          children: [
            {
              path: "",
              element: <VillageTractList />,
            },
          ],
        },
        {
          path: ROUTES.VILLAGE,
          element: <Village />,
          children: [
            {
              path: "",
              element: <VillageList />,
            },
          ],
        },
        {
          path: ROUTES.CLUSTER,
          element: <Cluster />,
          children: [
            {
              path: "",
              element: <ClusterList />,
            },
            {
              path: ":cluster_id",
              element: <ClusterDetail />,
            },
          ],
        },
        // User Management
        {
          path: ROUTES.DONOR,
          element: <Donor />,
          children: [
            {
              path: "",
              element: <DonorList />,
            },
            {
              path: ":donor_id",
              element: <DonorDetail />,
            },
          ],
        },
        {
          path: ROUTES.PORTAL_USER,
          element: <PortalUser />,
          children: [
            {
              path: "",
              element: <PortalUserList />,
            },
            {
              path: ":portaluserid",
              element: <PortalUserDetail />,
            },
          ],
        },
        {
          path: ROUTES.OFFICE,
          element: <Office />,
          children: [
            {
              path: "",
              element: <OfficeList />,
            },
            {
              path: ":office_id",
              element: <OfficeDetail />,
            },
          ],
        },
        {
          path: ROUTES.COMMITTEE,
          element: <Committee />,
          children: [
            {
              path: "",
              element: <CommitteeList />,
            },
            {
              path: ":committee_id",
              element: <CommitteeDetail />,
            },
            {
              path: ":committee_id/members/:member_id",
              element: <MemberDetail />,
            },
            {
              path: ":committee_id/members/:member_id/invoice/:invoice_id",
              element: <InvoiceDetail />,
            },
            {
              path: ":committee_id/deposit/:deposit_id",
              element: <DepositDetail />,
            },
            {
              path: ":committee_id/fund-transfers/:handover_id",
              element: <FundHandoverDetail />,
            },
            {
              path: ":committee_id/loan/:loan_id",
              element: <LoanDetail />,
            },
            {
              path: ":committee_id/loan/:loan_id/transaction_history",
              element: <LoanTransactionHistoryList />,
            },
            {
              path: ":committee_id/settlement/:settlement_id",
              element: <SettlementDetail />,
            },
            {
              path: ":committee_id/settlement/:settlement_id/invoice/:invoice_id",
              element: <InvoiceDetail />,
            },
          ],
        },
        {
          path: ROUTES.MEMBER,
          element: <Member />,
          children: [
            {
              path: "",
              element: <MemberList />,
            },
            {
              path: ":member_id",
              element: <MemberDetail />,
            },
            {
              path: ":member_id/invoice/:invoice_id",
              element: <InvoiceDetail />
            }
          ],
        },
        {
          path: ROUTES.SHOP,
          element: <Shop />,
          children: [
            {
              path: "",
              element: <ShopList />,
            },
            {
              path: ":shopid",
              element: <ShopDetail />,
            },
            {
              path: ":shopid/settlements/:settlement_id",
              element: <SettlementDetail />,
            },
            {
              path: ":shopid/invoice/:invoice_id",
              element: <InvoiceDetail />,
            },
          ],
        },
        // Permission
        {
          path: ROUTES.SCHEMA,
          element: <StandardSchema />,
          children: [
            {
              path: "",
              element: <StandardSchemaList />,
            },
          ],
        },
        //Portal User
        {
          path: ROUTES.ROLES,
          element: <PortalUserRoles />,
          children: [
            {
              path: "",
              element: <PortalUserRolesList />,
            },
          ],
        },
        // Deposit
        {
          path: ROUTES.DEPOSIT,
          element: <Deposit />,
          children: [
            {
              path: "",
              element: <DepositList />,
            },
            {
              path: ":deposit_id",
              element: <DepositDetail />,
            },
          ],
        },
        // Fund Receiving Invoice List
        {
          path: ROUTES.FUND_RECEIVING_INVOICES,
          element: <FundReceivingInvoices />,
          children: [
            {
              path: "",
              element: <FundReceivingInvoicesList />,
            },
            {
              path: ":fund_receiving_invoice_id",
              element: <FundReceivingInvoicesDetail />,
            },
          ],
        },
        // Fund Allocation Invoice List
        {
          path: ROUTES.FUND_ALLOCATION,
          element: <FundAllocation />,
          children: [
            {
              path: "",
              element: <FundAllocationList />,
            },
            {
              path: ":fund_allocation_id",
              element: <FundAllocationDetails />,
            },
          ],
        },
        // Loan
        {
          path: ROUTES.LOAN,
          element: <Loan />,
          children: [
            {
              path: "",
              element: <LoanList />,
            },
            {
              path: ":loan_id",
              element: <LoanDetail />,
            },
            {
              path: ":loan_id/transaction_history",
              element: <LoanTransactionHistoryList />,
            },
          ],
        },
        {
          path: ROUTES.SETTLEMENT,
          element: <Settlement />,
          children: [
            {
              path: "",
              element: <SettlementList />,
            },
            {
              path: ":settlement_id",
              element: <SettlementDetail />,
            },
            {
              path: ":settlement_id/invoice/:invoice_id",
              element: <InvoiceDetail />,
            },
          ],
        },
        {
          path: ROUTES.INVOICE,
          element: <Invoice />,
          children: [
            {
              path: "",
              element: <InvoiceList />,
            },
            {
              path: ":invoice_id",
              element: <InvoiceDetail />,
            },
          ],
        },
        {
          path: ROUTES.CATALOG,
          element: <Catalog />,
          children: [
            {
              path: "",
              element: <CatalogList />,
            },
            {
              path: `${ROUTES.CATALOG}/create`,
              element: <CreateProduct />
            },
            {
              path: `:product_id`,
              element: <CreateProduct />,
            }
          ],
        },
        {
          path: ROUTES.CATEGORY,
          element: <Category />,
          children: [
            {
              path: "",
              element: <CategoryList />,
            },
            {
              path: "/product-categories/create",
              element: <CategoryCreatePage />,
            },
            {
              path: ":category_id",
              element: <CategoryCreatePage />,
            },
          ],
        },
        //Ecommerce Order
        {
          path: ROUTES.ECOMMERCEORDER,
          element: <EcommerceOrder />,
          children: [
            {
              path: "",
              element: <EcommerceOrderList />,
            },
            // {
            //   path: "/ecommerce-orders/create",
            //   element: <EcommerceOrderDetail />,
            // },
            {
              path: ":order_id",
              element: <EcommerceOrderPage />,
            },
          ],
        },
        {
          path: ROUTES.REVENUETRANSFERS,
          element: <RevenueTransfers />,
          children: [
            {
              path: "",
              element: <RevenueTransfersList />,
            },
            {
              path: ":revenue_transfers_id",
              element: <RevenueTransfersDetail />,
            },
            {
              path: ":revenue_transfers_id/transaction_history",
              element: <RevenueTransactionList />,
            },
            {
              path: ":revenue_transfers_id/settlements/:settlement_id",
              element: <SettlementDetail />,
            },
            {
              path: ":revenue_transfers_id/deposits/:deposit_id",
              element: <DepositDetail />,
            },
          ],
        },
        {
          path: ROUTES.ACTION_LOG,
          element: <ActionLog />,
          children: [
            {
              path: "",
              element: <ActionLogList />,
            },
          ],
        },
        {
          path: ROUTES.ARTICLE,
          element: <Article />,
          children: [
            {
              path: "",
              element: <ArticlePageList />,
            },
            {
              path: "/articles/create",
              element: <ArticleCreatePage />,
            },
            {
              path: ":article_id",
              element: <ArticleCreatePage />,
            },
          ],
        },
        {
          path: ROUTES.DATA_EXPORT,
          element: <DataExport />,
          children: [
            {
              path: "",
              element: <DataExportPage />,
            },
          ],
        },
        {
          path: ROUTES.STATIC_PAGE,
          element: <StaticPage />,
          children: [
            {
              path: "",
              element: <StaticPageList />,
            },
          ],
        },
        // Maintenance
        {
          path: ROUTES.MAINTENANCE,
          element: <MaintenancePage />,
          children: [
            {
              path: '',
              element: <Maintenance/>
            }
          ]
        },
        // 404 Not Found
        {
          path: "*",
          element: <Error404 />,
        },
      ],
    },
  ]);

  return routes;
};

export default AppRoutes;
