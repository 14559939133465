import React from "react";
import StyledIconButton from "./StyledIconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNoti } from "../../providers";


const CopyIconButton = ({ id }) => {
    const {showNoti} = useNoti();
    const [isCopy, setIsCopy] = React.useState(false);

    const onClick = React.useCallback((e) => {
        e.stopPropagation();
            if(id){
                !isCopy && window.navigator.clipboard.writeText(id)
                .then(() => {
                    console.info("Successfully copied")
                    setIsCopy(true);
                })
                .catch((err) => {
                    console.error("Failed to copy: ", err);
                    showNoti('Failed to copy', 'error');
                    setIsCopy(false);
                    return;
                });
            }else {
                showNoti('No Id to copy', 'error');
                setIsCopy(false);
                return;
            }

        setTimeout(() => {
            setIsCopy(false); // after 1.5 seconds change previous copy state
        }, 1500);
    }, [id, isCopy]);

    return (
        <>
            <StyledIconButton onClick={(e) => onClick(e)} label={isCopy ? 'Copied' : 'Copy'}>
                <ContentCopyIcon sx={{ paddig: '10px' }} />
            </StyledIconButton>
        </>
    )
}

export default React.memo(CopyIconButton);