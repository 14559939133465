import { useSelector } from "react-redux";
import useBreadCrumbActions  from './_actions';

export const useBreadCrumb = () => {
    const {
        setBreadCrumbStep,
    } = useBreadCrumbActions();

    const steps = useSelector(state => state.breadCrumb.steps);

    return {
        //state
        steps,

        //actions
        setBreadCrumbStep,
    }
}

export {default as breadCrumbReducer} from './_reducers';