import { createSlice } from "@reduxjs/toolkit";

const loanContractDetailSlice = createSlice({
    name: 'loan_contract_detail',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        searchTerm: '',
        filters: {
            status: '',
        },

        isLoading: false,
        isError: false,
        errorMessage: '',
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.filters.status = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess: (state, action) => {
            action.payload?.data && (state.data = action.payload.data);
            action.payload?.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        }
    }
});

export const {
    setData,
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    setSearchTerm,
    setStatusFilter,
    setStartDateFilter,
    setEndDateFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataFail,
    submitDataSuccess
} = loanContractDetailSlice.actions;

export default loanContractDetailSlice.reducer;