import { useSelector } from "react-redux";
import useTownshipActions from './_actions';

export const useTownship = () => {
      const {
            fetchTownshipListByClusterId,
            fetchTownshipList,
            getTownshipList,
            addNewTownship,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            changeRegionIdFilter,
            refreshDialogState,
            editTownship,
            downloadTownshipFileUploadTemplate,
            createMultipleTownships,
            changeCluster,
      } = useTownshipActions();

      const townships = useSelector(state => state.township.data);
      const currentPage = useSelector(state => state.township.currentPage);
      const totalRows = useSelector(state => state.township.totalRows);
      const rowsPerPage = useSelector(state => state.township.rowsPerPage);
      const isLoading = useSelector(state => state.township.isLoading);
      const isError = useSelector(state => state.township.isError);
      const searchTerm = useSelector(state => state.township.searchTerm);
      const regionIdFilter = useSelector(state => state.township.filters.region_id);

      const dialogStates = useSelector(state => state.township.dialogStates);
      
      return {
            //state
            townships,
            isLoading,
            isError,
            currentPage,
            rowsPerPage,
            totalRows,
            searchTerm,
            regionIdFilter,
            dialogStates,
            //actions
            fetchTownshipListByClusterId,
            fetchTownshipList,
            getTownshipList,
            addNewTownship,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            changeRegionIdFilter,
            refreshDialogState,
            editTownship,
            downloadTownshipFileUploadTemplate,
            createMultipleTownships,
            changeCluster
      }
}

export { default as townshipReducer } from './_reducers';