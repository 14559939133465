import { DataGrid } from "@mui/x-data-grid";
import React from "react";

/**
 * ## DataTable
 * @param {Array} rows
 * @param {Array} columns
 * @param {Event} onSelectRows
 * @param {Object} sx
 * @param {Boolean} isLoading
 * @param {Array} selectedRows
 * @returns 
 */
const DataTable = (props) => {
    const [selectionModel, setSelectionModel ] = React.useState([]);

    const onSelectRows = (selectedIds=[]) => {
        setSelectionModel(selectedIds);
        const selectedRows = props.rows.filter(r => selectedIds.includes(r.id));
        props.onSelectRows(selectedRows);
    }

    React.useEffect(() => {
        if(props.selectedRows.length > 0) {
            setSelectionModel(props.selectedRows.map(r => r.id));
        }
    }, [props.selectedRows]);

    return (
        <DataGrid
            loading={props.isLoading ?? false}
            rows={props.rows}
            columns={props.columns}
            checkboxSelection
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={onSelectRows}
            hideFooterPagination={true}
            sx={{border: 'none'}}
            localeText={{
                noRowsLabel: props.errMsg
            }}
        />
    )
}

export default DataTable;