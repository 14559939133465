import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import {
      // setData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Create
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,

} from "./_reducers";
import { useNoti } from "../../providers";

const useProductCategoryActions = () => {
      const { showNoti } = useNoti();
      const dispatch = useDispatch();
    //   const data = useSelector(state => state.productCategory.data);
      const currentPage = useSelector(state => state.productCategory.currentPage);
      const rowsPerPage = useSelector(state => state.productCategory.rowsPerPage);
      const searchTerm = useSelector(state => state.productCategory.searchTerm);


      

      function fetchProductCategoryList() {
            dispatch(fetchingData());

            let get_category_url = `${API_URL.ProductCategory}/list?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`;

            ApiRequest.get(get_category_url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      function getAllProductCategoryList() {
            dispatch(fetchingData());

            let get_category_url = `${API_URL.ProductCategory}/list`;

            ApiRequest.get(get_category_url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      async function getRelatedProductList(id, apiOptions={}) {
            dispatch(fetchingData());
            const url = `${API_URL.ProductCategory}/${id}`;
            try {
                const res = await ApiRequest.get(url, {...apiOptions});
            //     dispatch(fetchDataSuccess());
                return res.data.payload.data;
          }
          catch(err) {  
                console.error(err);
                const errMsg = `Failed to get related product list! ${err.message}`;
                dispatch(fetchDataFail(errMsg));
                return Promise.reject();
          }
        }

      async function createProductCategory(data) {

            dispatch(submittingData());
            try {
                  const url = API_URL.ProductCategory;
                  const res = await ApiRequest.post(url, data);
                  showNoti('Product Category created!', 'success');
                  dispatch(submitDataSuccess());
                  fetchProductCategoryList();
                  return res.data.payload.data;
            }
            catch (err) {
                  dispatch(submitDataFail(err.message ?? 'Creating Category Failed'));
                  showNoti(err.message, 'error');
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  // !err.response && showNoti(err.message, 'error');
                  return Promise.reject(errors);
            }
      }

      async function updateProductCategory (data) {
            dispatch(submittingData());
            const url = `${API_URL.ProductCategory}/edit`;
          
            try {
                const res = await ApiRequest.post(url, data);
                  const category = res.data
                  dispatch(submitDataSuccess())
                  return category
            } catch (err) {
              const errors = {};
                errors.data = err?.response?.data?.errors?.data;
                dispatch(submitDataFail(err.response.data.errors.message?? "Updating category failed!"));
                return Promise.reject(errors);
            }
           }

     async function deleteProductCategory(id, apiOptions={}) {
            dispatch(fetchingData());
            const url = `${API_URL.ProductCategory}/${id}`;
            try {
                await ApiRequest.delete(url, {...apiOptions});
            //     dispatch(fetchDataSuccess());
            //     return res.data.payload.data;
          }
          catch(err) {
                console.error(err);
                const errMsg = `Failed to delete category! ${err.message}`;
                dispatch(fetchDataFail(errMsg));
                return Promise.reject();
          }
        }

      function changeSearchTerm(search_key) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(search_key));
      }
      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }


      return {
            // Actions
            fetchProductCategoryList,
            getAllProductCategoryList,
            getRelatedProductList,
            createProductCategory,
            updateProductCategory,
            deleteProductCategory,
            refreshDialogState,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
      }
}

export default useProductCategoryActions;