import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import OtpInput from 'react-otp-input';
import React from "react";
import { LoadingButton, StyledDialogAction, StyledDialogHeader } from "..";

/**
 * 
 * @param {Boolean} isOpen true to open dialog false to close dialog
 * @param {Event} handleClose event
 * @param {Event} submit event
 * @param {Boolean} isError
 * @param {Boolean} isLoading
 * @param {String} errorMessage
 * @returns DialogBox
 */
const OtpDialog = (props) => {
    const [code, setCode] = React.useState('');

    React.useEffect(() => {
        setCode('')
    }, [props.isOpen])

    const onHandleClose = () => {
        props.handleClose();
    };

    const onSubmit = () => {
        props.submit(code);
    }

    return (
        <Dialog open={props.isOpen} fullWidth maxWidth='sm' sx={{ height: 'auto' }}>
            <StyledDialogHeader title="Type OTP"/>
            <DialogContent>
                <Grid sx={{ display:'flex', height: "100px", justifyContent: 'center', alignItems: 'center'}}>
                    <OtpInput
                        value={code}
                        onChange={setCode}
                        numInputs={6}
                        inputStyle={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            border: '0',
                            marginLeft: '8px',
                            marginRight: '8px',
                            background: '#D9D9D9',
                            fontSize: '20px'
                        }}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                    />
                </Grid>
            </DialogContent>
            <StyledDialogAction isError={props.isError || false} errMsg={props.errorMessage || ''}>
                <Button onClick={onHandleClose} variant="outlined">Close</Button>
                <LoadingButton
                    isLoading={props.isLoading ?? false}
                    label="Confirm"
                    onClick={onSubmit}
                    loadingIconSize={20}
                    disabled={(code.length < 6)} />
            </StyledDialogAction>
        </Dialog>
    )
}

export default OtpDialog;