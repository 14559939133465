import { useDispatch, useSelector } from "react-redux";
import {
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm
} from './_reducers';
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";

const useLogActions = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector(state => state.log.currentPage);
    const rowsPerPage = useSelector(state => state.log.rowsPerPage);
    const searchTerm = useSelector(state => state.log.searchTerm);

    function fetchLogList() {
        dispatch(fetchingData());
        const url = `${API_URL.Log}?page_no=${currentPage+1}&page_size=${rowsPerPage}&search_term=${searchTerm}`;
        ApiRequest.get(url)
        .then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({data, total_items}));
        })
        .catch(err => {
            const errMsg = err.response?.data.errors.message || err.message;
            dispatch(fetchDataFail(errMsg));
        })
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(st) {
        dispatch(setSearchTerm(st));
    }

    return {
        fetchLogList,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm
    }
}

export default useLogActions;