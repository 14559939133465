import { createSlice } from "@reduxjs/toolkit";

const ecommerceOrderSlice = createSlice({
  name: "ecommerceOrder",
  initialState: {
    data: [],

    totalRows: 0,
    currentPage: 0,
    rowsPerPage: 5,

    newOrder : 0,

    searchTerm: "",
    filters: {
      status: "",
      startDate: null,
      endDate: null,
      region_id : ''
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
    action : {
      isLoading: false,
      isError: false,
      errorMessage:""
    }
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setTotalRows: (state, action) => {
      state.totalRows = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    // setCategoryIdFilter: (state, action) => {
    //   state.filters.category_id = action.payload;
    // },
    setStartDate: (state, action) => {
      state.filters.startDate = action.payload;
    },
    setEndDate : (state, action) => {
      state.filters.endDate = action.payload;
    },

    setRegionCodeFilter : (state, action) => {
      state.filters.region_id = action.payload;
    },

    setNewOrderCount : (state, action) => {
      state.newOrder = action.payload;
    },

    // fetch
    fetchingData : (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchDataSuccess : (state, action) => {
      action.payload.data && (state.data = action.payload.data);
      action.payload.total_items && (state.totalRows = action.payload.total_items);

      state.isLoading = false;
      state.isError = false;
    },
    fetchDataFail : (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },

    submittingData: (state) => {
      state.action.isLoading = true;
      state.action.isError = false;
      state.action.errorMessage = '';
    },
   
    submitDataSuccess : (state) => {
      state.action.isLoading = false;
      state.action.isError = false;
    },
    
    submitDataFail : (state,action) => {
      state.action.isLoading = false;
      state.action.isError = true;
      state.action.errorMessage = action.payload;
    },

    submittingCancel : (state) => {
      state.action.isLoading = false;
      state.action.isError = false;
      state.action.errorMessage = '';
    },

    setStatusFilter: (state, action) => {
      state.filters.status = action.payload;
    },

    reloadDialogState: (state) => {
      state.action = { isLoading: false, isError: false, errorMessage: ''}
    }
  },
});

export const {
  setData,
  setTotalRows,
  setCurrentPage,
  setRowsPerPage,
  setSearchTerm,
  setStartDate,
  setEndDate,
  setRegionCodeFilter,
  setStatusFilter,
  setNewOrderCount,
  submittingData,
  reloadDialogState,
  
  // fetch api
  fetchingData,
  fetchDataSuccess,
  fetchDataFail,
  submitDataSuccess,
  submitDataFail,
  submittingCancel
} = ecommerceOrderSlice.actions;

export default ecommerceOrderSlice.reducer;
