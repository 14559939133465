import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import { useNoti } from '../../providers';
import {
      // setData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,
      submittingData,
      submitDataFail,
      submitDataSuccess,
} from "./_reducers";

const useClusterActions = () => {
    const dispatch = useDispatch();
    const { showNoti } = useNoti();

    const clusters = useSelector(state => state.cluster.data);
    const currentPage = useSelector(state => state.cluster.currentPage);
    const rowsPerPage = useSelector(state => state.cluster.rowsPerPage);
    const searchTerm = useSelector(state => state.cluster.searchTerm);

    function fetchClusterList() {
        dispatch(fetchingData());

        const url = `${API_URL.Cluster}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`;
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            dispatch(fetchDataFail(err.response?.data));
        });
    }

    function getClusterDetail(id) {
        return clusters.filter(cluster => cluster._id == id)[0] || null;
    }

    async function createNewCluster(data) {
        const url = API_URL.Cluster;
        try {
            dispatch(submittingData());
            await ApiRequest.post(url, data);
            showNoti(`New cluster '${data.name}' created successfully`, 'success');
            dispatch(submitDataSuccess());
            fetchClusterList();
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            const errors = err.response?.data.errors.data || null;
            dispatch(submitDataFail(errMsg));
            return Promise.reject(errors);
        }
    }

    function changeSearchTerm(search_key) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key));
    }
    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    return {
        fetchClusterList,
        getClusterDetail,
        createNewCluster,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage
    }
}

export default useClusterActions;