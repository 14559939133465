import { useSelector } from 'react-redux';
import useRevenueTransfersActions from './_actions';

export const useRevenueTransfers = () => {
    const {
        //actions
        // fetchRevenueList,
        getRevenueTransfersList,
        getRevenueTransfersDetails,
        getRevenueTransferHistory,
        completeRevenueTransfer,
        createNewRevenueTransfers,
        refreshDialogState,

        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeStatusFilter,
        changeOfficeFilter,
        changeCurrentPage, 
        changeRowsPerPage,
    } = useRevenueTransfersActions();

    // const revenue = useSelector(state => state.revenue.data);
    const revenueTransfers = useSelector(state => state.revenueTransfers.data);
    const searchTerm = useSelector(state => state.revenueTransfers.searchTerm);
    const start_date = useSelector(state => state.revenueTransfers.filters.start_date);
    const end_date = useSelector(state => state.revenueTransfers.end_date);
    const currentPage = useSelector(state => state.revenueTransfers.currentPage);
    const totalRows = useSelector(state => state.revenueTransfers.totalRows);
    const rowsPerPage = useSelector(state => state.revenueTransfers.rowsPerPage);
    const isLoading = useSelector(state => state.revenueTransfers.isLoading);
    const isError = useSelector(state => state.revenueTransfers.isError);
    const errorMessage = useSelector(state =>state.revenueTransfers.errorMessage);
    const apiAction = useSelector(state => state.revenueTransfers.action);
    const statusFilter = useSelector(state => state.revenueTransfers.filters.status);
    const officeFilter = useSelector(state => state.revenueTransfers.filters.office_id);

    return {         
        // revenue,
        statusFilter,
        revenueTransfers,
        searchTerm,
        start_date,
        end_date,
        currentPage,
        rowsPerPage,
        totalRows,
        isLoading,
        isError,
        errorMessage,
        apiAction,
        officeFilter,

        // fetchRevenueList,
        getRevenueTransfersList,
        getRevenueTransfersDetails,
        getRevenueTransferHistory,
        createNewRevenueTransfers,
        completeRevenueTransfer,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeStatusFilter,
        changeOfficeFilter,
        
        changeCurrentPage,
        changeRowsPerPage,
        refreshDialogState,
    }
}

export { default as revenueTransfersReducer } from './_reducers';