import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useCommittee, useMetaData } from '../../features';

const AutocompleteField = ({ name, control, options, label, error, errorMsg, onFieldChange, disable }) => {
    return (
        <Controller
            name={name}
            control={control}
            autoComplete='off'
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    options={options}
                    readOnly={disable}
                    getOptionLabel={(option) => option.label ?? ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={(e, value) => {
                        value ? field.onChange(value) : field.onChange(null);
                        onFieldChange && onFieldChange(value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            margin='none'
                            autoComplete='nothing'
                            {...params}
                            size='small'
                            label={label}
                            error={error}
                            helperText={errorMsg}
                        />
                    )}
                />
            )}
        // onChange={onFieldChange}
        />
    );
};

const LocationFormField = ({ fields = [], control, errors, setValue, disable }) => {

    const { officeMeta, getLocByFilter } = useMetaData();
    const { getCommitteeListByLocation } = useCommittee();

    //options 
    const [ regions, setRegions ] = React.useState([]);
    const [townships, setTownShips] = React.useState([]);
    const [village_tracts, setVillageTracts] = React.useState([]);
    const [villages, setVillages] = React.useState([]);
    const [offices, setOffices] = React.useState(officeMeta);
    const [committees, setCommittees] = React.useState([]);

    // selected
    const [regionSelected, setSelectedRegion] = React.useState(null);
    const [townshipSelected, setSelectedTownship] = React.useState(null);
    const [villageTractSelected, setSelectedVillageTract] = React.useState(null);

    async function getLocMetadata(rig, tsp, vt){
        let { regions, townships, village_tracts, villages } = await getLocByFilter(rig, tsp, vt);
        if(villages){
            setVillages(villages);
        }else if(village_tracts){
            setVillageTracts(village_tracts);
        } else if(townships){
            setTownShips(townships);
        } else if(regions){
            setRegions(regions);
        }
  }


    function getCommittees(value) {
        if (!value) {
            setValue('committee', null);
        } else {
            getCommitteeListByLocation(regionSelected?.mimu_code ?? '', townshipSelected?.mimu_code ?? '', villageTractSelected?.mimu_code ?? '', value?.mimu_code ?? '').then(committees => {
                setCommittees(committees.map(item => ({ ...item, label: item.mm_committee_name })));
            })
        }
    }

    React.useEffect(() => {
        getLocMetadata();
    },[])


    return (
        <Box >
            <Grid container direction='row'>
                <Grid item xs={3} md={3} sm={12} sx={{pr: 2}}>
                    {
                        fields.includes('region') ? (
                            <AutocompleteField
                                name={"region"}
                                control={control}
                                options={regions}
                                label={'Region'}
                                error={!!errors?.region}
                                disable={disable}
                                errorMsg={errors?.region?.message}
                                onFieldChange={(value) => {
                                    setValue('township', null);
                                    setValue('village_tract', null);
                                    setValue('village', null);
                                    setValue('committee', null);
                                    setValue('office', null);
                                    setSelectedRegion(value);
                                    if (value) {
                                        getLocMetadata(value.id);
                                        setOffices(officeMeta.filter(off => off.connected_region.includes(value.id)));
                                    } else {
                                        setTownShips([]);
                                        setOffices([]);
                                    }
                                }}
                            />
                        ) : null
                    }
                </Grid>
                <Grid item xs={3} md={3} sm={12} sx={{pr: 2}}>
                    {
                        fields.includes('township') ? (
                            <AutocompleteField
                                name={"township"}
                                control={control}
                                options={townships}
                                label={'Township'}
                                error={!!errors?.township}
                                disable={disable}
                                errorMsg={errors?.township?.message}
                                onFieldChange={(value) => {
                                    setValue('village_tract', null);
                                    setValue('village', null);
                                    setValue('committee', null);
                                    setSelectedTownship(value);
                                    if (value) {
                                        getLocMetadata(regionSelected.id, value.id);
                                    } else {
                                        setVillageTracts([]);
                                    }
                                }}
                            />
                        ) : null
                    }
                </Grid>
                <Grid item xs={3} md={3} sm={12}>
                    {
                        fields.includes('village_tract') ? (
                            <AutocompleteField
                                name={"village_tract"}
                                control={control}
                                options={village_tracts}
                                label={'Village Tract'}
                                error={!!errors?.village_tract}
                                disable={disable}
                                errorMsg={errors?.village_tract?.message}
                                onFieldChange={(value) => {
                                    setValue('village', null);
                                    setValue('committee', null);
                                    setSelectedVillageTract(value);
                                    if (value) {
                                        getLocMetadata(regionSelected.id, townshipSelected.id, value.id);
                                    } else {
                                        setVillages([]);
                                    }
                                    getCommittees(value)
                                }}
                            />
                        ) : null
                    }
                </Grid>
                <Grid item xs={3} md={3} sm={12}>
                    {
                        fields.includes('villages') ? (
                            <AutocompleteField
                                name={"village"}
                                control={control}
                                options={villages}
                                label={'Village'}
                                error={!!errors?.village}
                                disable={disable}
                                errorMsg={errors?.village?.message}
                                onFieldChange={(value) => {
                                    setValue('committee', null);
                                    getCommittees(value)
                                }}
                            />
                        ) : null
                    }
                </Grid>
            </Grid>
            {
                fields.includes('committee') ? (
                    <AutocompleteField
                        name={"committee"}
                        control={control}
                        options={committees}
                        label={'Committee'}
                        error={!!errors?.committee}
                        disable={disable}
                        errorMsg={errors?.committee?.message}
                        onFieldChange={() => { }}
                    />
                ) : null
            }

            {
                fields.includes('office') ? (
                    <AutocompleteField
                        name={"office"}
                        control={control}
                        options={offices}
                        label={'Connected Office'}
                        error={!!errors?.office}
                        disable={disable}
                        errorMsg={errors?.office?.message}
                    />
                ) : null
            }

        </Box>
    )
};

export default LocationFormField;