import { createSlice } from "@reduxjs/toolkit";

const townshipSlice = createSlice({
      name: 'township',
      initialState: {
            data: [],
            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,
            searchTerm: '',
            filters: {
                  region_id: '',
                  cluster_id: ''
            },
            isLoading: false,
            isError: false,
            errorMsg: '',

            dialogStates: {
                  isLoading: false,
                  isError: false,
                  errorMessage: '',
            },
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setTotalRows: (state, action) => {
                  state.totalRows = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            setRegionIdFilter: (state, action) => {
                  state.filters.region_id = action.payload;
            },
            setClusterIdFilter: (state, action) => {
                  state.filters.cluster_id = action.payload;
            },
            // Fetch List
            fetchingData: (state) => {
                  state.isLoading = true;
                  state.isError = false;
            },
            fetchDataSuccess: (state) => {
                  state.isLoading = false;
                  state.isError = false;
            },
            fetchDataFail: (state, action) => {
                  state.isLoading = false;
                  state.isError = true;
                  state.errorMsg = action.payload
            },
            //submitting data
            submittingData: (state) => {
                  state.action.isLoading = true;
                  state.action.isError = false;
            },
            submitDataSuccess: (state) => {
                  state.action.isLoading = false;
                  state.action.isError = false;
            },
            submitDataFail: (state, action) => {
                  state.action.isLoading = false;
                  state.action.isError = true;
                  state.action.errorMessage = action.payload;
            },
            // Dialog Action
            dialogActionStarted: (state) => {
                  state.dialogStates = { isLoading: true, isError: false, errorMessage: '' }
            },
            dialogActionSuccess: (state) => {
                  state.dialogStates = { isLoading: false, isError: false, errorMessage: '' }
            },
            dialogActionFail: (state, action) => {
                  state.dialogStates = { isLoading: false, isError: true, errorMessage: action.payload }
            },
            reloadDialogState: (state) => {
                  state.dialogStates = {isLoading: false, isError: false, errorMessage: '' }
            }
      }
});

export const {
      setData,
      setCurrentPage,
      setRowsPerPage,
      setTotalRows,
      setSearchTerm,
      setRegionIdFilter,
      setClusterIdFilter,
      // Fetch List
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,
      
      submittingData,
      submitDataFail,
      submitDataSuccess,
      // Dialog Action
      dialogActionStarted,
      dialogActionSuccess,
      dialogActionFail,
      reloadDialogState
} = townshipSlice.actions;

export default townshipSlice.reducer;