import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import {
      // setData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Create
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,
      setStartDate,
      setEndDate,
      // setContractFilter

} from "./_reducers";
import { useNoti } from "../../providers";
import { requestOneTimeToken } from "../../utils/helper";

const useFundReceivingActions = () => {
      const { showNoti } = useNoti();
      const dispatch = useDispatch();
      const data = useSelector(state => state.fundReceivingInvoice.data);
      const currentPage = useSelector(state => state.fundReceivingInvoice.currentPage);
      const rowsPerPage = useSelector(state => state.fundReceivingInvoice.rowsPerPage);
      const searchTerm = useSelector(state => state.fundReceivingInvoice.searchTerm);
      const start_date = useSelector(state => state.fundReceivingInvoice.filters.start_date);
      const end_date = useSelector(state => state.fundReceivingInvoice.filters.end_date);
      // const contractFilter = useSelector(state => state.fundReceivingInvoice.filters.funding_contract_id_number);

      function getFundReceivingInvoiceListByContractId(contractId) {
            dispatch(fetchingData());

            let url = `${API_URL.FundReceivingInvoice}/?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[funding_contract_id_number]=${contractId}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}`;

            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }


      function fetchFundReceivingInvoiceList() {
            dispatch(fetchingData());
            let get_fund_receiving_invoice_url = `${API_URL.FundReceivingInvoice}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}`;

            ApiRequest.get(get_fund_receiving_invoice_url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      

      function getFundReceivingInvoiceDetail(id) {
            return data.filter((d) => d._id == id)[0]
      }

      async function createFundReceivingInvoice(data) {
            dispatch(submittingData());
            try {
                  const ott = await requestOneTimeToken();
                  const url = API_URL.FundReceivingInvoice;
                  const res = await ApiRequest.post(url, { ...data, ott });
                  showNoti('Fund Receiving Invoice created!', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundReceivingInvoiceList();
                  return res.data.payload.data;
            }
            catch (err) {
                  dispatch(submitDataFail(err.message ?? 'Creating funding invoice failed!'));
                  // showNoti(err.message, 'error');
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  // !err.response && showNoti(err.message, 'error');
                  return Promise.reject(errors);
            }
      }

      async function editFundReceivingInvoice(data){
            dispatch(submittingData());
            try{
                  const url = `${API_URL.FundReceivingInvoice}/edit`;
                  const res = await ApiRequest.post(url, data);
                  showNoti('Fund Receiving Invoice edited!', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundReceivingInvoiceList();
                  return res.data.payload.data; 
            } catch(err){
                  dispatch(submitDataFail(err.message ?? 'Editing funding invoice failed!'));
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  return Promise.reject(errors);
            }
      }

      async function fetchFundReceivingInvoiceTransaction(fundReceivingInvoiceId) {
            try {
                  const url = `${API_URL.FundReceivingInvoice}/${fundReceivingInvoiceId}/transaction-history?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
                  const res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ total_items }));
                  return data;
            }
            catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  showNoti(errMsg, 'error');
            }
      }

      function changeSearchTerm(search_key) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(search_key));
      }
      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeStartDate(date) {
            dispatch(setStartDate(date));
      }
      function changeEndDate(date) {
            dispatch(setEndDate(date));
      }
      // function changeContractFilter(funding_contract_id_number) {
      //       dispatch(setCurrentPage(0));
      //       dispatch(setContractFilter(funding_contract_id_number));
      //   }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      return {
            // Actions
            getFundReceivingInvoiceListByContractId,
            fetchFundReceivingInvoiceList,
            fetchFundReceivingInvoiceTransaction,
            getFundReceivingInvoiceDetail,
            createFundReceivingInvoice,
            editFundReceivingInvoice,
            refreshDialogState,

            // Paganation
            // changeContractFilter,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
      }
}

export default useFundReceivingActions;