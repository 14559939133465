import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { Controller } from "react-hook-form";

/**
 * 
 * @param {String} name Name is must be same with schema name
 * @param {String} label for the form label
 * @returns 
 */

const Switcher = ({ name, control, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Switch
              checked={value || false}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default Switcher;
