import { useDispatch } from "react-redux";
import {
    setAdminData,
    setFinanceHOData,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    reCalculatingLedger,
    reCalculatingSuccess,
    reCalculatingFail,
    resetDialogStatus
} from "./_reducers";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import { useNoti } from "../../providers";

const useDashboardActions = () => {
    const dispatch = useDispatch();
    const { showNoti } = useNoti();

    async function fetchAdminDashboardData(selectedOfficeId=null) {
        const url = `${API_URL.Dashboard}/admin?office_id=${selectedOfficeId || ""}`;
        dispatch(fetchingData());

        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            dispatch(setAdminData(data));
            dispatch(fetchDataSuccess());
        }).catch(err => {
            console.error(err);
            const errMsg = err.response?.data.errors.message ?? err.message;
            showNoti(errMsg, 'error');
            dispatch(fetchDataFail(errMsg));
        })
    }

    async function fetchFinanceHODashboardData(){
        const url = `${API_URL.Dashboard}/finace_HO`;
        dispatch(fetchingData());

        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            dispatch(setFinanceHOData(data));
            dispatch(fetchDataSuccess());
        }).catch(err => {
            console.error(err);
            const errMsg = err.response?.data.errors.message ?? err.message;
            showNoti(errMsg, 'error');
            dispatch(fetchDataFail(errMsg));
        })
    }

    async function reCalculateLedger() {
        const url = `${API_URL.Dashboard}/refreshLedger`;
        dispatch(reCalculatingLedger());

        await ApiRequest.post(url).then(res => {
            dispatch(reCalculatingSuccess());
            return Promise.resolve(res);
        }).catch(err => {
            console.error(err);
            const errMsg = err.response?.data.errors.message ?? err.message;
            showNoti(errMsg, 'error');
            dispatch(reCalculatingFail(errMsg));
            return Promise.reject(errMsg);
        })
    }

    function resetDialog() {
        dispatch(resetDialogStatus());
    }
    return {
        fetchAdminDashboardData,
        fetchFinanceHODashboardData,
        reCalculateLedger,
        resetDialog
    }
}

export default useDashboardActions;