import { createSlice } from "@reduxjs/toolkit";

const regionSlice = createSlice({
      name: 'region',
      initialState: {
            data: [],
            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,
            searchTerm: '',

            listViewStates: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },

            dialogStates: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setTotalRows: (state, action) => {
                  state.totalRows = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            // Fetching List View
            fetchingData: (state) => {
                  state.listViewStates = { isLoading: true, isError: false, errorMessage: '' };
            },
            fetchDataSuccess: (state, action) => {
                  action.payload.data && (state.data = action.payload.data);
                  action.payload.total_items && (state.totalRows = action.payload.total_items);
                  state.listViewStates = { isLoading: false, isError: false, errorMessage: '' };
            },
            fetchDataFail: (state, action) => {
                  state.listViewStates = { isLoading: false, isError: true, errorMessage: action.payload };
            },

            //submitting data
            submittingData: (state) => {
                  state.action.isLoading = true;
                  state.action.isError = false;
            },
            submitDataSuccess: (state) => {
                  state.action.isLoading = false;
                  state.action.isError = false;
            },
            submitDataFail: (state, action) => {
                  state.action.isLoading = false;
                  state.action.isError = true;
                  state.action.errorMessage = action.payload;
            },

            // Dialog Action
            dialogActionStarted: (state) => {
                  state.dialogStates = { isLoading: true, isError: false, errorMessage: '' }
            },
            dialogActionSuccess: (state) => {
                  state.dialogStates = { isLoading: false, isError: false, errorMessage: '' }
            },
            dialogActionFail: (state, action) => {
                  state.dialogStates = { isLoading: false, isError: true, errorMessage: action.payload }
            },
            reloadDialogState: (state) => {
                  state.dialogStates = {isLoading: false, isError: false, errorMessage: ''}
            }
      }
});

export const {
      setData,
      setCurrentPage,
      setRowsPerPage,
      setTotalRows,
      setSearchTerm,

      submittingData,
      submitDataFail,
      submitDataSuccess,
      // Fetch List View
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,
      // Dialog Actions
      dialogActionStarted,
      dialogActionSuccess,
      dialogActionFail,
      reloadDialogState,
} = regionSlice.actions;

export default regionSlice.reducer;