import { useSelector } from "react-redux";
import useCommitteeActions from "./_actions";

export const useMember = () => {
    const {
        fetchMemberList,
        fetchMemberListByCommitteeId,
        getRelatedCommittees,
        addNewMember,
        editMember,
        getMemberTransaction,
        createMultipleMembers,
        getMemberDetail,
        activateMember,
        deactivateMember,
        verifyMember,
        resetMemberPin,
        getFarmTypes,
        updateMember,
        downloadMemberFileUploadTemplate,
        exportMemberData,

        changeMemberPhoneNumber,

        changeCurrentPage, 
        changeRowsPerPage,

        changeSearchTerm,
        changeStatusFilter,
        changeSearchTermForCommittee,
        changeStatusFilterForCommittee,
        changeSMSStatusFilterForCommittee,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        changeVillageCodeFilter,
        changePositionFilter,
        changeCreatedDateFilter,
        refreshDialogState,
        changeMemberTypeFilter
    } = useCommitteeActions();

    const members = useSelector(state => state.member.data);

    const currentPage = useSelector(state => state.member.currentPage);
    const totalRows = useSelector(state => state.member.totalRows);
    const rowsPerPage = useSelector(state => state.member.rowsPerPage);

    const isLoading = useSelector(state => state.member.isLoading);
    const isError = useSelector(state => state.member.isError);
    const errorMessage = useSelector(state =>state.member.errorMessage);

    const searchTerm = useSelector(state => state.member.searchTerm);
    const statusFilter = useSelector(state => state.member.filters.status);
    const searchTermForCommittee = useSelector(state => state.member.searchTermForCommittee);
    const statusFilterForCommittee = useSelector(state => state.member.filters.statusForCommittee); 
    const smsStatusFilterForCommittee = useSelector(state => state.member.filters.smsStatusForCommittee); 
    const regionCodeFilter = useSelector(state => state.member.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.member.filters.township_code);
    const villageTractCodeFilter = useSelector(state => state.member.filters.village_tract_code);
    const villageCodeFilter = useSelector(state => state.member.filters.village_code);
    const positionFilter = useSelector(state => state.member.filters.position_id);
    const memberTypeFilter = useSelector(state => state.member.filters.member_type);
    const createdDateFilter = useSelector(state => state.member.filters.created_date);
    const apiAction = useSelector(state => state.member.action);
    const dialogStates = useSelector(state => state.member.action);

    return {
        //state
        members,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        searchTermForCommittee,
        statusFilter,
        statusFilterForCommittee,
        smsStatusFilterForCommittee,
        regionCodeFilter,
        townshipCodeFilter,
        villageTractCodeFilter,
        villageCodeFilter,
        positionFilter,
        memberTypeFilter,
        createdDateFilter,
        apiAction,
        dialogStates,
        updateMember,

        //actions
        fetchMemberList,
        fetchMemberListByCommitteeId,
        getMemberTransaction,
        getRelatedCommittees,
        addNewMember,
        editMember,
        createMultipleMembers,
        getMemberDetail,
        activateMember,
        deactivateMember,
        verifyMember,
        resetMemberPin,
        getFarmTypes,
        changeMemberPhoneNumber,
        
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeStatusFilter,
        changeSearchTermForCommittee,
        changeStatusFilterForCommittee,
        changeSMSStatusFilterForCommittee,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        changeVillageCodeFilter,
        changePositionFilter,
        changeCreatedDateFilter,
        refreshDialogState,
        downloadMemberFileUploadTemplate,
        exportMemberData,
        changeMemberTypeFilter
    }
}

export {default as memberReducer} from './_reducers';