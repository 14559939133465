import { useDispatch } from "react-redux";
import {
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
} from './_reducers';
import { API_URL } from "../../../configs/_api";
import ApiRequest from "../../../utils/apiRequest";



const useRelatedRevenueActions = () => {


    const dispatch = useDispatch();


    async function getRelatedRevenue(id) {
        dispatch(fetchingData());
        const getRelatedUrl = `${API_URL.RevenueTransfers}/related_revenue/${id}`;
        ApiRequest.get(getRelatedUrl).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;

            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

    return {
        getRelatedRevenue,
    }
}

export default useRelatedRevenueActions;