import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import {
      // setData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Create
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,
      setStartDate,
      setEndDate,
      setBranchFilter,

} from "./_reducers";
import { useNoti } from "../../providers";
import { requestOneTimeToken } from "../../utils/helper";

const useFundAllocationActions = () => {
      const { showNoti } = useNoti();
      const dispatch = useDispatch();
      const data = useSelector(state => state.fundAllocation.data);
      const currentPage = useSelector(state => state.fundAllocation.currentPage);
      const rowsPerPage = useSelector(state => state.fundAllocation.rowsPerPage);
      const searchTerm = useSelector(state => state.fundAllocation.searchTerm);
      const start_date = useSelector(state => state.fundAllocation.filters.start_date);
      const end_date = useSelector(state => state.fundAllocation.filters.end_date);
      const branchFilter = useSelector(state => state.fundAllocation.filters.branch_id);


      function getFundAllocationListByContractId(contractId) {
            dispatch(fetchingData());

            let url = `${API_URL.FundAllocation}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[funding_contract_id_number]=${contractId}&filters[branch_id]=${branchFilter}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}`;

            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      function fetchFundAllocationList() {
            dispatch(fetchingData());

            let get_fund_allocation_url = `${API_URL.FundAllocation}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[branch_id]=${branchFilter}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}`;

            ApiRequest.get(get_fund_allocation_url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      function getFundAllocationDetail(id) {
            return data.filter((d) => d._id == id)[0]
      }

      async function createFundAllocation(data) {

            dispatch(submittingData());
            try {
                  const ott = await requestOneTimeToken();
                  const url = API_URL.FundAllocation;
                  const res = await ApiRequest.post(url, { ...data, ott });
                  showNoti('Fund Allocation created!', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundAllocationList();
                  return res.data.payload.data;
            }
            catch (err) {
                  dispatch(submitDataFail(err.message ?? 'Creating Fund Allocation Failed'));
                  showNoti(err.message, 'error');
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  // !err.response && showNoti(err.message, 'error');
                  return Promise.reject(errors);
            }
      }

      async function fetchFundAllocationTransaction(fundAllocId) {
            try {
                  const url = `${API_URL.FundAllocation}/${fundAllocId}/transaction-history?page_no=${currentPage + 1}&page_size=${rowsPerPage}`;
                  const res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ total_items }));
                  return data;
            }
            catch (err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  showNoti(errMsg, 'error');
            }
      }

      async function getAllocationByOfficeId(office_id) {
            try {
                  const url = `${API_URL.FundAllocation}/getAllocationByOfficeId/${office_id}`;
                  const res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  return data;
            } catch (err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  return Promise.reject(errMsg);
            }
      }


      function changeSearchTerm(search_key) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(search_key));
      }
      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeStartDate(date) {
            dispatch(setStartDate(date));
      }
      function changeEndDate(date) {
            dispatch(setEndDate(date));
      }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      function changeBranchFilter(branch_id) {
            dispatch(setCurrentPage(0));
            dispatch(setBranchFilter(branch_id));
      }

      return {
            // Actions
            getFundAllocationListByContractId,
            fetchFundAllocationList,
            fetchFundAllocationTransaction,
            getFundAllocationDetail,
            createFundAllocation,
            refreshDialogState,
            getAllocationByOfficeId,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeBranchFilter
      }
}

export default useFundAllocationActions;