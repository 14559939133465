import { useSelector } from 'react-redux';
import useRevenueActions from './_actions';

export const useRevenue = () => {
    const {
        fetchRevenueList,
    } = useRevenueActions();

    const revenue = useSelector(state => state.revenue.data);

    return {
        fetchRevenueList,
        revenue,
    }
}

export { default as revenueReducer } from './_reducers';