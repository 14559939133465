import React from 'react';
import { Box, Typography, } from '@mui/material'
import { Close, CloudUploadOutlined } from '@mui/icons-material';
// Local
import StyledIconButton from '../../Buttons/StyledIconButton';
import ImageResizerDialog from './ImageResizerDialog';
import { useNoti } from '../../../providers';
import imageCompression from 'browser-image-compression';

 
/**
 * # Resizable Image Uploader
 * @param {Number} acpect to define the ration of image {e.g. 2 /1 | 1 /1} 
 * @param {Number} height to define the height of preview image
 * @param {String} url to provide url of image to display
 * @param {Function} onChange to listen the changes| it will return file or null value
 * @example <ResizableImageUploader acpect={2 / 1} height={200} url={'https://s.studiobinder.com/wp-content/uploads/2020/01/What-is-2-1-Aspect-Ratio-Univisium-2-1-Aspect-Ratio-Movies-FB.jpg'} onChange={(f)=> console.log(f)} />
 * @returns 
 */
const ResizableImageUploader = (props) => {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [croppedImage, setCroppedImage] = React.useState(null);
    const inputRef = React.useRef();
    const {showNoti} = useNoti();

    React.useEffect(() => {
        if (props.url) {
            setCroppedImage({url: props.url, ...croppedImage})
        }
    }, [props.url !== null, props.url])

    function handleOpenClose(val) {
        setOpen(val);

        if (!val) {
            setFile(null);
            inputRef.current = null
        }
    }
    function handleOnClick() {
        // pick file
        inputRef.current?.click();
    }

  async  function onFilePickHandler(e) {
        const file = e.target.files[0];
        const maxSizeBytes = 5 * 1024 * 1024;

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        }

        if(file?.size > maxSizeBytes) {
            showNoti('File size exceeds maximum size (5MB)', 'error')
        }else{
        if(file){
            const compressImage = await imageCompression(file, options);

            if (compressImage?.size < maxSizeBytes) {
                setFile(compressImage)
                handleOpenClose(true)
            }else{
                showNoti('File size exceeds maximum size (5MB)', 'error');
            }
        }
        }
    }

    function handleOnCropped(v) {
        if(v) {
            setCroppedImage(v)
            props.onChange(v.file)
        }
    }

    function handleDeleteBannerPreview() {
        setFile(null);
        setCroppedImage(null);
        props.handleDelete()
    }

    function handleOnDrag() {
        console.log('dragged!')
    }
    function handleOnDrop() {
        console.log('dropped!')
    }

    return (
        <>
            <ImageResizerDialog
                isOpen={open}
                file={file}
                aspect={props.acpect}
                handleClose={() => handleOpenClose(false)}
                croppedImage={handleOnCropped} />


            <Box sx={{ borderRadius: 2 }} >
                {
                    croppedImage?.url
                        ? <Box width={(props.height * props.acpect) - 5} height={props.height - 5} sx={{ position: 'relative', borderRadius: 2, }}>
                            <img
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                }}
                                loading="lazy"
                                alt="Banner Image"
                                onClick={handleOnClick}
                                src={croppedImage?.url} />

                            {DeleteButton(handleDeleteBannerPreview)}
                        </Box>

                        // Empty Box
                        : EmptyBox(props.height, props.acpect, handleOnClick, handleOnDrag, handleOnDrop)

                }
                <input
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    style={{ display: 'none' }}
                    onChange={onFilePickHandler}
                    ref={(instance) => inputRef.current = instance}
                />
            </Box>
        </>
    )
}
 
function EmptyBox(height, acpect, handleOnClick, onDragOverHandler, onDropHandler) {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        px: 1,
        backgroundColor: '#F2F2F2',
        borderStyle: 'dotted',
        borderColor: '#CFCFCF',
        borderRadius: 2,
        width: height * acpect,
        height: height,
        cursor: 'pointer'
    }}
    onDragOver={onDragOverHandler} 
    onDrop={onDropHandler}
    onClick={handleOnClick}>
        <CloudUploadOutlined sx={{ fontSize: "4rem", color: '#B7B7B7' }} />
        <Box>
            <Typography variant={'subtitle1'} fontWeight={400} sx={{ mb: 0, wordWrap: "break-word" }}>
                Drop image or click here to upload
            </Typography>
        </Box>
    </Box>;
}

function DeleteButton(handleDeleteBannerPreview) {
    return <Box
        sx={{
            bgcolor: "lightgray",
            borderRadius: "50%",
            "&:hover": {
                opacity: 0.5,
            },
            width: "25px",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 4,
            right: 4,
        }}
    >
        <StyledIconButton
            onClick={() => handleDeleteBannerPreview()}
        >
            <Close sx={{ fontSize: "14px" }} />
        </StyledIconButton>
    </Box>;
}

export {ResizableImageUploader}; 