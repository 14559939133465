import { useSelector } from "react-redux";
import useRegionActions from './_actions';

export const useRegion = () => {
      const {
            fetchRegionList,
            addRegion,
            editRegion,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState,
            downloadRegionFileUploadTemplate,
            getRegionList,
            createMultipleRegions
      } = useRegionActions();

      const regions = useSelector(state => state.region.data);
      const currentPage = useSelector(state => state.region.currentPage);
      const totalRows = useSelector(state => state.region.totalRows);
      const rowsPerPage = useSelector(state => state.region.rowsPerPage);
      const searchTerm = useSelector(state => state.region.searchTerm);

      const isLoading = useSelector(state => state.region.isLoading);
      const isError = useSelector(state => state.region.isError);
      const errorMessage = useSelector(state => state.region.errorMessage);

      const dialogStates = useSelector(state => state.region.dialogStates);

      return {
            //state
            regions,
            isLoading,
            isError,
            errorMessage,
            currentPage,
            rowsPerPage,
            totalRows,
            searchTerm,
            dialogStates,
            //actions
            fetchRegionList,
            getRegionList,
            addRegion,
            editRegion,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState,
            downloadRegionFileUploadTemplate,
            createMultipleRegions
      }
}

export { default as regionReducer } from './_reducers';