import { useSelector } from "react-redux";
import useFundReceivingActions from "./_actions";

export const useFundReceiving = () => {
      const {
            // Actions
            getFundAllocationListByContractId,
            fetchFundReceivingList,
            getFundReceivingDetail,
            createFundReceiving,
            refreshDialogState,
            updateFundReceiving,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,

            changeAllocationSearchTerm,
            changeAllocationCurrentPage,
            changeAllocationRowsPerPage,
            changeAllocationStartDate,
            changeAllocationEndDate,
            changeBranchFilter,

      } = useFundReceivingActions();

      const fundReceiving = useSelector(state => state.fundReceiving.data);
      const currentPage = useSelector(state => state.fundReceiving.currentPage);
      const totalRows = useSelector(state => state.fundReceiving.totalRows);
      const rowsPerPage = useSelector(state => state.fundReceiving.rowsPerPage);
      const isLoading = useSelector(state => state.fundReceiving.isLoading);
      const isError = useSelector(state => state.fundReceiving.isError);
      const errorMessage = useSelector(state => state.fundReceiving.errorMessage);
      const searchTerm = useSelector(state => state.fundReceiving.searchTerm);
      const start_date = useSelector(state => state.fundReceiving.filters.start_date);
      const end_date = useSelector(state => state.fundReceiving.filters.end_date);
      const apiAction = useSelector(state => state.fundReceiving.action);


      const allocation = useSelector(state => state.fundReceiving.allocation.data);
      const allocationCurrentPage = useSelector(state => state.fundReceiving.allocation.currentPage);
      const allocationTotalRows = useSelector(state => state.fundReceiving.allocation.totalRows);
      const allocationRowsPerPage = useSelector(state => state.fundReceiving.allocation.rowsPerPage);
      const allocationSearchTerm = useSelector(state => state.fundReceiving.allocation.searchTerm);
      const allocationStart_date = useSelector(state => state.fundReceiving.allocation.filters.start_date);
      const allocationEnd_date = useSelector(state => state.fundReceiving.allocation.filters.end_date);
      const branchFilter = useSelector(state => state.fundReceiving.allocation.filters.branch_id);
      
      const dialogStates = useSelector(state => state.deposit.dialogStates);

      return {
            //state
            allocation,
            fundReceiving,

            currentPage,
            totalRows,
            rowsPerPage,

            allocationCurrentPage,
            allocationTotalRows,
            allocationRowsPerPage,
            allocationSearchTerm,
            allocationStart_date,
            allocationEnd_date,

            isLoading,
            isError,
            errorMessage,
            searchTerm,

            start_date,
            end_date,
            apiAction,
            dialogStates,
            branchFilter,

            //actions
            getFundAllocationListByContractId,
            updateFundReceiving,
            fetchFundReceivingList,
            getFundReceivingDetail,
            createFundReceiving,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            refreshDialogState,

            changeAllocationSearchTerm,
            changeAllocationCurrentPage,
            changeAllocationRowsPerPage,
            changeAllocationStartDate,
            changeAllocationEndDate,
            changeBranchFilter,


      }
}

export { default as fundReceivingReducers } from './_reducers';