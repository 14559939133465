import { useDispatch } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import { API_URL } from '../../configs/_api';

import {
      setData,
      setCurrentPage,
      setRowsPerPage,
      setSearchTerm,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail
} from "./_reducers";

const useRegionActions = () => {
      const dispatch = useDispatch();

      async function fetchStandardSchema() {
            dispatch(fetchingData());
            let get_standardSchema_url = `${API_URL.StandardSchema}`;
            ApiRequest.get(get_standardSchema_url).then(res => {
                  const { data } = res.data.payload;
                  dispatch(setData(data));
                  dispatch(fetchDataSuccess());
            }).catch(err => {
                  console.log(err);
                  dispatch(fetchDataFail(err));
            });
      }

      function getSchema(){
            dispatch(fetchingData());

            let get_schema_url = `${API_URL.StandardSchema}`;
            ApiRequest.get(get_schema_url).then(res => {
                  const { data } = res.data;
                  dispatch(setData(data));
                  dispatch(fetchDataSuccess());
            }).catch(err => {
                  dispatch(fetchDataFail(err));
            });
      }

      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeSearchTerm(searchTerm) {
            dispatch(setSearchTerm(searchTerm));
      }

      return {
            fetchStandardSchema,
            getSchema,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm
      }
}

export default useRegionActions;