import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import { useNoti } from "../../providers";
import ApiRequest from "../../utils/apiRequest";
import {
      setCurrentPage,
      setRowsPerPage,
      setSearchTerm,
      setStatusFilter,
      setStartDateFilter,
      setEndDateFilter,
      setDueDateFilter,
      setStatusFilterForMember,
      setStartDateForMember,
      setEndDateForMember,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,
      submittingData,
      submitDataFail,
      submitDataSuccess,
      reloadDialogState
} from "./_reducers";

const useLoanActions = () => {
      const dispatch = useDispatch();
      const { showNoti } = useNoti();

      const currentPage = useSelector(state => state.loan.currentPage);
      const rowsPerPage = useSelector(state => state.loan.rowsPerPage);
      const searchTerm = useSelector(state => state.loan.searchTerm);
      const statusFilter = useSelector(state => state.loan.filters.status);
      const startDateFilter = useSelector(state => state.loan.filters.startDate);
      const endDateFilter = useSelector(state => state.loan.filters.endDate);
      const statusForMember = useSelector(state => state.loan.filters.statusForMember);
      const startDateForMember = useSelector(state => state.loan.filters.startDateForMember);
      const endDateForMember = useSelector(state => state.loan.filters.endDateForMember);
      const dueDateFilter = useSelector(state => state.loan.filters.dueDate);

      function fetchLoanList() {
            dispatch(fetchingData());
            const url = `${API_URL.Loan}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${statusFilter || ''}&filters[start_date]=${startDateFilter || ''}&filters[end_date]=${endDateFilter || ''}&filters[dueDate]=${dueDateFilter || 'all'}`;
            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.message));
            });
      }

      async function getLoanDetail(loanId, apiOptions = {}) {
            dispatch(fetchingData());
            const url = `${API_URL.Loan}/${loanId}`;
            try {
                  const res = await ApiRequest.get(url, { ...apiOptions });
                  dispatch(fetchDataSuccess());
                  return res.data.payload.data;
            }
            catch (err) {
                  console.error(err);
                  const errMsg = `Failed to get loan contract detail! ${err.message}`;
                  dispatch(fetchDataFail(errMsg));
                  return Promise.reject();
            }
      }

      async function getLoanCloseInfo(loanId, apiOptions = {}) {
            const url = `${API_URL.Loan}/${loanId}/close/pre-info`;
            try {
                  const res = await ApiRequest.get(url, { ...apiOptions });
                  return res.data.payload.data;
            }
            catch (err) {
                  console.error(err);
                  const errMsg = `Failed to get loan contract close detail! ${err.message}`;
                  showNoti(errMsg, 'error');
                  return Promise.reject();
            }
      }

      async function closeLoanContract(loanId, otpId, otpCode) {
            dispatch(submittingData());
            const url = `${API_URL.Loan}/${loanId}/close`;
            try {
                  await ApiRequest.post(url, { otp_id: otpId, otp_code: otpCode });
                  dispatch(submitDataSuccess());
                  showNoti('Loan contract closed successfully', 'success');
                  return Promise.resolve();
            }
            catch (err) {
                  console.error(err);
                  const errMsg = err.response?.data.errors.message ?? err.message;
                  showNoti(errMsg, 'error');
                  dispatch(submitDataFail());
                  return Promise.reject();
            }
      }

      function fetchLoanListByCommitteeId(committeeId) {
            dispatch(fetchingData());
            const url = `${API_URL.Loan}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${statusFilter || ''}&filters[start_date]=${startDateFilter || ''}&filters[end_date]=${endDateFilter || ''}&filters[committee_id]=${committeeId || ''}`;
            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.message));
            });
      }

      function fetchLoanHistoryListOfMember(committee_id, member_id) {
            dispatch(fetchingData());
            const url = `${API_URL.Loan}/member-loan-history/?committee_id=${committee_id}&member_id=${member_id}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${statusForMember || ''}&filters[start_date]=${startDateForMember || ''}&filters[end_date]=${endDateForMember || ''}`;
            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.message));
            });
      }

      async function fetchDataForLoanCreate(committee_id) {
            const url = `${API_URL.Committee}/${committee_id}/get_loan_create_data`;
            try {
                  const res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  return data;
            } catch (err) {
                  const errMsg = err.response?.data.errors?.message ?? err.message;
                  return Promise.reject(errMsg);
            }
      }

      async function sendLoanContractCloseOTP(loanContractId) {
            dispatch(submittingData());
            const url = `${API_URL.Loan}/${loanContractId}/close/otp`;
            try {
                  const res = await ApiRequest.post(url);
                  dispatch(submitDataSuccess());
                  return res.data.payload.data.otp_id;
            }
            catch (err) {
                  console.error(err);
                  const errMsg = err.response?.data.errors?.message ?? err.message;
                  dispatch(submitDataFail());
                  showNoti(errMsg, 'error');
                  return Promise.reject();
            }
      }

      async function checkOTP(otpId, otpCode) {
            const url = `${API_URL.Otp}/${otpId}/check`;
            try {
                  await ApiRequest.post(url, { otp_code: otpCode });
                  return Promise.resolve();
            }
            catch (err) {
                  const errMsg = err.response?.data.errors?.message ?? err.message;
                  return Promise.reject(errMsg);
            }
      }

      async function getLoanTransactionList(id) {
            const url = `${API_URL.Loan}/${id}/loan_transactions?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
            try {
                  let res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
                  return data;
            } catch (err) {
                  console.error(err);
            }
      }

      async function sendOTP(committee_id) {
            const url = `${API_URL.Otp}/to-committee-chairman`;
            try {
                  const res = await ApiRequest.post(
                        url,
                        { committee_id },
                        { headers: { 'Content-Type': 'application/json' } }
                  );
                  const { otp_id } = res.data.payload.data;
                  return otp_id;
            } catch (err) {
                  const errMsg = err.response?.data.errors?.message ?? err.message;
                  showNoti(`Error. ${errMsg}`, 'error');
                  return Promise.reject(errMsg);
            }
      }

      async function createNewLoan(loan) {
            dispatch(submittingData());
            const url = `${API_URL.Loan}/create`;
            try {
                  let res = await ApiRequest.post(
                        url, loan,
                        { headers: { 'Content-Type': 'application/json' } }
                  );
                  showNoti('New loan contract is saved .', 'success');
                  dispatch(submitDataSuccess());
                  return res.data.payload.data;
            } catch (err) {
                  console.error(err);
                  const errMsg = err.response?.data.errors?.message ?? err.message;
                  dispatch(submitDataFail(`Create new loan contract fail! ${errMsg}`));
                  showNoti(`Error to create new loan contract. ${errMsg}`, 'error');
                  return Promise.reject(errMsg);
            }
      }

      async function transferPointToAllMember(data) {
            let url = `${API_URL.Loan}/transfer/all-member`;
            try {
                  await ApiRequest.post(url, data);
                  showNoti("အောင်မြင်ပါသည်။ Member တစ်ဦးချင်းစီအား လွှဲပေးနေပါပြီ။", "success");
                  return Promise.resolve();
            }
            catch (err) {
                  const errMsg = err.response?.data.errors?.message ?? err.message;
                  return Promise.reject(errMsg);
            }
      }

      function changeSearchTerm(searchTerm) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(searchTerm));
      }

      function changeStatusFilter(status) {
            dispatch(setStatusFilter(status));
      }

      function changeStartDateFilter(date) {
            dispatch(setStartDateFilter(date));
      }

      function changeDueDateFilter(due) {
            dispatch(setDueDateFilter(due));
      }

      function changeEndDateFilter(date) {
            dispatch(setEndDateFilter(date));
      }

      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeStatusForMember(status) {
            dispatch(setStatusFilterForMember(status))
      }
      function changeStartDateForMember(date) {
            dispatch(setStartDateForMember(date))
      }
      function changeEndDateForMember(date) {
            dispatch(setEndDateForMember(date))
      }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      return {
            fetchLoanList,
            getLoanTransactionList,
            getLoanDetail,
            closeLoanContract,
            getLoanCloseInfo,
            sendLoanContractCloseOTP,
            changeDueDateFilter,
            checkOTP,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStatusFilter,
            changeStartDateFilter,
            changeEndDateFilter,
            fetchLoanListByCommitteeId,
            fetchLoanHistoryListOfMember,
            changeStatusForMember,
            changeStartDateForMember,
            changeEndDateForMember,
            fetchDataForLoanCreate,
            sendOTP,
            createNewLoan,
            transferPointToAllMember,
            refreshDialogState
      }
}

export default useLoanActions;