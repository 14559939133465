import { createSlice } from "@reduxjs/toolkit";

const loanSlice = createSlice({
    name: 'loan',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        searchTerm: '',
        filters: {
            status: '',
            startDate: null,
            endDate: null,
            statusForMember: '',
            startDateForMember: '',
            endDateForMember: '',
            dueDate: ''
        },

        isLoading: false,
        isError: false,
        errorMessage: '',
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.filters.status = action.payload;
        },
        setStartDateFilter: (state, action) => {
            state.filters.startDate = action.payload;
        },
        setEndDateFilter: (state, action) => {
            state.filters.endDate = action.payload;
        },
        setDueDateFilter: (state, action) => {
            state.filters.dueDate = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess: (state, action) => {
            action.payload?.data && (state.data = action.payload.data);
            action.payload?.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        setStatusFilterForMember: (state, action) => {
            state.filters.statusForMember = action.payload;
        },
        setStartDateForMember: (state, action) => {
            state.filters.startDateForMember = action.payload;
        },
        setEndDateForMember: (state, action) => {
            state.filters.endDateForMember = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' };
        }
    }
});

export const {
    setData,
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    setSearchTerm,
    setStatusFilter,
    setStartDateFilter,
    setEndDateFilter,
    setDueDateFilter,
    setStatusFilterForMember,
    setStartDateForMember,
    setEndDateForMember,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState
} = loanSlice.actions;

export default loanSlice.reducer;