import { useSelector } from 'react-redux';
import useLoanActions from './_actions';

export const useLoan = () => {
      const {
            fetchLoanList,
            getLoanTransactionList,
            getLoanDetail,
            closeLoanContract,
            getLoanCloseInfo,
            checkOTP,
            sendLoanContractCloseOTP,
            fetchLoanListByCommitteeId,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStatusFilter,
            changeStartDateFilter,
            changeEndDateFilter,
            changeDueDateFilter,
            fetchLoanHistoryListOfMember,
            changeStatusForMember,
            changeStartDateForMember,
            changeEndDateForMember,
            fetchDataForLoanCreate,
            sendOTP,
            createNewLoan,
            transferPointToAllMember,
            refreshDialogState
      } = useLoanActions();

      const loans = useSelector(state => state.loan.data);
      const currentPage = useSelector(state => state.loan.currentPage);
      const totalRows = useSelector(state => state.loan.totalRows);
      const rowsPerPage = useSelector(state => state.loan.rowsPerPage);
      const isLoading = useSelector(state => state.loan.isLoading);
      const isError = useSelector(state => state.loan.isError);
      const errorMessage = useSelector(state => state.loan.errorMessage);
      const searchTerm = useSelector(state => state.loan.searchTerm);
      const statusFilter = useSelector(state => state.loan.filters.status);
      const startDateFilter = useSelector(state => state.loan.filters.startDate);
      const endDateFilter = useSelector(state => state.loan.filters.endDate);
      const apiAction = useSelector(state => state.loan.action);
      const statusForMember = useSelector(state => state.loan.filters.statusForMember);
      const startDateForMember = useSelector(state => state.loan.filters.startDateForMember);
      const endDateForMember = useSelector(state => state.loan.filters.endDateForMember);
      const dueDateFilter = useSelector(state => state.loan.filters.dueDate);

      return {
            loans,
            currentPage,
            totalRows,
            rowsPerPage,
            isError,
            isLoading,
            errorMessage,
            searchTerm,
            statusFilter,
            startDateFilter,
            endDateFilter,
            apiAction,
            statusForMember,
            startDateForMember,
            endDateForMember,
            dueDateFilter,

            fetchLoanList,
            getLoanTransactionList,
            getLoanDetail,
            closeLoanContract,
            getLoanCloseInfo,
            checkOTP,
            sendLoanContractCloseOTP,
            fetchLoanListByCommitteeId,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            changeStatusFilter,
            changeStartDateFilter,
            changeEndDateFilter,
            changeDueDateFilter,
            fetchLoanHistoryListOfMember,
            changeStatusForMember,
            changeStartDateForMember,
            changeEndDateForMember,
            fetchDataForLoanCreate,
            sendOTP,
            createNewLoan,
            transferPointToAllMember,
            refreshDialogState
      }
}

export { default as loanReducer } from './_reducers';

