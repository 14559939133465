import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    resetData,
    setCurrentPage,
    setSearchTerm,
    setRowsPerPage,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setStartDate,
    setEndDate,
    setOfficeFilter,
    setCommitteeFilter,
    setStatusFilter,
    setShopFilter,
    reloadDialogState,
    submittingData,
    submitDataFail,
    submitDataSuccess,
} from './_reducers';
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import * as XLSX from 'xlsx';
import { invoiceListColumns } from "../../configs/_dataColumnConfig"

import { useProductCatalog } from "../productCatalog";
import useDataExportActions from "../data_exports/_actions";
import dayjs from "dayjs";
import { useNoti } from "../../providers";
import { writeOutExcelFile } from "../../utils/xlsxWorkBook";

const useInvoiceActions = () => {

    const { moduleParam, getFilteredData } = useDataExportActions()
    const { showNoti } = useNoti()

    const dispatch = useDispatch();
    const { productCatalog, getAllProductCatalog} = useProductCatalog();

    const searchTerm = useSelector(state => state.invoice.searchTerm);
    const officeFilter = useSelector(state => state.invoice.filters.office_id);
    const shopFilter = useSelector(state => state.invoice.filters.shopid);
    const committeeFilter = useSelector(state => state.invoice.filters.committee_id);
    const statusFilter = useSelector(state => state.invoice.filters.status);
    const startDate = useSelector(state => state.invoice.filters.startDate);
    const endDate = useSelector(state => state.invoice.filters.endDate);
    const currentPage = useSelector(state => state.invoice.currentPage);
    const rowsPerPage = useSelector(state => state.invoice.rowsPerPage);

    useEffect(() => {
        getAllProductCatalog()
    }, []);


    async function fetchInvoiceListBySettlementId(settlementId) {
        dispatch(resetData());
        const url = `${API_URL.Invoice}/?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[settlement_id]=${settlementId}&filters[office_id]=${officeFilter}&filters[shopid]=${shopFilter}&filters[start_date]=${startDate || ''}&filters[end_date]=${endDate || ''}&filters[committee_id]=${committeeFilter}&filters[status]=${statusFilter}`;
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    async function fetchInvoiceListByMemberId (memberId) {
        dispatch(resetData());
        const url = `${API_URL.Invoice}/?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[member_id]=${memberId}`
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    async function fetchInvoiceList() {
        dispatch(resetData());
        const url = `${API_URL.Invoice}/?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[office_id]=${officeFilter}&filters[shopid]=${shopFilter}&filters[start_date]=${startDate || ''}&filters[end_date]=${endDate || ''}&filters[committee_id]=${committeeFilter}&filters[status]=${statusFilter}`;
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    async function getInvoiceDetails(id, apiOptions={}) {
        dispatch(fetchingData());
        const url = `${API_URL.Invoice}/${id}`;
        try {
            const res = await ApiRequest.get(url, {...apiOptions});
            dispatch(fetchDataSuccess());
            return res.data.payload.data;
      }
      catch(err) {
            console.error(err);
            const errMsg = `Failed to get invoice detail! ${err.message}`;
            dispatch(fetchDataFail(errMsg));
            return Promise.reject();
      }
    }

    async function deleteInvoice(id, apiOptions={}) {
        dispatch(fetchingData());
        const url = `${API_URL.Invoice}/${id}`;
        try {
            const res = await ApiRequest.delete(url, {...apiOptions});
            dispatch(fetchDataSuccess());
            return res.data.payload.data;
      }
      catch(err) {
            console.error(err);
            const errMsg = `Failed to delete invoice! ${err.message}`;
            dispatch(fetchDataFail(errMsg));
            return Promise.reject();
      }
    }

    function downloadOrderFileUploadTemplate(columns) {
        const workBook = XLSX.utils.book_new();

        const dataSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
        XLSX.utils.book_append_sheet(workBook, dataSheet, 'data');

        const productListMetaSheet = getProductListSheet();
        XLSX.utils.book_append_sheet(workBook, productListMetaSheet, 'product list');

        XLSX.writeFile(workBook, 'invoiceUploadTemplate.xlsx');

        function getProductListSheet() {
            const productListSheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_aoa(productListSheet, [['productName', 'product_code']]);
            const filteredProductList = productCatalog.map(p => ({ name: p.product_name, product_code: p.product_code }));
            XLSX.utils.sheet_add_json(productListSheet, filteredProductList, { origin: 'A2', skipHeader: true });

            return productListSheet;
        }
    }

    async function createMultipleInvoices(file, committeeId, settlementId, shopId) {
        const url = `${API_URL.Invoice}/batch`;
        const data = new FormData();
        data.append('file', file);
        data.append('committee_id', committeeId);
        data.append('settlement_id', settlementId);
        data.append('shop_id', shopId);

        try {
            dispatch(submittingData());
            const res = await ApiRequest.post(url, data);
            dispatch(submitDataSuccess());
            return res.data;
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            dispatch(submitDataFail());
            return Promise.reject(errMsg);
        }
    }

    async function createInvoices(data) {
        const url = API_URL.Invoice;
        try {
            dispatch(submittingData());
            const res = await ApiRequest.post(url, data);
            dispatch(submitDataSuccess());
            return res.data;
        }
        catch(err) {
            const errorMessage = err.response?.data.errors.message || err.message;
            dispatch(submitDataFail(errorMessage));
            return Promise.reject(errorMessage);
        }
    }

    const exportInvoiceData = async (office , status, start_date, end_date) => {
        try {
            dispatch(submittingData());
            const invoice = moduleParam.INVOICE_LIST
            const query = `module=${invoice}&filter[office]=${office ?? ''}&filter[status]=${status ?? ''}&filter[start_date]=${start_date ?? ''}&filter[end_date]=${end_date ?? ''}`
            const url = `${API_URL.DataExport}?${query}`
            const res = await ApiRequest.post(url);
            const response = res.data;
            if(response.status === 'success') {
                const data = response.payload.data;
                const filterData = getFilteredData(data, invoiceListColumns)
                const sheetHeader = [invoiceListColumns.map(item => item.label)]

                let fileName = ''

                fileName += invoice?.charAt(0).toUpperCase() + invoice.slice(1);
                if( start_date || end_date){
                    fileName += `(${start_date} ~ ${end_date || dayjs().format('YYYY-MM-DD')})`;
                }
                fileName += `.xlsx`;

                writeOutExcelFile(sheetHeader, filterData, fileName);
                dispatch(submitDataSuccess());
                showNoti('Invoice list data exported successfully', 'success');
            }
        } catch (err) {
            const errorMessage = err.response?.data.errors.message || err.message;
            dispatch(submitDataFail(errorMessage));
            showNoti('Error occurred while exporting data', 'error')
            return Promise.reject(errorMessage);
        }
    }

    function changeSearchTerm(search_key) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(search_key));
    }

    function changeStartDate(date) {
        dispatch(setStartDate(date));
    }

    function changeEndDate(date) {
        dispatch(setEndDate(date));
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeOfficeFilter(code){
        dispatch(setCurrentPage(0));
        dispatch(setOfficeFilter(code));
    }

    function changeShopFilter(shopid){
        dispatch(setCurrentPage(0));
        dispatch(setShopFilter(shopid));
    }

    function changeCommitteeFilter(committee_id) {
        dispatch(setCurrentPage(0));
        dispatch(setCommitteeFilter(committee_id));
    }

    function changeStatusFilter(status) {
        dispatch(setCurrentPage(0));
        dispatch(setStatusFilter(status));
    }

    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        createInvoices,
        createMultipleInvoices,
        fetchInvoiceList,
        fetchInvoiceListBySettlementId,
        fetchInvoiceListByMemberId,
        deleteInvoice,
        getInvoiceDetails,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeOfficeFilter,
        changeShopFilter,
        changeCommitteeFilter,
        changeStatusFilter,
        refreshDialogState,
        downloadOrderFileUploadTemplate,
        exportInvoiceData
    }
}

export default useInvoiceActions;