import { useSelector } from "react-redux";
import useProductCategoryActions from './_actions';

export const useProductCategory = () => {
      const {
            fetchProductCategoryList,
            getAllProductCategoryList,
            getRelatedProductList,
            createProductCategory,
            updateProductCategory,
            deleteProductCategory,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState
      } = useProductCategoryActions();

      const productCategory = useSelector(state => state.productCategory.data);
      const currentPage = useSelector(state => state.productCategory.currentPage);
      const totalRows = useSelector(state => state.productCategory.totalRows);
      const rowsPerPage = useSelector(state => state.productCategory.rowsPerPage);
      const searchTerm = useSelector(state => state.productCategory.searchTerm);

      const isLoading = useSelector(state => state.productCategory.isLoading);
      const isError = useSelector(state => state.productCategory.isError);
      const errorMessage = useSelector(state => state.productCategory.errorMessage);
      const apiAction = useSelector(state => state.productCategory.action);
      const dialogStates = useSelector(state => state.productCategory.dialogStates);

      return {
            //state
            productCategory,
            isLoading,
            isError,
            errorMessage,
            currentPage,
            rowsPerPage,
            totalRows,
            searchTerm,
            dialogStates,
            apiAction,
            
            //actions
            fetchProductCategoryList,
            getAllProductCategoryList,
            getRelatedProductList,
            createProductCategory,
            updateProductCategory,
            deleteProductCategory,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState
      }
}

export { default as productCategoryReducer } from './_reducers';