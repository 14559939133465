import { useSelector } from "react-redux";
import useNewRowActions from './_actions';

export const useNewRows = () => {
      const {
            addNewRow,
            removeNewRow,
      } = useNewRowActions();

      const newRows = useSelector(state => state.newRows.data);

      return {
            //state
            newRows,
            //actions
            addNewRow,
            removeNewRow
      }
}

export { default as newRowReducer } from './_reducers';