import { useSelector } from 'react-redux';
import useDashboardActions from './_actions';

export const useDashboard = () => {
    const {
        fetchAdminDashboardData,
        fetchFinanceHODashboardData,
        reCalculateLedger,
        resetDialog
    } = useDashboardActions();

    const adminData = useSelector(state => state.dashboard.admin);
    const financeHOData = useSelector(state => state.dashboard.financeHO);
    const isLoading = useSelector(state => state.dashboard.isLoading);
    const isError = useSelector(state => state.dashboard.isError);
    const errorMessage = useSelector(state => state.dashboard.errorMessage);
    const dialogStatus = useSelector(state => state.dashboard.dialogStatus);

    return {
        fetchAdminDashboardData,
        fetchFinanceHODashboardData,
        reCalculateLedger,
        resetDialog,

        adminData,
        financeHOData,
        isLoading,
        isError,
        errorMessage,
        dialogStatus
    }
}

export { default as dashboardReducer } from './_reducers';