import { useDispatch } from 'react-redux';
import { toggleMaintenanceMode, submittingData, submitDataFail, submitDataSuccess } from './_reducers';
import { API_URL } from '../../configs/_api';
import ApiRequest from '../../utils/apiRequest';
import tokenConfig from "../../configs/_token";
import { useNoti } from '../../providers';

const useMaintenanceMode = () => {
      const dispatch = useDispatch();
      const {showNoti} = useNoti();
      const access_token = localStorage.getItem(tokenConfig.ACCESS_TOKEN_NAME);
      const refresh_token = localStorage.getItem(tokenConfig.REFRESH_TOKEN_NAME);

      async function maintenanceModeOn () {
        dispatch(submittingData());
        try {
            const url = `${API_URL.Maintenance}`;
            const res = await ApiRequest.post(url, {access_token, refresh_token});
            const data = res.data?.payload?.data
            dispatch(toggleMaintenanceMode({mode : true}));
            dispatch(submitDataSuccess());
            showNoti(data.message, 'success');
            return data;
        } catch (err) {
            console.error(err);
            dispatch(submitDataFail(err.message));
            return Promise.reject(err);
        }
      }

      async function maintenanceModeOff () {
        dispatch(submittingData());
        try {
            const url = `${API_URL.Maintenance}`;
            const res = await ApiRequest.delete(url, {access_token, refresh_token});
            const data = res.data?.payload?.data
            dispatch(toggleMaintenanceMode({mode : false}));
            dispatch(submitDataSuccess());
            showNoti(data.message, 'success');
            return data;
        } catch (err) {
            console.error(err);
            dispatch(submitDataFail(err.message));
            return Promise.reject(err);
        }
      }

      async function getMaintenanceStatus () {
        try {
          const url = `${API_URL.Maintenance}`;
          const res = await ApiRequest.get(url, {access_token, refresh_token});
          const data = res.data.payload?.data;
          dispatch(toggleMaintenanceMode({mode : data.status}));
          return data;
        } catch (err) {
          console.error(err);
            dispatch(submitDataFail(err.message));
            return Promise.reject(err);
        }
      }

      return {
            maintenanceModeOn,
            maintenanceModeOff,
            getMaintenanceStatus
      };

};

export default useMaintenanceMode;