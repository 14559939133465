import { useSelector } from 'react-redux';
import useInvoiceActions from './_actions';

export const useInvoice = () => {
    const {
        fetchInvoiceList,
        fetchInvoiceListBySettlementId,
        fetchInvoiceListByMemberId,
        deleteInvoice,
        getInvoiceDetails,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeOfficeFilter,
        changeShopFilter,
        changeCommitteeFilter,
        changeStatusFilter,
        changeCurrentPage, 
        changeRowsPerPage,
        refreshDialogState,
        downloadOrderFileUploadTemplate,
        createMultipleInvoices,
        createInvoices,
        exportInvoiceData
    } = useInvoiceActions();

    const invoices = useSelector(state => state.invoice.data);
    const searchTerm = useSelector(state => state.invoice.searchTerm);
    const officeFilter = useSelector(state => state.invoice.filters.office_id);
    const shopFilter = useSelector(state => state.invoice.filters.shopid);
    const committeeFilter = useSelector(state => state.invoice.filters.committee_id);
    const statusFilter = useSelector(state => state.invoice.filters.status);
    const startDate = useSelector(state => state.invoice.filters.startDate);
    const endDate = useSelector(state => state.invoice.filters.endDate);
    const currentPage = useSelector(state => state.invoice.currentPage);
    const totalRows = useSelector(state => state.invoice.totalRows);
    const rowsPerPage = useSelector(state => state.invoice.rowsPerPage);
    const isLoading = useSelector(state => state.invoice.isLoading);
    const isError = useSelector(state => state.invoice.isError);
    const errorMessage = useSelector(state =>state.invoice.errorMessage);
    const apiAction = useSelector(state => state.invoice.action);

    return {
        createInvoices,
        createMultipleInvoices,
        fetchInvoiceList,
        fetchInvoiceListBySettlementId,
        fetchInvoiceListByMemberId,
        deleteInvoice,
        getInvoiceDetails,
        changeSearchTerm,
        changeStartDate,
        changeEndDate,
        changeOfficeFilter,
        changeShopFilter,
        changeCurrentPage,
        changeRowsPerPage,
        refreshDialogState,
        changeCommitteeFilter,
        changeStatusFilter,
        downloadOrderFileUploadTemplate,
        exportInvoiceData,

        invoices,
        searchTerm,
        officeFilter,
        shopFilter,
        committeeFilter,
        statusFilter,
        startDate,
        endDate,
        currentPage,
        rowsPerPage,
        totalRows,
        isLoading,
        isError,
        errorMessage,
        apiAction
    }
}

export { default as invoiceReducer } from './_reducers';