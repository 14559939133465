import * as XLSX from "xlsx";

/**
 * # Data Export XLSX
 * @augments Array sheetHeader
 * @augments Array filterData
 * @returns wrokbook
 */


export const xlsxWrokBookExport = (sheetHeader, filterData = []) => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(workSheet, sheetHeader);
    XLSX.utils.sheet_add_json(workSheet, filterData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet 1');

    return workBook;
}

export const writeOutExcelFile = (sheetHeader, filterData = [], fileName = '') => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(workSheet, sheetHeader);
    XLSX.utils.sheet_add_json(workSheet, filterData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(workBook, workSheet, 'sheet 1');

    XLSX.writeFile(workBook, fileName);
}