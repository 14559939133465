import { useSelector } from "react-redux";
import useLoanContractDetailActions from "./_actions";

export const useLoanDetail = () => {
    const {
        fetchLoanContractDetailList,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
        changeStatusFilter,
    } = useLoanContractDetailActions();

    const loan_details = useSelector(state => state.loan_detail.data);
    const currentPage = useSelector(state => state.loan_detail.currentPage);
    const totalRows = useSelector(state => state.loan_detail.totalRows);
    const rowsPerPage = useSelector(state => state.loan_detail.rowsPerPage);
    const isLoading = useSelector(state => state.loan_detail.isLoading);
    const isError = useSelector(state => state.loan_detail.isError);
    const errorMessage = useSelector(state => state.loan_detail.errorMessage);
    const searchTerm = useSelector(state => state.loan_detail.searchTerm);
    const statusFilter = useSelector(state => state.loan_detail.filters.status); 

    return {
        fetchLoanContractDetailList,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
        changeStatusFilter,

        loan_details,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        statusFilter
    }
}

export {default as loanDetailReducer} from './_reducers';