import { Card, Typography } from '@mui/material';
import React from 'react';

export default function FormSessionBox(props) {
  return (
    <Card
        sx={{ padding: '20px', marginY: '20px', paddingTop: '10px'}}
        elevation={1}
    >
        <Typography sx={{ m: 0 }} variant="caption">{props.title}</Typography>
        {props.children}
    </Card>
  )
}
