import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import {
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setRegionIdFilter,
    setTownshipIdFilter,
    setStatusFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submitDataSuccess,
    submittingData,
    submitDataFail,
    reloadDialogState
} from "./_reducers";
import { useNoti } from "../../providers";
// import { asymmetricEncrypt } from '../../utils/crypt';

const useShopActions = () => {
    const dispatch = useDispatch();
    const { showNoti } = useNoti();

    const currentPage = useSelector(state => state.shop.currentPage);
    const rowsPerPage = useSelector(state => state.shop.rowsPerPage);
    const searchTerm = useSelector(state => state.shop.searchTerm);
    const statusFilter = useSelector(state => state.shop.filters.status);
    const regionIdFilter = useSelector(state => state.shop.filters.region_id);
    const townshipIdFilter = useSelector(state => state.shop.filters.township_id);

    function getShopListByFilter(shop_type) {
        dispatch(fetchingData());
        const getShopListUrl = `${API_URL.Shop}/getListByFilter?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}&filters[region_id]=${regionIdFilter}&filters[township_id]=${townshipIdFilter}&filters[type]=${shop_type ?? ''}`;

        ApiRequest.get(getShopListUrl)
            .then(res => {
                const data = res.data.payload.data;
                const { total_items } = res.data.payload.metadata;

                dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                const errMsg = err.response?.data.errors?.message ?? `${err.message}. Fail to find shops!`;
                dispatch(fetchDataFail(errMsg));
            });
    }

    async function addShop(shopFormData) {
        dispatch(submittingData());
        const addShopUrl = `${API_URL.Shop}/add`;
        try {
            // const encryptedPinCode = asymmetricEncrypt("" + shopFormData.get("pin_code"));
            // shopFormData.set("pin_code", encryptedPinCode);
            const res = await ApiRequest.post(addShopUrl, shopFormData);
            const newShop = res.data.payload.data;
            dispatch(submitDataSuccess());
            return newShop;
        }
        catch (err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            dispatch(submitDataFail(err.response?.data.errors.message ?? 'Creating New Shop failed!'))
            return Promise.reject(errors);
        }
    }

    async function editShop(shopFormData) {
        dispatch(submittingData());
        const editShopUrl = `${API_URL.Shop}/update`;
        try {
            // const encryptedPinCode = asymmetricEncrypt("" + shopFormData.get("pin_code"));
            // shopFormData.set("pin_code", encryptedPinCode);
            const res = await ApiRequest.put(editShopUrl, shopFormData);
            const editedShop = res.data.payload.data;
            dispatch(submitDataSuccess());
            return editedShop;
        } catch (err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            dispatch(submitDataFail(err.response?.data.errors.message ?? 'Creating New Shop failed!'))
            return Promise.reject(errors);
        }
    }

    async function editShopPic(reqData) {
        const url = `${API_URL.Shop}/${reqData.shopid}`;
        try {
            await ApiRequest.put(url, reqData);
        }
        catch (err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            !err.response && showNoti(err.message, 'error');
            return Promise.reject(errors);
        }
    }

    /**
     * 
     * @param {String} shopId 
     */
    async function getShopDetail(shopId, apiOptions={}) {
        const getShopDetailUrl = `${API_URL.Shop}/${shopId}`;
        try {
            const res = await ApiRequest.get(getShopDetailUrl, {...apiOptions});
            const shop = res.data.payload.data;
            return shop;
        }
        catch (err) {
            const errMsg = err.response?.data.errors?.message ?? err.message;
            return Promise.reject(errMsg);
        }
    }

    /**
     * 
     * @param {string} shopId 
     */
    async function deactivateShop(shopId) {
        const deactivateUrl = `${API_URL.Shop}/${shopId}/deactivate`;
        try {
            const res = await ApiRequest.patch(deactivateUrl);
            return res.status;
        }
        catch (err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? `Fail to deactivate shop! ${err.message}`;
            return Promise.reject(errMsg);
        }
    }

    /**
     * 
     * @param {string} shopId 
     */
    async function activateShop(shopId) {
        const activateUrl = `${API_URL.Shop}/${shopId}/activate`;
        try {
            const res = await ApiRequest.patch(activateUrl);
            return res.status;
        }
        catch (err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? `Fail to deactivate shop! ${err.message}`;
            return Promise.reject(errMsg);
        }
    }

    /**
     * 
     * @param {string} shopId 
     */
    async function changeShopPhoneNumber(shop) {
        const changePhoneNoUrl = `${API_URL.Shop}/${shop._id}/change_ph_number`;
        try {
            const res = await ApiRequest.post(changePhoneNoUrl, shop);
            return res.status;
        }
        catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `Fail to change phone number! ${err.message}`;
            return Promise.reject(errMsg);
        }
    }

    async function getUnsettledInvoices(shopId, isNoDraft = false, settlementId, apiOptions = {}) {
        const url = `${API_URL.Shop}/${shopId}/invoices?filters[is_settled]=false&filters[is_no_draft]=${isNoDraft}&filters[settlement_id]=${settlementId}`;
        dispatch(submittingData());
        try {
            const res = await ApiRequest.get(url, { ...apiOptions });
            dispatch(submitDataSuccess());
            return res.data.payload.data;
        }
        catch (err) {
            console.error(err);
            dispatch(submitDataFail(err.message));
            return Promise.reject();
        }
    }

    async function getInvoicesOnDraftSettlement(shopId, settlementId, apiOptions = {}) {
        const url = `${API_URL.Shop}/${shopId}/invoices?filters[settlement_id]=${settlementId}`;
        dispatch(submittingData());
        try {
            const res = await ApiRequest.get(url, { ...apiOptions });
            dispatch(submitDataSuccess());
            return res.data.payload.data;
        }
        catch (err) {
            console.error(err);
            dispatch(submitDataFail(err.message));
            showNoti(`Error! ${err.message}`);
            return Promise.reject();
        }
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }

    function changeStatusFilter(status) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(""));
        dispatch(setStatusFilter(status));
    }

    function changeRegionIdFilter(id) {
        dispatch(setSearchTerm(''));
        dispatch(setRegionIdFilter(id));
    }

    function changeTownshipIdFilter(id) {
        dispatch(setSearchTerm(''));
        dispatch(setTownshipIdFilter(id));
    }
    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    /**
     * 
     * @param {string} shopId 
     */
    async function resetShopPin(shopId) {
        const resetShopPinUrl = `${API_URL.Shop}/${shopId}/reset-pin`;
        try {
            const res = await ApiRequest.patch(resetShopPinUrl);
            return res.status;
        }
        catch (err) {
            console.error(err);
            const errMsg = err.response?.data.errors?.message ?? err.message;
            return Promise.reject(errMsg);
        }
    }

    return {
        getShopListByFilter,
        getShopDetail,
        addShop,
        editShop,
        editShopPic,
        changeShopPhoneNumber,
        getUnsettledInvoices,
        getInvoicesOnDraftSettlement,
        changeStatusFilter,
        deactivateShop,
        activateShop,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionIdFilter,
        changeTownshipIdFilter,
        resetShopPin,
        refreshDialogState
    }
}

export default useShopActions;