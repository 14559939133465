import { useDispatch } from "react-redux";
import {
    resetData,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
} from './_reducers';
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";


const useRevenueActions = () => {


    const dispatch = useDispatch();

    async function fetchRevenueList() {
        dispatch(resetData());
        const url = `${API_URL.RevenueTransfers}/revenue`;
        fetchingData();
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    return {
        fetchRevenueList,
    }
}

export default useRevenueActions;