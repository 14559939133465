import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import { API_URL } from "../../configs/_api";
import * as XLSX from "xlsx";
import { memberColumns } from "../../configs/_dataColumnConfig"

import {
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setStatusFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageTractCodeFilter,
    setVillageCodeFilter,
    setPositionFilter,
    setCreatedDateFilter,
    setSearchTermForCommittee,
    setStatusFilterForCommittee,
    setSmsStatusFilterForCommittee,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState,
    setMemberTypeFilter
} from "./_reducers";
import { useNoti } from "../../providers";
import { useMetaData } from "../meta_data";
import useDataExportActions from "../data_exports/_actions";
import { writeOutExcelFile } from "../../utils/xlsxWorkBook";

const useMemberActions = () => {
  const dispatch = useDispatch();
  const { showNoti } = useNoti();
  const { farmTypes, cropTypes, memberPositions } = useMetaData();

  const { moduleParam, getFilteredData } = useDataExportActions()

    const currentPage = useSelector(state => state.member.currentPage);
    const rowsPerPage = useSelector(state => state.member.rowsPerPage);
    const searchTerm = useSelector(state => state.member.searchTerm);
    const searchTermForCommittee = useSelector(state => state.member.searchTermForCommittee);
    const statusFilterForCommittee = useSelector(state => state.member.filters.statusForCommittee); 
    const smsStatusFilterForCommittee = useSelector(state => state.member.filters.smsStatusForCommittee); 
    const statusFilter = useSelector(state => state.member.filters.status); 
    const regionCodeFilter = useSelector(state => state.member.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.member.filters.township_code);
    const villageTractCodeFilter = useSelector(state => state.member.filters.village_tract_code);
    const villageCodeFilter = useSelector(state => state.member.filters.village_code);
    const positionFilter = useSelector(state => state.member.filters.position_id);
    const member_type = useSelector(state => state.member.filters.member_type);
    const createdDateFilter = useSelector(state => state.member.filters.created_date);

  function fetchMemberListByCommitteeId(committeeId) {
    dispatch(fetchingData());

    let url = `${
      API_URL.Member
    }?search_term=${searchTermForCommittee}&page_no=${
      currentPage + 1
    }&page_size=${rowsPerPage}&filters[status]=${statusFilterForCommittee}&filters[committee_id]=${committeeId}&filters[sms_actions]=${smsStatusFilterForCommittee}`;
    ApiRequest.get(url)
      .then((res) => {
        const data = res.data.payload.data;
        const { total_items } = res.data.payload.metadata;
        dispatch(fetchDataSuccess({ data, total_items }));
      })
      .catch((err) => {
        console.error(err);
        dispatch(fetchDataFail(err.message));
      });
  }

  function fetchMemberList() {
    dispatch(fetchingData());

        let url = `${API_URL.Member}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}&filters[village_tract_code]=${villageTractCodeFilter}&filters[village_code]=${villageCodeFilter}&filters[position_id]=${positionFilter}&filters[created_date]=${createdDateFilter ?? ''}&filters[member_type]=${member_type}`;
        ApiRequest.get(url).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;
            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

  async function updateMember(member) {
    let edit_member_url = API_URL.Member + "/update";

    try {
      const res = await ApiRequest.post(edit_member_url, member, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const editMember = res.data;
      return editMember;
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ?? `${err.message}. Fail to Edit!`;
      return Promise.reject(errMsg);
    }
  }

  async function getRelatedCommittees(
    region_code,
    township_code,
    village_tract_code
  ) {
    let url = `${API_URL.Committee}?filters[region_code]=${region_code}&filters[township_cod]=${township_code}&filters[village_tract_code]=${village_tract_code}`;
    try {
      const res = await ApiRequest.get(url);
      return res.data.payload.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(`Unable to get committee list. ${err.message}`);
    }
  }

  async function addNewMember(member) {
    dispatch(submittingData());
    try {
      const url = API_URL.Member;
      const res = await ApiRequest.post(url, member);
      const newMember = res.data.payload.data;
      dispatch(submitDataSuccess());
      return newMember;
    } catch (err) {
      const errors = {};
      errors.data = err.response?.data.errors.data;
      !err.response && showNoti(err.message, "error");
      dispatch(submitDataFail());
      return Promise.reject(errors);
    }
  }

  async function editMember(member) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.Member}/update`;
      const res = await ApiRequest.post(url, member);
      const editedMember = res.data.payload.data;
      dispatch(submitDataSuccess());
      return editedMember;
    } catch (err) {
      const errors = {};
      errors.data = err.response?.data.errors.data;
      !err.response && showNoti(err.message, "error");
      dispatch(submitDataFail());
      return Promise.reject(errors);
    }
  }

  async function createMultipleMembers(file, committeeId) {
    const url = `${API_URL.Member}/batch`;
    const data = new FormData();
    data.append("file", file);
    data.append("committee_id", committeeId);

    try {
      dispatch(submittingData());
      const res = await ApiRequest.post(url, data);
      dispatch(submitDataSuccess());
      return res.data;
    } catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      dispatch(submitDataFail());
      return Promise.reject(errMsg);
    }
  }

  async function getFarmTypes() {
    try {
      const url = `${API_URL.Member}/farm-types`;
      const res = await ApiRequest.get(url);
      return res.data.payload?.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(`Unable to get farm types. ${err.message}`);
    }
  }

  async function getCropTypes () 
  {
    try {
      const url = `${API_URL.Member}/crop-types`;
      const res = await ApiRequest.get(url);
      return res.data.payload?.data;
    }
    catch (err) {
      console.error(err);
      return Promise.reject(`Unable to get crop types. ${err.message}`);
    }
  }

  async function getMemberDetail(memberId, apiOptions = {}) {
    dispatch(fetchingData());
    try {
      const url = `${API_URL.Member}/${memberId}`;
      const res = await ApiRequest.get(url, { ...apiOptions });
      const member = res.data.payload.data;
      dispatch(fetchDataSuccess());
      return member;
    } catch (err) {
      console.error(err);
      dispatch(fetchDataFail(`Failed to get member detail! ${err.message}`));
      return Promise.reject();
    }
  }

  async function resetMemberPin(memberId) {
    const url = `${API_URL.Member}/${memberId}/reset-pin`;
    dispatch(submittingData());
    try {
      const res = await ApiRequest.patch(url);
      const statusCode = res.status;
      if (statusCode == 200) {
        showNoti(`Pin code reset and sent SMS successfully!`, "success");
      } else if (statusCode == 207) {
        showNoti(`Pin code reset success but sending SMS fails.`, "warning");
      }
      dispatch(submitDataSuccess());
      return Promise.resolve();
    } catch (err) {
      console.error(err);
      dispatch(submitDataFail());
      showNoti(`Error to reset member pin. ${err.message}`, "error");
      return Promise.reject();
    }
  }

  async function activateMember(memberId) {
    const url = `${API_URL.Member}/${memberId}/activate`;
    dispatch(submittingData());
    try {
      await ApiRequest.patch(url);
      showNoti(`Member activated successfully`, "success");
      dispatch(submitDataSuccess());
      return Promise.resolve();
    } catch (err) {
      console.error(err);
      dispatch(submitDataFail());
      showNoti(`Error to activate member. ${err.message}`, "error");
      return Promise.reject();
    }
  }

  async function deactivateMember(memberId) {
    const url = `${API_URL.Member}/${memberId}/deactivate`;
    dispatch(submittingData());
    try {
      await ApiRequest.patch(url);
      dispatch(submitDataSuccess());
      showNoti(`Member deactivated successfully`, "success");
      return Promise.resolve();
    } catch (err) {
      console.error(err);
      dispatch(submitDataFail());
      showNoti(`Error to deactivate member. ${err.message}`, "error");
      return Promise.reject();
    }
  }

  async function verifyMember(memberId) {
    const url = `${API_URL.Member}/${memberId}/verify`;
    dispatch(submittingData());
    try {
      await ApiRequest.patch(url);
      showNoti(
        `Member verified and 6 digit pin sent by SMS successfully`,
        "success"
      );
      dispatch(submitDataSuccess());
      // return Promise.resolve();
    } catch (err) {
      console.error(err);
      dispatch(submitDataFail());
      showNoti(`Error to verify member. ${err.message}`, "error");
      // return Promise.reject();
    }
  }

  async function getMemberTransaction(memberId) {
    try {
      const url = `${API_URL.Member}/${memberId}/transaction-history?page_no=${
        currentPage + 1
      }&page_size=${rowsPerPage}`;
      const res = await ApiRequest.get(url);
      const data = res.data.payload.data;
      const { total_items } = res.data.payload.metadata;
      dispatch(fetchDataSuccess({ total_items }));
      return data;
    } catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, "error");
    }
  }

  function downloadMemberFileUploadTemplate(columns) {
    const workBook = XLSX.utils.book_new();

    const dataSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
    XLSX.utils.book_append_sheet(workBook, dataSheet, "data");

    const farmTypeMetaSheet = getFarmTypeMetaSheet();
    XLSX.utils.book_append_sheet(
      workBook,
      farmTypeMetaSheet,
      "(meta) farm type"
    );

    const cropTypeMetaSheet = getCropTypeSheet();
    XLSX.utils.book_append_sheet(
      workBook,
      cropTypeMetaSheet,
      "(meta) crop type"
    );

    const memberPositionMetaSheet = getPositionMetaSheet();
    XLSX.utils.book_append_sheet(
      workBook,
      memberPositionMetaSheet,
      "(meta) member position"
    );

    XLSX.writeFile(workBook, "memberUploadTemplate.xlsx");

    function getFarmTypeMetaSheet() {
      const farmTypeSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(farmTypeSheet, [["id", "name"]]);
      const filteredFarmTypes = farmTypes.map((ft) => ({
        id: ft._id,
        name: ft.name,
      }));
      XLSX.utils.sheet_add_json(farmTypeSheet, filteredFarmTypes, {
        origin: "A2",
        skipHeader: true,
      });

      return farmTypeSheet;
    }

    function getCropTypeSheet() {
      const cropTypeSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(cropTypeSheet, [["id", "name"]]);
      const filteredCropTypes = cropTypes.map((ct) => ({
        id: ct._id,
        name: ct.name,
      }));
      XLSX.utils.sheet_add_json(cropTypeSheet, filteredCropTypes, {
        origin: "A2",
        skipHeader: true,
      });

      return cropTypeSheet;
    }

    function getPositionMetaSheet() {
      const positionSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(positionSheet, [["id", "name"]]);
      const filteredPositions = memberPositions.map((mp) => ({
        id: mp._id,
        name: mp.mm_name,
      }));
      XLSX.utils.sheet_add_json(positionSheet, filteredPositions, {
        origin: "A2",
        skipHeader: true,
      });

      return positionSheet;
    }
  }

  async function changeMemberPhoneNumber(member) {
    const changePhoneNoUrl = `${API_URL.Member}/${member._id}/change_ph_number`;
    try {
      const res = await ApiRequest.post(changePhoneNoUrl, member);
      return res.status;
    } catch (err) {
      const errMsg =
        err.response?.data.errors?.message ??
        `Fail to change phone number! ${err.message}`;
      return Promise.reject(errMsg);
    }
  }

  const exportMemberData = async (region, township, village_track, village, position, created_date) => {
    try {
      dispatch(submittingData());
      const member = moduleParam.MEMBER;
      const query = `module=${member}&filter[region]=${region}&filter[township]=${township}&filter[village_track]=${village_track}&filter[village]=${village}&filter[position]=${position}&filter[created_at]=${created_date ?? ''}` 
      const url = `${API_URL.DataExport}?${query}`
      const res = await ApiRequest.post(url)
      const response = res.data;
      if(response.status === 'success') {
        const data = response.payload.data;
        const filterData = getFilteredData(data, memberColumns)
        const sheetHeader = [memberColumns.map(item => item.label)]

        let fileName = ''

        fileName += member?.charAt(0).toUpperCase() + member.slice(1);
        if(created_date){
            fileName += `(${created_date}`;
        }
        fileName += `.xlsx`;

        writeOutExcelFile(sheetHeader, filterData, fileName);
        dispatch(submitDataSuccess());
        showNoti('Member list data exported successfully', 'success');
    }
    } catch (err) {
      const errorMessage = err.response?.data.errors.message || err.message;
      dispatch(submitDataFail(errorMessage));
      showNoti(errorMessage || 'Error occurred while exporting data', 'error')
      return Promise.reject(errorMessage);
    }
  }

  function changeSearchTerm(searchTerm) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTerm(searchTerm));
  }

  function changeStatusFilter(status) {
    dispatch(setStatusFilter(status));
  }

  function changeRegionCodeFilter(code) {
    dispatch(setCurrentPage(0));
    dispatch(setRegionCodeFilter(code));
  }

  function changeTownshipCodeFilter(code) {
    dispatch(setCurrentPage(0));
    dispatch(setTownshipCodeFilter(code));
  }

    function changeVillageTractCodeFilter(code) {
        dispatch(setCurrentPage(0));
        dispatch(setVillageTractCodeFilter(code));
    }

    function changeVillageCodeFilter(code) {
        dispatch(setCurrentPage(0));
        dispatch(setVillageCodeFilter(code));
    }

  function changePositionFilter(positionName) {
    dispatch(setCurrentPage(0));
    dispatch(setPositionFilter(positionName));
  }

  function changeMemberTypeFilter(type) {
    dispatch(setCurrentPage(0));
    dispatch(setMemberTypeFilter(type));
  }

  function changeCurrentPage(pageNo) {
    dispatch(setCurrentPage(pageNo));
  }

  function changeRowsPerPage(rowsPerPage) {
    dispatch(setCurrentPage(0));
    dispatch(setRowsPerPage(rowsPerPage));
  }

  function changeCreatedDateFilter(date) {
    dispatch(setCreatedDateFilter(date));
  }

  function changeSearchTermForCommittee(searchTerm) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTermForCommittee(searchTerm));
  }

  function changeStatusFilterForCommittee(status) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTermForCommittee(""));
    dispatch(setStatusFilterForCommittee(status));
  }

  function changeSMSStatusFilterForCommittee(sms_actions) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTermForCommittee(""));
    dispatch(setSmsStatusFilterForCommittee(sms_actions));
  }

  function refreshDialogState() {
    dispatch(reloadDialogState());
  }

    return {
        changeMemberPhoneNumber,
        fetchMemberList,
        fetchMemberListByCommitteeId,
        getRelatedCommittees,
        getMemberDetail,
        getMemberTransaction,
        getFarmTypes,
        getCropTypes,
        addNewMember,
        editMember,
        updateMember,
        createMultipleMembers,
        resetMemberPin,
        activateMember,
        deactivateMember,
        verifyMember,
        changeSearchTerm,
        changeSearchTermForCommittee,
        changeStatusFilter,
        changeStatusFilterForCommittee,
        changeSMSStatusFilterForCommittee,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        changeVillageCodeFilter,
        changePositionFilter,
        changeCreatedDateFilter,
        changeCurrentPage,
        changeMemberTypeFilter,
        changeRowsPerPage,
        refreshDialogState,
        downloadMemberFileUploadTemplate,
        exportMemberData
    }
}

export default useMemberActions;
