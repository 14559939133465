import { useSelector } from "react-redux";
import useAppVersionActions from "./_actions";

export const useAppVersion = () => {
    const {
        fetchAppVersionList,
        updateAppVersionInfo,
        refreshDialogState
    } = useAppVersionActions();

    const appVersions = useSelector(state => state.app_version.data);
    const isLoading = useSelector(state => state.app_version.isLoading);
    const isError = useSelector(state => state.app_version.isError);
    const errorMessage = useSelector(state => state.app_version.errorMessage);
    const apiAction = useSelector(state => state.app_version.action);

    return {
        appVersions,
        isLoading,
        isError,
        errorMessage,
        apiAction,

        fetchAppVersionList,
        updateAppVersionInfo,
        refreshDialogState
    }
}

export {default as appVersionReducer} from './_reducers';