import { useDispatch, useSelector } from "react-redux";
import {
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    setCurrentPage,
    setRowsPerPage,
    reloadDialogState
} from './_reducers';
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import { useNoti } from "../../providers";

const useStaticPageActions = () => {
    const dispatch = useDispatch();
    const { showNoti } = useNoti();

    const currentPage = useSelector(state => state.static_page.currentPage);
    const rowsPerPage = useSelector(state => state.static_page.rowsPerPage);

    async function fetchStaticPageList() {
        dispatch(fetchingData());
        try {
            const url = `${API_URL.StaticPage}?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
            const res = await ApiRequest.get(url);
            const data = res.data.payload.data;
            const { total_items } = res.data.payload.metadata;
            dispatch(fetchDataSuccess({ data, total_items }));
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            dispatch(fetchDataFail(errMsg));
        }
    }

    async function createStaticPage(data) {
        dispatch(submittingData());
        try {
            const url = API_URL.StaticPage;
            await ApiRequest.post(url, data);
            showNoti("New Static Page created successfully", "success");
            fetchStaticPageList();
            dispatch(submitDataSuccess());
        }
        catch(err) {
            const errMsg = err.response?.data.errors.message || err.message;
            const errors = err.response?.data.errors.data || null;
            if(!errors) {
                showNoti(errMsg);
            }
            dispatch(submitDataFail(errMsg));
            return Promise.reject(errors);
        }
    }

    async function updateStaticPage(pageId, data) {
        dispatch(submittingData());
        try {
            const url = `${API_URL.StaticPage}/${pageId}`;
            await ApiRequest.patch(url, data);
            showNoti(`${data.name} page updated successfully`, 'success');
            fetchStaticPageList();
            dispatch(submitDataSuccess());
        }
        catch(err) {
            const errMsg = err.response?.errors.message || err.message;
            dispatch(submitDataFail(errMsg));
        }
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        fetchStaticPageList,
        createStaticPage,
        updateStaticPage,
        changeCurrentPage,
        changeRowsPerPage,
        refreshDialogState
    }
}

export default useStaticPageActions;