import { useDispatch } from "react-redux";
import {
      setStep
} from "./_reducers";

const useBreadCrumbActions = () => {
      const dispatch = useDispatch(); 

      function setBreadCrumbStep(steps){
            dispatch(setStep(steps))
      }

      return {
            setBreadCrumbStep,
      }
}

export default useBreadCrumbActions;