import { useDispatch, useSelector } from "react-redux";
import {
  setStatusFilter,
  setCurrentPage,
  setSearchTerm,
  setRowsPerPage,
  submittingData,
  setNewOrderCount,
  submitDataFail,
  submitDataSuccess,
  fetchingData,
  fetchDataSuccess,
  fetchDataFail,
  reloadDialogState,
  setStartDate,
  setEndDate,
  setRegionCodeFilter,
} from "./_reducers";
import ApiRequest from "../../utils/apiRequest";
import { API_URL } from "../../configs/_api";
import { useNoti } from "../../providers";

const useEcommerceOrderActions = () => {
  const dispatch = useDispatch();
  const { showNoti } = useNoti();

  const currentPage = useSelector((state) => state.ecommerceOrder.currentPage);
  const rowsPerPage = useSelector((state) => state.ecommerceOrder.rowsPerPage);
  const searchTerm = useSelector((state) => state.ecommerceOrder.searchTerm);
  const startDate = useSelector(state => state.ecommerceOrder.filters?.startDate);
  const endDate = useSelector(state => state.ecommerceOrder.filters?.endDate);
  const statusFilter = useSelector(state => state.ecommerceOrder.filters.status);
  const region_id = useSelector(state => state.ecommerceOrder.filters?.region_id);

  function fetchEcommerceOrderList() {
    dispatch(fetchingData());
    let getOrderListUrl = `${API_URL.EcommerceOrder}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}&filters[start_date]=${startDate || ''}&filters[end_date]=${endDate || ''}&filters[region_id]=${region_id}`;

    ApiRequest.get(getOrderListUrl)
      .then(res => {
        const data = res.data.payload.data;
        const { total_items } = res.data.payload.metadata;

        dispatch(fetchDataSuccess({ data, total_items }));
      }).catch(err => {
        const errMsg = err.response?.data.errors?.message ?? `${err.message}`
        dispatch(fetchDataFail(errMsg))
      })
  }

  async function getEcommerceOrderDetail(order_id) {
    dispatch(fetchingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${order_id}`;
      const res = await ApiRequest.get(url);
      // console.log("res.data.payload.data", )
      // return res.data.payload.data;

      const data = res.data.payload.data;
      dispatch(fetchDataSuccess(data));
      return data;
    }
    catch (err) {
      // console.error(err);
      showNoti("Error to get order detail", "error");
      const errMsg = err.response?.data.errors?.message ?? err.message;
      dispatch(fetchDataFail(errMsg));
      return Promise.reject(errMsg);
    }
    // return data.filter((d) => d._id == id)[0]
  }

  async function updateEcommerceOrder(data) {
    dispatch(submittingData());
    const url = `${API_URL.EcommerceOrder}/edit`;

    try {
      const res = await ApiRequest.post(url, data);
      const order = res.data
      dispatch(submitDataSuccess())
      return order
    } catch (err) {
      const errors = {};
      errors.data = err?.response?.data?.errors?.data;
      dispatch(submitDataFail(err.response.data.errors.message ?? "updating order failed!"));
      return Promise.reject(errors);
    }
  }

  async function confirmEcommerceOrder(orderId) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${orderId}/confirm`;
      await ApiRequest.post(url);
      dispatch(submitDataSuccess());
      showNoti('Order confirm successed', 'success');
    }
    catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, 'error');
      dispatch(submitDataFail());
    }
  }

  async function cancelEcommerceOrder(orderId) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${orderId}/cancel`;
      await ApiRequest.post(url);
      dispatch(submitDataSuccess());
      showNoti('Order cancel successed', 'success');
      getNewOrderCount()
    }
    catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, 'error');
      dispatch(submitDataFail());
    }
  }

  async function submitEcommerceOrder(orderId) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${orderId}/submit`;
      await ApiRequest.post(url);
      dispatch(submitDataSuccess());
      showNoti('Order submit successed', 'success');
    }
    catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, 'error');
      dispatch(submitDataFail());
    }
  }

  async function readyToDeliverEcommerceOrder(orderId) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${orderId}/readyToDeliver`;
      await ApiRequest.post(url);
      dispatch(submitDataSuccess());
      showNoti('Change ready to deliver status successed', 'success');
    }
    catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, 'error');
      dispatch(submitDataFail());
    }
  }

  async function deliveringEcommerceOrder(orderId) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${orderId}/delivering`;
      await ApiRequest.post(url);
      dispatch(submitDataSuccess());
      showNoti('Change delivering status successed', 'success');
    }
    catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, 'error');
      dispatch(submitDataFail());
    }
  }

  async function completeEcommerceOrder(orderId) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.EcommerceOrder}/${orderId}/complete`;
      await ApiRequest.post(url);
      dispatch(submitDataSuccess());
      showNoti('Order submit successed', 'success');
      getNewOrderCount();
    }
    catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      showNoti(errMsg, 'error');
      dispatch(submitDataFail());
    }
  }

  async function getNewOrderCount() {
    try {
      const url = `${API_URL.EcommerceOrder}/new-order-count`;
      const res = await ApiRequest.get(url);
      const data = res.data.payload.data;
      dispatch(setNewOrderCount(data.counts));
      return data;
    } catch (err) {
      const errMsg = err.response?.data.errors.message || err.message;
      console.error(errMsg);
    }
  }


  function changeSearchTerm(search_key) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTerm(search_key));
  }

  function changeStartDate(date) {
    dispatch(setStartDate(date))
  }

  function changeEndDate(date) {
    dispatch(setEndDate(date))
  }

  function changeRegionCodeFilter(regionCode) {
    dispatch(setCurrentPage(0))
    dispatch(setRegionCodeFilter(regionCode))
  }

  function changeCurrentPage(pageNo) {
    dispatch(setCurrentPage(pageNo));
  }

  function changeRowsPerPage(rowsPerPage) {
    dispatch(setCurrentPage(0));
    dispatch(setRowsPerPage(rowsPerPage));
  }

  function changeStatusFilter(status) {
    dispatch(setStatusFilter(status));
  }

  function refreshDialogState() {
    dispatch(reloadDialogState());
  }

  return {
    // Actions
    fetchEcommerceOrderList,
    getEcommerceOrderDetail,
    updateEcommerceOrder,
    confirmEcommerceOrder,
    cancelEcommerceOrder,
    submitEcommerceOrder,
    readyToDeliverEcommerceOrder,
    deliveringEcommerceOrder,
    completeEcommerceOrder,

    changeSearchTerm,
    changeStartDate,
    changeEndDate,
    changeRegionCodeFilter,
    changeCurrentPage,
    changeRowsPerPage,
    changeStatusFilter,
    refreshDialogState,

    getNewOrderCount
  };
};

export default useEcommerceOrderActions;
