import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import { API_URL } from '../../configs/_api';
import { useNoti } from "../../providers";
import * as XLSX from 'xlsx';

import {
      setCurrentPage,
      setRowsPerPage,
      setSearchTerm,
      // Fetch List View Data
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      submittingData,
      submitDataFail,
      submitDataSuccess,
      // Dialog Actions
      dialogActionStarted,
      dialogActionSuccess,
      dialogActionFail,
      reloadDialogState
} from "./_reducers";

const useRegionActions = () => {
      const dispatch = useDispatch();
      const { showNoti } = useNoti();

      const currentPage = useSelector(state => state.region.currentPage);
      const rowsPerPage = useSelector(state => state.region.rowsPerPage);
      const searchTerm = useSelector(state => state.region.searchTerm);

      function fetchRegionList() {
            dispatch(fetchingData());

            let get_regions_url = `${API_URL.Region}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`;

            ApiRequest.get(get_regions_url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;

                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  console.error(err);
                  dispatch(fetchDataFail(err.message));
            });
      }

      async function getRegionList() {
            try {
                const url = API_URL.Region;
                const res = await ApiRequest.get(url);
                return res.data.payload.data;
            }
            catch(err) {
                console.error(err);
                return [];
            }
        }
    

      async function updateRegion({ id, mm_name, en_name, mimu_code }) {
            dispatch(dialogActionStarted())
            let update_region_url = API_URL.Region;
            try {
                  await ApiRequest.patch(update_region_url,
                        { id, mm_name, en_name, mimu_code },
                        { headers: { 'Content-Type': 'application/json' } }
                  );
                  showNoti(`Region code ${id} is updated successfully!`, 'success');
                  dispatch(dialogActionSuccess());
                  fetchRegionList();
                  return Promise.resolve();
            }
            catch (err) {
                  console.error(err);
                  const errMsg = err.response?.data.errors?.message ?? `${err.message}. Fail to create new region!`;
                  showNoti(errMsg, 'error');
                  dispatch(dialogActionFail());
                  return Promise.reject();
            }
      }

      async function addRegion({ mm_name, en_name, mimu_code }) {
            dispatch(dialogActionStarted())
            let add_region_url = API_URL.Region;

            try {
                  let id = null;
                  await ApiRequest.post(add_region_url,
                        { mm_name, en_name, mimu_code },
                        { headers: { 'Content-Type': 'application/json' } }
                  ).then(res => id = res.data.payload.data._id)
                  showNoti('New region created successfully!', 'success');
                  dispatch(dialogActionSuccess());
                  fetchRegionList();
                  return Promise.resolve(id);
            }
            catch (err) {
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  dispatch(dialogActionFail("Creating region failed"));
                  return Promise.reject(errors);
            }
      }

      async function editRegion({id, mm_name, en_name, mimu_code}) {
            dispatch(dialogActionStarted())
            let edit_region_url = API_URL.Region + "/" + id;

            try {  
                  await ApiRequest.post(edit_region_url,
                        { id, mm_name, en_name, mimu_code },
                        { headers: { 'Content-Type': 'application/json' } }
                  ).then(res => id = res.data?.payload?.data?._id)
                  showNoti('Region edited successfully!', 'success');
                  dispatch(dialogActionSuccess());
                  fetchRegionList();
                  return Promise.resolve(id);
            }
            catch (err) {
                  console.log(err)
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  dispatch(dialogActionFail("Editing region failed"));
                  return Promise.reject(errors);
            }
      }

      async function downloadRegionFileUploadTemplate(columns=[]) {
            const workBook = XLSX.utils.book_new();
    
            const dataSheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
            XLSX.utils.book_append_sheet(workBook, dataSheet, 'data');
            XLSX.writeFile(workBook, 'regionUploadTemplate.xlsx');
      }

      async function createMultipleRegions(file) {
            const url = `${API_URL.Region}/batch`;
            const data = new FormData();
            data.append('file', file);

            try {
                  dispatch(submittingData());
                  const res = await ApiRequest.post(url, data);
                  dispatch(submitDataSuccess());
                  return res.data;
              }
              catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  dispatch(submitDataFail());
                  return Promise.reject(errMsg);
              }
        }

      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeSearchTerm(searchTerm) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(searchTerm));
      }

      function refreshDialogState(){
            dispatch(reloadDialogState());
      }

      return {
            fetchRegionList,
            getRegionList,
            addRegion,
            updateRegion,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState,
            editRegion,
            downloadRegionFileUploadTemplate,
            createMultipleRegions
      }
}

export default useRegionActions;