import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';

const TimePickerFiled = (props) => {
    const { name, control, errmsg, placeholder, label, onChange } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
                name={name}
                control={control}
                defaultValue={null}
                render={({ field }) => (
                    <TimePicker
                        {...field}
                        label={label}
                        value={dayjs(field?.value) ?? undefined}
                        format="hh:mm A"
                        slotProps={{
                            textField: {
                                helperText: errmsg ?? '',
                                error: Boolean(errmsg),
                                size: 'small',
                                placeholder: placeholder
                            }
                        }}
                        onChange={(v) => {
                            onChange ? 
                            field.onChange(onChange(v)) 
                            : 
                            field.onChange(v)
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default TimePickerFiled;