import { useSelector } from "react-redux";
import useMetaDataActions from "./_actions";

export const useMetaData = () => {
      const {
            fetchMetaData,
            refreshLoaction,
            refreshMetaData,
            getLocByFilter,
            fetchDonors
      } = useMetaDataActions();

      const farmTypes = useSelector(state => state.meta_data.farm_types);
      const cropTypes = useSelector(state => state.meta_data.crop_types);
      const memberPositions = useSelector(state => state.meta_data.member_positions);
      const donors = useSelector(state => state.meta_data.donors);
      const articleCategories = useSelector(state => state.meta_data.article_categories);
      const clusters = useSelector(state => state.meta_data.clusters);

      const regionMeta = useSelector(state => state.meta_data.location.region);
      const townshipMeta = useSelector(state => state.meta_data.location.township);
      const villageTractMeta = useSelector(state => state.meta_data.location.villageTract);
      const villageMeta = useSelector(state => state.meta_data.location.village);
      const officeMeta = useSelector(state => state.meta_data.location.office);

      return {
            farmTypes,
            cropTypes,
            memberPositions,
            donors,
            articleCategories,
            clusters,
            regionMeta,
            townshipMeta,
            villageMeta,
            villageTractMeta,
            officeMeta,
            // Actions 
            fetchMetaData,
            refreshLoaction,
            refreshMetaData,
            getLocByFilter,
            fetchDonors
      }
}

export { default as metaDataReducer } from './_reducers';