import React from "react";
import IconButton from '@mui/material/IconButton';
import { Box, Tooltip } from "@mui/material";
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { useThemeSetting } from "../../features";

const ChangeThemeButton = () => {
      const { changeMode, mode } = useThemeSetting();
      return (
            <Box sx={{ height: '100%' }}>
                  <Tooltip title={"Change to " + (mode === 'light' ? 'dark mode' : 'light mode')}>
                        <IconButton color="inherit" onClick={changeMode}>
                              {mode === 'light'
                                    ? <WbSunnyIcon />
                                    : <ModeNightIcon />}
                        </IconButton>
                  </Tooltip>
            </Box>
      )
};

export default ChangeThemeButton;
