
export function getTotalAmountFromArray(arr = []) {
    let total = 0;
    if(Array.isArray(arr)) {
        arr?.map(item => total += item?.amount);
    }
    return total;
}

export function calculateLedgerMainLevel(ledger = []) {
    let totalAmount = 0;
    let new_arr = [];

    if (ledger.length > 0) {
        for (let i = 0; i < ledger.length; i++) {
            let credit = 0;
            let debit = 0;
            let balance = 0;

            let item = ledger[i];
            let sub_accounts = item.sub_accounts;
            if(sub_accounts.length > 0) {
                for (let j = 0; j < sub_accounts.length; j++) {
                    let sub_item = sub_accounts[j];
                    if(sub_item.sub_accounts && sub_item.sub_accounts.length > 0) {
                        sub_item.sub_accounts.map(sub => {
                            credit = sub.credit + credit;
                            debit = sub.debit + debit
                            balance = sub.balance + balance;
                        })
                    } else {
                        credit = sub_item.credit + credit;
                        debit = sub_item.debit + debit
                        balance = sub_item.balance + balance;
                    }
                }
                totalAmount += item.balance;
                new_arr.push({
                    ...item,
                    credit: credit,
                    debit: debit,
                    balance: balance
                })
            } else {
                
                totalAmount += item.balance;
                new_arr.push(item); 
            }
        }
    }

    return { ledger: new_arr, totalAmount: totalAmount }
}