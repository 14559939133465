import Axios from 'axios';
import store from '../store';
import { setAuth } from '../features/user/_reducers';
import tokenConfig from '../configs/_token';
import { API_BASE_URL } from "../configs/_api";

const ApiRequest = Axios.create({
    headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data', 
        'Accept': 'application/json',
    },
    baseURL: API_BASE_URL
});

async function requestNewTokens() {
    const accessToken = localStorage.getItem(tokenConfig.ACCESS_TOKEN_NAME);
    const refreshToken = localStorage.getItem(tokenConfig.REFRESH_TOKEN_NAME);
    const apiRes = await ApiRequest.post('v1/auth/refresh', {access_token: accessToken, refresh_token: refreshToken});
    const { access_token, refresh_token } = apiRes.data.payload.data;
    return { access_token, refresh_token };
}

ApiRequest.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        let res = err.response;
        try {
            const isTokenExpired = res.data?.errors?.data?.is_token_expired;
            if(!err.config?.url.includes('login') && res?.status == 401 && isTokenExpired) {
                try {
                    const { access_token, refresh_token } = await requestNewTokens();
                    localStorage.setItem(tokenConfig.ACCESS_TOKEN_NAME, access_token);
                    localStorage.setItem(tokenConfig.REFRESH_TOKEN_NAME, refresh_token);   

                    err.config.headers = {
                    ...err.config.headers,
                    authorization: `Bearer ${access_token}`,
                    };

                    return Axios(err.config);
                }
                catch(err) {
                    store.dispatch(setAuth(false));
                    throw err;
                }
            }
            else {
                throw err;
            }
        }
        catch(err) {
            return Promise.reject(err);
        }
    }
);

ApiRequest.interceptors.request.use(
    req => {
        const token = localStorage.getItem(tokenConfig.ACCESS_TOKEN_NAME);
        req.headers.Authorization = `Bearer ${token}`;
        return req;
    }
)

export default ApiRequest;