import { useSelector } from 'react-redux';
import useLogActions from './_actions';

export const useLog = () => {
    const {
        fetchLogList,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm
    } = useLogActions();

    const logs = useSelector(state => state.log.data);
    const currentPage = useSelector(state => state.log.currentPage);
    const totalRows = useSelector(state => state.log.totalRows);
    const rowsPerPage = useSelector(state => state.log.rowsPerPage);
    const isLoading = useSelector(state => state.log.isLoading);
    const isError = useSelector(state => state.log.isError);
    const errorMessage = useSelector(state => state.log.errorMessage);
    const searchTerm = useSelector(state => state.log.searchTerm);

    return {
        logs,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,

        fetchLogList,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm
    }
}

export { default as logReducer } from './_reducers';