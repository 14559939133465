import { useSelector } from "react-redux";
import useRolesActions from "./_actions";

export const useRoles = () => {
    const {
        fetchRolesList,
        getSchemaForCreate,
        addRole,
        updateRole,
        changeCurrentPage, 
        changeRowsPerPage,
        changeSearchTerm
    } = useRolesActions();

    const portalUserRoles = useSelector(state => state.portalUserRole.data);
    const schema = useSelector(state => state.portalUserRole.schema);
    const currentPage = useSelector(state => state.portalUserRole.currentPage);
    const totalRows = useSelector(state => state.portalUserRole.totalRows);
    const rowsPerPage = useSelector(state => state.portalUserRole.rowsPerPage);
    const isLoading = useSelector(state => state.portalUserRole.isLoading);
    const isError = useSelector(state => state.portalUserRole.isError);
    const searchTerm = useSelector(state => state.portalUserRole.searchTerm);

    return {
        //state
        portalUserRoles,
        schema,
        isLoading,
        isError,
        currentPage,
        rowsPerPage,
        totalRows,
        searchTerm,

        //actions
        fetchRolesList,
        getSchemaForCreate,
        addRole,
        updateRole,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm
    }
}

export {default as rolesReducer} from './_reducers';