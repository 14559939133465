import { createSlice } from "@reduxjs/toolkit";

const committeeSlice = createSlice({
    name: 'committee',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        searchTerm: '',
        filters: {
            status: 'active',
            region_code: '',
            township_code: '',
            village_tract_code: '',
            village_code: '',
        },
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        },

        isLoading: false,
        isError: false,
        errorMessage: '',

        settlement:{
            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,
            searchTerm: '',
            filters: {
                committee_id: '',
            },

            isLoading: false,
            isError: false,
            errorMessage: '',
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
      }
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.filters.status = action.payload
        },
        setRegionCodeFilter: (state, action) => {
            state.filters.region_code = action.payload;
        },
        setTownshipCodeFilter: (state, action) => {
            state.filters.township_code = action.payload;
        },
        setVillageTractCodeFilter: (state, action) => {
            state.filters.village_tract_code = action.payload;
        },
        setVillageCodeFilter: (state, action) => {
            state.filters.village_code = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess: (state, action) => {
            action.payload?.data && (state.data = action.payload.data);
            action.payload?.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: ''}
        },

        // fundAllocation

        setSettlementData: (state, action) => {
            state.settlement.data = action.payload;
      },

      fetchingSettlementData: (state) => {
            state.settlement.isLoading = true;
            state.settlement.isError = false;
      },
      fetchSettlementDataSuccess: (state, action) => {
            action.payload.data && (state.settlement.data = action.payload.data);
            action.payload.total_items && (state.settlement.totalRows = action.payload.total_items);
            state.settlement.isLoading = false;
            state.settlement.isError = false;
      },
      fetchSettlementDataFail: (state, action) => {
            state.settlement.isLoading = false;
            state.settlement.isError = true;
            state.settlement.errorMessage = action.payload;
      },

      setSettlementCurrentPage: (state, action) => {
            state.settlement.currentPage = action.payload;
      },
      setSettlementRowsPerPage: (state, action) => {
            state.settlement.rowsPerPage = action.payload;
      },
      setSettlementSearchTerm: (state, action) => {
            state.settlement.searchTerm = action.payload;
      },
      setSettlementCommitteeFilter: (state, action) => {
        state.settlement.filters.committee_id = action.payload;
     },

//      setBranchFilter: (state, action) => {
//         state.allocation.filters.branch_id = action.payload;
//   },
    }
});

export const {
    setData,
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    setSearchTerm,
    setStatusFilter,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageTractCodeFilter,
    setVillageCodeFilter,
    setPositionFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState,

    setSettlementData,
    fetchingSettlementData,
    fetchSettlementDataSuccess,
    fetchSettlementDataFail,
    setSettlementCurrentPage,
    setSettlementRowsPerPage,
    setSettlementSearchTerm,
    setSettlementCommitteeFilter
} = committeeSlice.actions;

export default committeeSlice.reducer;