import { useSelector } from "react-redux";
import useClusterActions from "./_actions";

export const useCluster = () => {
    const {
        fetchClusterList,
        getClusterDetail,
        createNewCluster,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
    } = useClusterActions();

    const clusters = useSelector(state => state.cluster.data);
    const currentPage = useSelector(state => state.cluster.currentPage);
    const totalRows = useSelector(state => state.cluster.totalRows);
    const rowsPerPage = useSelector(state => state.cluster.rowsPerPage);
    const isLoading = useSelector(state => state.cluster.isLoading);
    const isError = useSelector(state => state.cluster.isError);
    const errorMessage = useSelector(state => state.cluster.errorMessage);
    const searchTerm = useSelector(state => state.cluster.searchTerm);
    const apiAction = useSelector(state => state.cluster.action);

    return {
        clusters,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        apiAction,

        fetchClusterList,
        getClusterDetail,
        createNewCluster,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage
    }
}

export { default as clusterReducer } from './_reducers'