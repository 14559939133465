import { createSlice } from "@reduxjs/toolkit";

const logSlice = createSlice({
    name: 'log',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,
        isLoading: false,
        isError: false,
        errorMessage: '',
        searchTerm: ''
    },
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            action.payload?.data && (state.data = action.payload.data);
            action.payload?.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        }
    }
});

export const {
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    setSearchTerm
} = logSlice.actions;

export default logSlice.reducer;