import { createSlice } from "@reduxjs/toolkit";

const appVersionSlice = createSlice({
    name: 'app_version',
    initialState: {
        data: [],
        isLoading: false,
        isError: false,
        errorMessage: '',
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },

        fetchDataSuccess: (state, action) => {
            action.payload && (state.data = action.payload);
            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
    }
});

export const {
    setData,
    fetchingData,
    fetchDataFail,
    fetchDataSuccess,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState
} = appVersionSlice.actions;

export default appVersionSlice.reducer;