import { Autocomplete, Box, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMetaData } from '../../features';

const LocationSelector = (props) => {
      const option = props.option ? props.option : 1
      const { getLocByFilter } = useMetaData();

      const [val, setVal] = useState([]);
      const [length, setLength] = useState(0)
      const [opt, setOpt] = useState([]);
      const [disable, setDisable] = useState(false);
      const [placeholderTxt, setPlaceholderTxt] = useState();

      async function getLocMetadata(rig, tsp, vt){
            let { regions, townships, village_tracts, villages } = await getLocByFilter(rig, tsp, vt);
            if(villages){
                  setOpt(villages);
            }else if(village_tracts){
                  setOpt(village_tracts);
            } else if(townships){
                  setOpt(townships);
            } else if(regions){
                  setOpt(regions);
            }
      }

      function handleChange(e, v) {
            if (length > v.length) {
                  let x = 0;
                  const myArray = val.filter((el) => !v.includes(el));
                  val.map((val, i) => val.id == myArray[0]?.id && (x = i))
                  const sliced = val.slice(0, x)
                  setVal(sliced)
                  setLength(sliced.length)
                  returnSelected(sliced)
            }
            else if (option == v.length - 1) {
                  let arr = [];
                  option !== 1 && (arr = val.slice(0, -1))
                  arr.push(v[v.length - 1])
                  setVal(arr);
                  setLength(arr.length)
                  returnSelected(arr)
            }
            else {
                  setVal(v);
                  setLength(v.length)
                  returnSelected(v)
            }

      }

      function returnSelected(v) {
            if (v.length == 0) {
                  props.selectedlocation(createData(null, null, null, null))
                  getLocMetadata();
            }
            else if (v.length == 1) {
                  props.selectedlocation(createData(v[0], null, null, null))
                  getLocMetadata(v[0].id);
            }
            else if (v.length == 2) {
                  props.selectedlocation(createData(v[0], v[1], null, null))
                  getLocMetadata(v[0].id, v[1].id);
            }
            else if (v.length == 3) {
                  props.selectedlocation(createData(v[0], v[1], v[2], null))
                  getLocMetadata(v[0].id, v[1].id, v[2].id);
            }
            else if (v.length == 4) {
                  props.selectedlocation(createData(v[0], v[1], v[2], v[3]))
                  getLocMetadata(v[0].id, v[1].id, v[2].id);
            }
      }

      function createData(r, t, v, g) {
            const _r = r ? { id: r.id, label: r.label, mimu_code: r.mimu_code } : null;
            const _t = t ? { id: t.id, label: t.label, mimu_code: t.mimu_code } : null;
            const _v = v ? { id: v.id, label: v.label, mimu_code: v.mimu_code } : null;
            const _g = g ? { id: g.id, label: g.label, mimu_code: g.mimu_code } : null;
            return { region: _r, township: _t, villageTract: _v, village: _g }
      }

      useEffect(() => {
            if (option == 4) {
                  if (length == 0) {
                        setDisable(true)
                        // setOpt(regionMeta)
                        setPlaceholderTxt('👉 Select Region')
                  } else if (length == 1) {
                        setDisable(true)
                        // setOpt(townshipMeta.filter((i) => i.mimu_code.includes(val[0].mimu_code)))
                        setPlaceholderTxt('👉 Select Township')
                  } else if (length == 2) {
                        setDisable(true)
                        // setOpt(villageTractMeta.filter((i) => i.mimu_code.includes(val[1].mimu_code)))
                        setPlaceholderTxt('👉 Select Village Tract')
                  } else if (length == 3) {
                        setDisable(false)
                        // setOpt(villageMeta.filter((i) => i.village_tractid == val[2].id))
                        setPlaceholderTxt('👉 Select Village')
                  } else if (length == 4) {
                        // setOpt(villageMeta.filter((i) => i.village_tractid == val[2].id))
                        setPlaceholderTxt('✅ OK')
                  }
            }
            if (option == 3) {
                  if (length == 0) {
                        setDisable(true)
                        // setOpt(regionMeta)
                        setPlaceholderTxt('👉 Select Region')
                  } else if (length == 1) {
                        setDisable(true)
                        // setOpt(townshipMeta.filter((i) => i.mimu_code.includes(val[0].mimu_code)))
                        setPlaceholderTxt('👉 Select Township')
                  } else if (length == 2) {
                        setDisable(false)
                        // setOpt(villageTractMeta.filter((i) => i.mimu_code.includes(val[1].mimu_code)))
                        setPlaceholderTxt('👉 Select Village Tract')
                  } else if (length == 3) {
                        // setOpt(villageTractMeta.filter((i) => i.mimu_code.includes(val[1].mimu_code)))
                        setPlaceholderTxt('✅ OK')
                  }
            }
            if (option == 2) {
                  if (length == 0) {
                        setDisable(true)
                        // setOpt(regionMeta)
                        setPlaceholderTxt('👉 Select region')
                  } else if (length == 1) {
                        setDisable(false)
                        // setOpt(townshipMeta.filter((i) => i.mimu_code.includes(val[0].mimu_code)))
                        setPlaceholderTxt('👉 Select Township')
                  } else if (length == 2) {
                        // setOpt(townshipMeta.filter((i) => i.mimu_code.includes(val[0].mimu_code)))
                        setPlaceholderTxt('✅')
                  }
            }
            if (option == 1) {
                  if (length == 0) {
                        setDisable(false)
                        // setOpt(regionMeta)
                        setPlaceholderTxt('👉 Select Region')
                  } else if (length == 1) {
                        // setOpt(regionMeta)
                        setPlaceholderTxt('✅')
                  }
            }
      }, [length]);

      useEffect(() => {
            async function getInitialData(){
                  let { regions } = await getLocByFilter();
                  // setRegionMeta(regions);
                  setOpt(regions);
            }
            if(length === 0){
                  getInitialData();
            }
      },[]);

      function reset() {
            setVal([]);
            setLength(0);
            setOpt([]);
            setPlaceholderTxt('');
            returnSelected([]);
      }



      useEffect(() => {
            reset();
      }, [props.reset]);

      return (
            <Box sx={props.sx}>
                  <Tooltip title={placeholderTxt} arrow placement="top" followCursor>
                        <Autocomplete
                              fullWidth={props.fullWidth ? true : false}
                              multiple
                              id="location-selector"
                              options={opt}
                              getOptionLabel={(option) => option.label}
                              value={val}
                              sx={{ minWidth: 200 }}
                              autoHighlight
                              filterSelectedOptions
                              size='small'
                              clearOnBlur={true}
                              onChange={handleChange}
                              disableCloseOnSelect= {disable}
                              renderInput={(params) => (
                                    <TextField
                                          {...params}
                                          label={props.label ?? "Filter by Location"}
                                          placeholder={placeholderTxt}
                                          error={Boolean(props.errMsg ?? '')}
                                          helperText={props.errMsg ?? ''}
                                    />
                              )}
                        />
                  </Tooltip>
            </Box>
      )
}

export default LocationSelector; 