import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';

/**
 * @param {Array} data
 * @param {Object} control
 * @param {String} name
 * @param {String} label
 * @param {String} errorMessage
 */
const RadioButtonGroup = (props) => {
    return (
        <>
            <Controller
                name={props.name}
                control={props.control}
                render={({ field }) => (
                    <FormControl FormControl >
                        {
                            props?.label ? <FormLabel id="demo-row-radio-buttons-group-label">{props?.label}</FormLabel> : null
                        }
                        <RadioGroup
                            {...field}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={props.value}
                            onChange={(e) => { field.onChange(e); props.onChange && props.onChange(e.target.value) }}
                        >
                            {
                                props.data && props.data.map((item, i) => {
                                    return (
                                        <FormControlLabel key={i} value={item} control={<Radio />} label={item} disabled={props?.disabled}/>
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                )}
            />
            {props.errorMessage && <Typography sx={{fontSize: '12px', color: '#d32f2f'}}>{props.errorMessage}</Typography>}
        </>

    )
};

export default RadioButtonGroup;