import { useSelector } from "react-redux";
import useUserActions  from './_actions';

export const useUser = () => {
    const {
        logIn,
        logOutUser,
        changePassword,
        sendMail,
        resetPassword,
        verifyEmail,
        changeAuth,
        hasPermission,
        hasFeRoute,
        isHeadOfficeUser,
    } = useUserActions();

    const userData = useSelector(state => state.user.data);
    const userRole = useSelector(state => state.user.data.role);
    const isAuth = useSelector(state => state.user.isAuth);
    const permission_schema = useSelector(state => state.user.permission_schema);
    const feRoutes = useSelector(state => state.user.feRoutes);
    const isLoading = useSelector(state => state.user.isLoading);
    const isSuccess = useSelector(state => state.user.isSuccess);
    const isError = useSelector(state => state.user.isError);
    const errorMsg = useSelector(state => state.user.errorMsg);
    const isFirstLogin = useSelector(state => state.user.data.is_first_login);

    return {
        //state
        userData,
        userRole,
        isAuth,
        permission_schema,
        feRoutes,
        isLoading,
        isSuccess,
        isError,
        errorMsg,
        isFirstLogin,

        //actions
        logIn,
        logOutUser,
        changePassword,
        sendMail,
        resetPassword,
        verifyEmail,
        changeAuth,
        hasPermission,
        hasFeRoute,
        isHeadOfficeUser
    }
}

export {default as userReducer} from './_reducers';