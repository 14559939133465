import { useSelector } from "react-redux";
import useStandardSchemaActions from './_actions';

export const useStandardSchema = () => {
      const {
            fetchStandardSchema,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm
      } = useStandardSchemaActions();

      const standardSchema = useSelector(state => state.standard_schema.data);
      const currentPage = useSelector(state => state.standard_schema.currentPage);
      const totalRows = useSelector(state => state.standard_schema.totalRows);
      const rowsPerPage = useSelector(state => state.standard_schema.rowsPerPage);
      const isLoading = useSelector(state => state.standard_schema.isLoading);
      const isError = useSelector(state => state.standard_schema.isError);
      const errorMsg = useSelector(state => state.standard_schema.errorMsg);

      return {
            //state
            standardSchema,
            isLoading,
            isError,
            currentPage,
            rowsPerPage,
            totalRows,
            errorMsg,
            //actions
            fetchStandardSchema,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm
      }
}

export { default as standardSchemaReducer } from './_reducers';