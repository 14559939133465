import { useSelector } from "react-redux";
import useEcommerceOrderActions from "./_actions";

export const useEcommerceOrder = () => {
  const {
    fetchEcommerceOrderList,
    getEcommerceOrderDetail,
    updateEcommerceOrder,
    confirmEcommerceOrder,
    cancelEcommerceOrder,
    submitEcommerceOrder,
    readyToDeliverEcommerceOrder,
    deliveringEcommerceOrder,
    completeEcommerceOrder,

    getNewOrderCount,
    
    changeSearchTerm,
    changeStartDate,
    changeEndDate,
    changeRegionCodeFilter,
    changeRowsPerPage,
    changeCurrentPage,
    changeStatusFilter,
    refreshDialogState
  } = useEcommerceOrderActions();

  const ecommerceOrder = useSelector((state) => state.ecommerceOrder.data);
  const currentPage = useSelector((state) => state.ecommerceOrder.currentPage);
  const totalRows = useSelector((state) => state.ecommerceOrder.totalRows);
  const rowsPerPage = useSelector((state) => state.ecommerceOrder.rowsPerPage);
  const searchTerm = useSelector((state) => state.ecommerceOrder.searchTerm);
  const startDate = useSelector((state) => state.ecommerceOrder.filters?.startDate);
  const endDate = useSelector((state) => state.ecommerceOrder.filters?.endDate);
  const region_id = useSelector((state) => state.ecommerceOrder.filters?.region_id);
  const isError = useSelector((state) => state.ecommerceOrder.isError);
  const isLoading = useSelector((state) => state.ecommerceOrder.isLoading);
  const errorMessage = useSelector((state) => state.ecommerceOrder.errorMessage);
  const apiAction = useSelector(state => state.ecommerceOrder.action);

  const statusFilter = useSelector(state => state.ecommerceOrder.filters.status);

  const newOrderCount = useSelector(state => state.ecommerceOrder.newOrder);

  return {
    // state
    ecommerceOrder,
    currentPage,
    totalRows,
    rowsPerPage,
    searchTerm,
    startDate,
    endDate,
    region_id,
    isError,
    isLoading,
    apiAction,
    errorMessage,
    statusFilter,

    newOrderCount,

    // actions
    fetchEcommerceOrderList,
    getEcommerceOrderDetail,
    updateEcommerceOrder,
    confirmEcommerceOrder,
    cancelEcommerceOrder,
    submitEcommerceOrder,
    readyToDeliverEcommerceOrder,
    deliveringEcommerceOrder,
    completeEcommerceOrder,

    getNewOrderCount,

    changeSearchTerm,
    changeStartDate,
    changeEndDate,
    changeRegionCodeFilter,
    changeCurrentPage,
    changeRowsPerPage,
    changeStatusFilter,
    refreshDialogState
  };
};

export { default as ecommerceOrderReducer } from "./_reducers";