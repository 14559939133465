import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, createMigrate } from "redux-persist";
//import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import {
  layoutSettingReducer,
  themeSettingReducer,
  regionReducer,
  townshipReducer,
  officeReducer,
  villageTractReducer,
  villageReducer,
  committeeReducer,
  standardSchemaReducer,
  userReducer,
  rolesReducer,
  portalUserReducer,
  shopReducer,
  memberReducer,
  metaDataReducer,
  depositReducers,
  appVersionReducer,
  loanReducer,
  loanDetailReducer,
  newRowReducer,
  settlementReducer,
  invoiceReducer,
  relatedRevenueReducer,
  revenueReducer,
  revenueTransfersReducer,
  dashboardReducer,
  fundReceivingReducers,
  fundReceivingInvoiceReducers,
  fundAllocationReducers,
  fundingHandoverReducers,
  logReducer,
  staticPageReducer,
  breadCrumbReducer,
  productCategoryReducer,
  productCatalogReducer,
  donorReducer,
  articleReducer,
  maintenanceReducer,
  ecommerceOrderReducer,
  clusterReducer
} from "./features";
const migrations = {
  0: (state) => {
    // migration clear out device state
    console.log("Previous State:", ...state);
    return {
      ...state,
      device: undefined,
    };
  },
  1: (state) => {
    // migration to keep only device state
    console.log("Migrated State:", state);
    return {
      device: state.device,
    };
  },
};

const persistConfig = {
  key: "vshop",
  storage,
  version: 3,
  migrate: createMigrate(migrations, { debug: true }),
  // stateReconciler: hardSet,
  whitelist: ['user', 'theme', 'layout', 'breadCrumb', 'meta_data'] // only navigation will be persisted
};

const reducers = combineReducers({
  theme: themeSettingReducer,
  layout: layoutSettingReducer,
  region: regionReducer,
  township: townshipReducer,
  office: officeReducer,
  village_tract: villageTractReducer,
  village: villageReducer,
  committee: committeeReducer,
  shop: shopReducer,
  deposit: depositReducers,
  standard_schema: standardSchemaReducer,
  user: userReducer,
  portalUserRole: rolesReducer,
  portal_user: portalUserReducer,
  member: memberReducer,
  meta_data: metaDataReducer,
  app_version: appVersionReducer,
  loan: loanReducer,
  loan_detail: loanDetailReducer,
  newRows: newRowReducer,
  settlement: settlementReducer,
  invoice: invoiceReducer,
  related_revenue: relatedRevenueReducer,
  revenue: revenueReducer,
  revenueTransfers: revenueTransfersReducer,
  dashboard: dashboardReducer,
  fundReceiving: fundReceivingReducers,
  fundReceivingInvoice: fundReceivingInvoiceReducers,
  fundAllocation: fundAllocationReducers,
  fundingHandover: fundingHandoverReducers,
  log: logReducer,
  static_page: staticPageReducer,
  breadCrumb: breadCrumbReducer,
  productCategory: productCategoryReducer,
  productCatalog: productCatalogReducer,
  donor: donorReducer,
  article: articleReducer,
  maintenance: maintenanceReducer,
  ecommerceOrder: ecommerceOrderReducer,
  cluster: clusterReducer
});

const rootReducer = (state, action) => {
  if ((action.type === "user/setAuth") & (action.payload == false)) {
    window.localStorage.removeItem("vshop");
    console.log("LOGOUT..");

    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// localStorage.clear();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
