import { createSlice } from "@reduxjs/toolkit";

const revenueTransfersSlice = createSlice({
      name: 'revenueTransfers',
      initialState: {
            data: [],
            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,
            searchTerm: '',
            filters: {
                  status: '',
                  start_date: '',
                  end_date: '',
                  office_id: ''
            },
            isLoading: false,
            isError: false,
            errorMessage: '',
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            resetData: (state) => {
                  state.data = [];
                  state.totalRows = 0;
            },
            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setTotalRows: (state, action) => {
                  state.totalRows = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            setStartDate: (state, action) => {
                  state.filters.start_date = action.payload;
            },
            setEndDate: (state, action) => {
                  state.filters.end_date = action.payload;
            },
            setStatusFilter: (state, action) => {
                  state.filters.status = action.payload;
              },
            setOfficeFilter: (state, action) => {
                  state.filters.office_id = action.payload;
            },
            fetchingData: (state) => {
                  state.isLoading = true;
                  state.isError = false;
            },
            fetchDataFail: (state, action) => {
                  state.isLoading = false;
                  state.isError = true;
                  state.errorMessage = action.payload;
            },
            fetchDataSuccess: (state, action) => {
                  action?.payload?.data && (state.data = action.payload.data);
                  action?.payload?.total_items && (state.totalRows = action.payload.total_items);
                  state.isLoading = false;
                  state.isError = false;
            },
            submittingData: (state) => {
                  state.action.isLoading = true;
                  state.action.isError = false;
            },
            submitDataSuccess: (state) => {
                  state.action.isLoading = false;
                  state.action.isError = false;
            },
            submitDataFail: (state, action) => {
                  state.action.isLoading = false;
                  state.action.isError = true;
                  state.action.errorMessage = action.payload;
            },
            reloadDialogState: (state) => {
                  state.action = { isLoading: false, isError: false, errorMessage: '' }
            }
      }
});

export const {

      // Fetch List View
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Dialog Actions
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setOfficeFilter,
      setData,
      resetData,
      setCurrentPage,
      setRowsPerPage,
      setTotalRows,
      setSearchTerm,
      setStartDate,
      setEndDate,
      setStatusFilter,
} = revenueTransfersSlice.actions;

export default revenueTransfersSlice.reducer;