import React from 'react';
import { Box } from '@mui/system';
import { Divider, Drawer, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
 import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { LeftNavigation, TopNavigation } from '../navigation';
import { useLayoutSetting, useUser } from '../features';
import { Outlet, useLocation } from 'react-router-dom';
import NoPermission from '../pages/NoPermissionPage';

const root_main_layout = (marginLeft) => {
      return (
            {
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 0,
                  flexGrow: 1,
                  height: '100vh',
                  transition: '0.5s',
                  backgroundColor: '#F6F7F9',
                  overflow: 'hidden',
                  marginLeft: marginLeft,
                  // overflowY: 'hidden',
            }
      )
};
const root_right_layout = {
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'background.default',
      height: '100%'
}
const root_left_layout_drawer = (matches, size) => {
      return ({
            display: 'flex',
            flexDirection: 'column',
            alignItem: 'flex-start',
            height: '100%',
            transition: '0.5s',
            overflow: 'hidden',
            width: matches ? '200px' : size
      })
};
const root_right_layout_content_session = {
      bgcolor: 'background.default',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'calc(10px + 2vmin)',
      color: 'text.primary',
      overflow: 'auto',
};

const RootLayout = () => {
      const {
            //Actions
            setAsMobileSize,
            isOpenDrawer,
            // Data
            variant,
            isCollapse,
            openDrawer,
            width, } = useLayoutSetting();

      const matches = useMediaQuery('(max-width:600px)');
      const getMargin = () => matches ? 0 : width;

      const location = useLocation();
      const path = location.pathname;
      const { hasFeRoute } = useUser();

      React.useEffect(() => {
            setAsMobileSize(matches);
            // eslint-disable-next-line
      }, [matches])


      function drawerToggle(event) {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                  return;
            }

            if (isOpenDrawer) {
                  openDrawer(false)
            }
            else {
                  openDrawer(true)
            }
      }

      return (
            <Box id={'root_main_layout'} sx={root_main_layout(getMargin())}>
                  {/* Left Layout - Drawer*/}
                  <Drawer id='root_left_layout' variant={variant} anchor="left" onClose={() => openDrawer(false)} open={isCollapse} >
                        <Box sx={root_left_layout_drawer(matches, width)}  >

                              {/* LOGO */}
                              <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'center', mt: 2, mb: 2 }}>
                                    <img src="/images/logo.svg" height={'35'} alt="left-navi-logo" />
                              </Box>
                              <Divider />

                              {/* Menu Context */}
                              <LeftNavigation />
                              <Divider />

                              {/* Collaspe Button Aress */}
                              <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'center', bgcolor: 'secondary', p: '4px 0 4px 0' }} onClick={drawerToggle}>
                                    {width === '64px'
                                          ? <IconButton sx={{ ml: 0 }} color="inherit"> <KeyboardDoubleArrowRightIcon /> </IconButton>
                                          : <IconButton sx={{ ml: 0 }} color="inherit"> <KeyboardDoubleArrowLeftIcon /> </IconButton>}
                              </Box>

                        </Box>
                  </Drawer >

                  {/* Right Layout - Content */}
                  <Box id='root_right_layout' sx={root_right_layout}>
                        {/* Top Navigation */}
                        <TopNavigation />

                        {/* Content */}
                        <Box id='root_right_layout_content_session' sx={root_right_layout_content_session}>
                              {hasFeRoute(path) ? <Outlet/> : <NoPermission />}
                        </Box>
                  </Box>
            </Box >
      )
}


export default RootLayout;
