import useDataExportActions from "./_actions"

export const useDataExport = () => {
    const {
        excelFormat, 
        moduleParam,

        getFilteredData,
        downloadData
    } = useDataExportActions();

    return {
        excelFormat, 
        moduleParam,

        getFilteredData,
        downloadData
    }
}