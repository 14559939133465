import React from 'react';
import { DateRangePicker } from 'rsuite';
import './style.css'

const TYDateRangePicker = (props) => {
      return (
            <DateRangePicker {...props}/>
      )
}

export default TYDateRangePicker; 