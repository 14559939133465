import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import StyledIconButton from "../Buttons/StyledIconButton";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";

/**
 * ## EditableTextBox
 * @param {String} label (Optional) Default is "Edit"
 * @param {*} defaultValue (Optional) Default value
 * @param {Event} onChangeValue Event on value change
 * @param {Boolean} isNumberOnly (Optional) Number only ?
 * @returns
 */
const EditableTextBox = (props) => {
  const [readOnly, setReadOnly] = React.useState(true);
  const inputRef = React.createRef();

  const [val, setVal] = React.useState(props.defaultValue ?? "");
  const [tempVal, setTempVal] = React.useState(props.defaultValue ?? "");

  const hideActionButtons = () => {
    setReadOnly(true);
  };

  const onClickEdit = () => {
    setReadOnly(false);
    inputRef.current.focus();
  };

  const onClickCancel = () => {
    setTempVal(val);
    hideActionButtons();
  };

  const onClickConfirm = () => {
    if (val === tempVal) return hideActionButtons();

    let valToSet = tempVal;
    if (props.isNumberOnly) {
      valToSet = String(tempVal).trim() == "" ? 0 : Number(tempVal);
    }

    props.onChangeValue && props.onChangeValue(valToSet);
    setVal(valToSet);
    setTempVal(valToSet);
    hideActionButtons();
  };

  const onInput = (e) => {
    if (!props.isNumberOnly) return;
    const pattern = /\D/g;
    e.target.value = e.target.value.replace(pattern, "");
  };

  useEffect(() => {
    setVal(props.defaultValue ?? "");
    setTempVal(props.defaultValue ?? "");
  }, [props.defaultValue]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        ...(props.sx ?? {}),
      }}
    >
      <Box sx={{ position: "relative", width: "100%" }}>
        <TextField
          sx={{ width: "100%" }}
          InputProps={{ readOnly }}
          size="small"
          value={tempVal}
          onChange={(e) => setTempVal(e.target.value)}
          onInput={onInput}
          inputRef={inputRef}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50px",
            right: 0,
            zIndex: 1,
            display: readOnly ? "none" : "flex",
            gap: "5px",
          }}
        >
          <StyledIconButton
            onClick={onClickCancel}
            sx={{ background: "rgba(200, 200, 200, 0.1)" }}
            label="Cancel"
          >
            <ClearIcon />
          </StyledIconButton>
          <StyledIconButton
            onClick={onClickConfirm}
            sx={{ background: "rgba(200, 200, 200, 0.1)" }}
            label="Confirm"
          >
            <CheckIcon />
          </StyledIconButton>
        </Box>
      </Box>
      <StyledIconButton
        label={props.label ?? "Edit"}
        onClick={onClickEdit}
        sx={{ visibility: readOnly ? "visible" : "hidden" }}
      >
        <EditIcon />
      </StyledIconButton>
    </Box>
  );
};

export default EditableTextBox;
