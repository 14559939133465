import { TextField } from '@mui/material';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

/**
 *
 * @param {String} label
 * @param {String} placeholder
 * @param {String} errMsg
 * @param {Array} inputProps
 * @returns
 */
const TextBox = (props) => {

      return (

            <Tooltip title={props?.instock} placement="top">
            <TextField
                  id={props.id}
                  label={props.label}
                  defaultValue={props.defaultValue}
                  placeholder={props.placeholder ?? ''}
                  autoComplete='off'
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(props.errMsg ?? '')}
                  helperText={props.errMsg ?? ''} 
                  {...props}
                  sx={{
                        display: props.display ? props.display : 'inline-flex',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: props.borderColor,
                            },
                            '&:hover fieldset': {
                                borderColor: props.borderColor,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: props.borderColor,
                            },
                        },
                    }}
            />
            </Tooltip>
      )
}

export default TextBox;