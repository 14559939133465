import { useDispatch } from "react-redux";

import {
      setNew,
      removeNew
} from "./_reducers";

const useNewRowsActions = () => {
      const dispatch = useDispatch();


      function addNewRow(id) {
            dispatch(setNew(id));
      }

      function removeNewRow(id) {
            dispatch(removeNew(id))
      }

      return {
            addNewRow,
            removeNewRow,
      }
}

export default useNewRowsActions;