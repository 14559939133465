import { CircularProgress, DialogContent } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

/**
 * # StyledDialogContent
 * @param {Boolean} isLoading 
 * @param {Object} sx
 * @returns 
 */
const StyledDialogContent = ({isLoading, sx={}, children}) => {
    return (
        <DialogContent sx={{...sx}}>
            {
                isLoading ? 
                    <Box sx={{width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress color="text" size={40}/>
                    </Box>
                :
                    children
            }
        </DialogContent>
    )
}

export default StyledDialogContent;