import React, { useState } from 'react';
import { LinearProgress, Typography, } from '@mui/material';
import { Box } from '@mui/system';

export default function PercentageBar({ label, point, percent }) {
      const [val, setVal] = useState(0);

      React.useEffect(() => {
            val < percent && setVal(val + 10)
      }, [val])

      return (
            <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='caption' sx={{ mb: 0 }}>{label} </Typography>
                        <Typography sx={{ fontSize: '11px', color: 'gray', mb: 0 }}>{`${point.toLocaleString()} Point | ${percent.toLocaleString()}%`}</Typography>
                  </Box>
                  <LinearProgress variant="determinate" value={val} />
            </Box>
      )
}