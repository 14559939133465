import { useDispatch } from 'react-redux';
import { API_URL } from '../../configs/_api';
import ApiRequest from '../../utils/apiRequest';
import {
      setFarmTypes,
      setCropTypes,
      setMemberPositions,
      setDonors,
      setLocation,
      setRegion,
      setTownship,
      setVillageTract,
      setVillage,
      setOffice,
      setArticleCategories,
      setClusters
} from './_reducers';

const useMetaDataActions = () => {
      const dispatch = useDispatch();

      function fetchMetaData() {
            fetchFarmTypes()
            fetchCropTypes()
            fetchMemberPositions()
            fetchDonors()
            // fetchLocations()
            fetchArticleCategories()
            fetchClusters();
      }

      function refreshLoaction() {
            //refreshLocation
            fetchLocations();
      }

      async function fetchFarmTypes() {
            try {
                  const url = `${API_URL.Member}/farm-types`;
                  const res = await ApiRequest.get(url);
                  dispatch(setFarmTypes(res.data.payload?.data ?? []));
            }
            catch (err) {
                  console.log(err);
            }
      }

      async function fetchCropTypes() {
            try {
                  const url = `${API_URL.Member}/crop-types`;
                  const res = await ApiRequest.get(url);
                  dispatch(setCropTypes(res.data.payload?.data ?? []));
            }
            catch (err) {
                  console.log(err);
            }
      }

      async function fetchDonors() {
            try {
                  const url = `${API_URL.Donor}/meta`;
                  const res = await ApiRequest.get(url);
                  dispatch(setDonors(res.data.payload?.data ?? []));
            } catch (err) {
                  console.log(err);
            }
      }

      async function fetchMemberPositions() {
            try {
                  const url = `${API_URL.Member}/positions`;
                  const res = await ApiRequest.get(url);
                  dispatch(setMemberPositions(res.data.payload?.data ?? []));
            }
            catch (err) {
                  console.log(err);
            }
      }

      async function fetchLocations() {
            try {
                  const url = `${API_URL.Locations}`;
                  const res = await ApiRequest.get(url);
                  dispatch(setLocation(res?.data ?? []));
            }
            catch (err) {
                  console.log(err);
            }
      }

      async function fetchArticleCategories() {
            try {
                  const url = `${API_URL.ArticleCategory}/fetch/meta`;
                  const res = await ApiRequest.get(url);
                  dispatch(setArticleCategories(res?.data.payload?.data ?? []));
            } catch (err) {
                  console.log(err)
            }
      }

      async function fetchClusters() {
            try {
                  const url = `${API_URL.Cluster}/meta`;
                  const res = await ApiRequest.get(url);
                  dispatch(setClusters(res?.data.payload?.data ?? []));
            } catch (error) {
                  console.log(error);
            }
      }


      async function refreshLocationDataWithFlag(flag) {
            try {
                  const url = `${API_URL.Locations}/fetch_by_flag/${flag}`;
                  const res = await ApiRequest.get(url);
                  return res?.data ?? [];
            } catch (err) {
                  console.log(err);
            }
      }

      async function refreshMetaData(metaType = []) {
            try {
                  for (var i = 0; i < metaType.length; i++) {
                        let flag = metaType[i];

                        switch (flag) {
                              case 'region':
                                    {
                                          let data = await refreshLocationDataWithFlag(flag);
                                          dispatch(setRegion(data));
                                    }
                                    break;
                              case 'township':
                                    {
                                          let data = await refreshLocationDataWithFlag(flag);
                                          dispatch(setTownship(data));
                                    }
                                    break;
                              case 'village_tract':
                                    {
                                          let data = await refreshLocationDataWithFlag(flag);
                                          dispatch(setVillageTract(data));
                                    }
                                    break;
                              case 'village':
                                    {
                                          let data = await refreshLocationDataWithFlag(flag);
                                          dispatch(setVillage(data));
                                    }
                                    break;
                              case 'office':
                                    {
                                          let data = await refreshLocationDataWithFlag(flag);
                                          dispatch(setOffice(data));
                                    }
                                    break;
                              case 'donor':
                                    await fetchDonors();
                                    break;
                              case 'farm_type':
                                    await fetchFarmTypes();
                                    break;
                              case 'crop_type':
                                    await fetchCropTypes();
                                    break;      
                              case 'member_position':
                                    await fetchMemberPositions();
                                    break;
                              case 'article_category':
                                    await fetchArticleCategories();
                                    break;
                              case 'clusters':
                                    await fetchClusters();
                                    break;
                        }
                  }
            } catch (err) {
                  console.log(err)
            }
      }

      async function getLocByFilter(rig, tsp, vt) {
            try {
                  const url = `${API_URL.Locations}/get_meta?filters[region]=${rig ?? ''}&filters[township]=${tsp ?? ''}&filters[villageTract]=${vt ?? ''}`;
                  let res = await ApiRequest.get(url);
                  const data = res?.data?.payload?.data;
                  return data;
            } catch (err) {
                  console.log(err);
            }
      }

      return {
            fetchMetaData,
            refreshLoaction,
            refreshMetaData,
            getLocByFilter,
            fetchDonors
      }
}

export default useMetaDataActions;