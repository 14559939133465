import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useUser } from "../../features";

/**
 * @param {Boolean} isLoading
 * @param {Function} onClick 
 * @param {String} label
 * @param {Object} sx
 * @param {Boolean} isError
 * @param {String} errMsg
 * @param {Number} loadingIconSize Icon size in pixel
 * @param {Boolean} disabled
 * @param {string} action
 * @returns 
 */
const LoadingButton = (props) => {
      const { hasPermission } = useUser();
      const variant = props.variant ?? 'contained';
      const [ showBtn , setShowBtn ] = React.useState(true);

      React.useState(() => {
            if(props.action){
                  setShowBtn(hasPermission(props.action));
            } else {
                  setShowBtn(true);
            }
      }, [props.action]);

      return (
                  showBtn ? 
                  props.isLoading
                  ? <Button sx={{...(props.sx ?? {}), opacity: 0.7}} variant={variant} >
                        <CircularProgress color="text" size={props.loadingIconSize ?? 30} sx={{mr: 2}}/> {props.label}
                    </Button>
                  : <>
                        <Button 
                              variant={variant} onClick={props.onClick} size={'medium'} sx={{...(props.sx ?? {})}} disabled={props.disabled ?? false}>
                              {props.children ? props.children : props.label}
                        </Button>
                        {props.isError && <Typography variant="body1" color={'error'}>{props.errMsg}</Typography>}
                  </>
                  : null
      )
};

export default LoadingButton;
