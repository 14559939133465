import { createSlice } from "@reduxjs/toolkit";

const fundingHandoverSlice = createSlice({
      name: 'fundingHandover',
      initialState: {
            data: [],

            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,

            searchTerm: '',
            filters: {
                  start_date: '',
                  end_date: '',
                  status: [],
                  office_id: ''
            },

            isLoading: false,
            isError: false,
            errorMessage: '',
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            // Fetching Data 
            fetchingData: (state) => {
                  state.isLoading = true;
                  state.isError = false;
            },
            fetchDataSuccess: (state, action) => {
                  action.payload.data && (state.data = action.payload.data);
                  action.payload.total_items && (state.totalRows = action.payload.total_items);
                  state.isLoading = false;
                  state.isError = false;
            },
            fetchDataFail: (state, action) => {
                  state.isLoading = false;
                  state.isError = true;
                  state.errorMessage = action.payload;
            },

            // Create New
            submittingData: (state) => {
                  state.action.isLoading = true;
                  state.action.isError = false;
            },
            submitDataSuccess: (state) => {
                  state.action.isLoading = false;
                  state.action.isError = false;
                  state.action.errorMessage = '';
            },
            submitDataFail: (state, action) => {
                  state.action.isLoading = false;
                  state.action.isError = true;
                  state.action.errorMessage = action.payload;
            },

            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            setStartDate: (state, action) => {
                  state.filters.start_date = action.payload;
            },
            setEndDate: (state, action) => {
                  state.filters.end_date = action.payload;
            },
            setStartDateForCommittee: (state, action) => {
                  state.filters.startDateForCommittee = action.payload;
            },
              setEndDateForCommittee: (state, action) => {
                  state.filters.endDateForCommittee = action.payload;
            },
            setStatusFilter: (state, action) => {
                  state.filters.status = action.payload;
            },
            setOfficeFilter: (state, action) => {
                  state.filters.office_id = action.payload;
            },
            reloadDialogState: (state) => {
                  state.action = { isLoading: false, isError: false, errorMessage: ''}
            }
      }
});

export const {
      // Fetching List
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Create New
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setData,
      setSearchTerm,
      setCurrentPage,
      setRowsPerPage,
      setStartDate,
      setEndDate,
      setStatusFilter,
      setOfficeFilter,
      setStartDateForCommittee,
      setEndDateForCommittee
} = fundingHandoverSlice.actions;

export default fundingHandoverSlice.reducer;