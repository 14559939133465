import React from 'react';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { alpha, styled } from '@mui/material/styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import { Box, Typography } from '@mui/material';
import { formatVal } from '../../utils/string';


function DebitCreditRow({ label, balance }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant='body1' noWrap sx={{ m: 0 }}>{label}</Typography>
            <Box sx={{ flexGrow: 1 }} ></Box>
            <Typography variant='body1' sx={{ width: 100, m: 0, textAlign: 'right' }}> {formatVal(balance, 'number')}</Typography>

        </Box>
    )
}

const StyledTreeItem = styled((props) => (<TreeItem {...props} label={<DebitCreditRow label={props.label} balance={props.total} />} />))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 10,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

const MyTreeItem = (props) => {
    return (

        <StyledTreeItem nodeId={'nodeid_'} total={props.total} label={props.label}>
            {
                props.data?.map((item, i) =>
                    <StyledTreeItem nodeId={'nodeid_' + item.donor_id} key={i} total={item.amount} label={item.donor_name} />
                )
            }
        </StyledTreeItem>
    )
}

function TreeViewComponent(props) {
    return (
        <Box sx={{ display: 'flex', flexGrow: 1, width: 'auto', height: '100%', alignItems: 'flex-start', justifyItems: 'flex-start' }}>
            <TreeView
                aria-label="customized"
                defaultExpanded={['1']}
                defaultCollapseIcon={<RemoveCircleOutlineOutlinedIcon />}
                defaultExpandIcon={<AddCircleOutlineOutlinedIcon />}
                defaultEndIcon={<RadioButtonCheckedOutlinedIcon />}
                disableSelection={true}
                sx={{ flexGrow: 1, }}
            >
                <MyTreeItem data={props.data} total={props.total} label={props.label} />
            </TreeView>
        </Box>
    )
}

export default TreeViewComponent;