import { useSelector } from "react-redux";
import useVillageActions from "./_actions";

export const useVillage = () => {
    const {
        fetchVillageList,
        downloadVillageFileUploadTemplate,
        createMultipleVillage,
        getVillageList,
        // addVillage,
        changeCurrentPage, 
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        refreshDialogState,
        // editVillage
    } = useVillageActions();

    const villages = useSelector(state => state.village.data);
    const currentPage = useSelector(state => state.village.currentPage);
    const totalRows = useSelector(state => state.village.totalRows);
    const rowsPerPage = useSelector(state => state.village.rowsPerPage);
    const isLoading = useSelector(state => state.village.isLoading);
    const isError = useSelector(state => state.village.isError);
    const errorMessage = useSelector(state => state.village.errorMessage);
    const searchTerm = useSelector(state => state.village.searchTerm);
    const regionCodeFilter = useSelector(state => state.village.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.village.filters.township_code);
    const villageTractCodeFilter = useSelector(state => state.village.filters.villageTract_code);
    const apiAction = useSelector(state => state.village.action);

    return {
        //state
        villages,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        regionCodeFilter,
        townshipCodeFilter,
        villageTractCodeFilter,
        apiAction,

        //actions
        fetchVillageList,
        downloadVillageFileUploadTemplate,
        createMultipleVillage,
        getVillageList,
        // addVillage,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        refreshDialogState,
        // editVillage
    }
}

export {default as villageReducer} from './_reducers';