import { createSlice } from "@reduxjs/toolkit";

const metaDataSlice = createSlice({
      name: 'meta_data',
      initialState: {
            member_positions: [],
            farm_types: [],
            crop_types: [],
            donors: [],
            location: {
                  region: [],
                  township: [],
                  villageTract: [],
                  village: [],
                  office: []
            },
            article_categories: [],
            // other meta data
            clusters : []
      },
      reducers: {
            setMemberPositions: (state, action) => {
                  state.member_positions = action.payload;
            },
            setFarmTypes: (state, action) => {
                  state.farm_types = action.payload;
            },
            setCropTypes : (state, action) => {
                  state.crop_types = action.payload;
            },
            setDonors: (state, action) => {
                  state.donors = action.payload;
            },
            setLocation: (state, action) => {
                  state.location = action.payload;
            },
            setRegion: (state, action) => {
                  state.location.region = action.payload;
            },
            setTownship: (state, action) => {
                  state.location.township = action.payload;
            },
            setVillageTract: (state, action) => {
                  state.location.villageTract = action.payload;
            },
            setVillage: (state, action) => {
                  state.location.village = action.payload;
            },
            setOffice: (state, action) => {
                  state.location.office = action.payload;
            },
            setArticleCategories: (state, action) => {
                  state.article_categories = action.payload;
            },
            setClusters : (state, action) => {
                  state.clusters = action.payload;
            }
      }
});

export const {
      setMemberPositions,
      setFarmTypes,
      setCropTypes,
      setDonors,
      setLocation,
      setRegion,
      setTownship,
      setVillageTract,
      setVillage,
      setOffice,
      setArticleCategories,
      setClusters
} = metaDataSlice.actions;

export default metaDataSlice.reducer;