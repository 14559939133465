import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode : false,
    action : {
        isLoading: false,
        isError: false,
        errorMessage : ''
    }
}

const maintenanceSlice = createSlice({
    name : 'maintenance',
    initialState,
    reducers: {
       toggleMaintenanceMode : (state, actions) => {
            return {
                ...state,
                mode : actions.payload.mode
            }
       },
       submittingData: (state) => {
        state.action.isLoading = true;
        state.action.isError = false;
        },
        submitDataSuccess: (state) => {
                state.action.isLoading = false;
                state.action.isError = false;
        },
        submitDataFail: (state, action) => {
                state.action.isLoading = false;
                state.action.isError = true;
                state.action.errorMessage = action.payload;
        },
    }
})

export const { toggleMaintenanceMode, submittingData, submitDataSuccess, submitDataFail } = maintenanceSlice.actions;

export default maintenanceSlice.reducer