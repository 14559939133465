import { createSlice } from "@reduxjs/toolkit";

const depositSlice = createSlice({
    name: 'deposit',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        searchTerm: '',
        filters: {
            region_code: '',
            township_code: '',
            village_tract_code: '',
            start_date: '',
            end_date: '',
            startDateForCommittee: '',
            endDateForCommittee: '',
            dueDate: ''
        },

        isLoading: false,
        isError: false,
        errorMessage: '',
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        },
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            action.payload.data && (state.data = action.payload.data);
            action.payload.total_items && (state.totalRows = action.payload.total_items);
            state.isLoading = false;
            state.isError = false;
        },
        fetchingSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
            state.action.errorMessage = '';
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setStartDate: (state, action) => {
            state.filters.start_date = action.payload;
        },
        setEndDate: (state, action) => {
            state.filters.end_date = action.payload;
        },
        setRegionCodeFilter: (state, action) => {
            state.filters.region_code = action.payload;
        },
        setTownshipCodeFilter: (state, action) => {
            state.filters.township_code = action.payload;
        },
        setVillageTractCodeFilter: (state, action) => {
            state.filters.village_tract_code = action.payload;
        },
        setStartDateForCommittee: (state, action) => {
            state.filters.startDateForCommittee = action.payload;
        },
        setEndDateForCommittee: (state, action) => {
            state.filters.endDateForCommittee = action.payload;
        },
        setDueDateFilter: (state, action) => {
            state.filters.dueDate = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' };
        }
    }
});

export const {
    setData,
    setSearchTerm,
    setCurrentPage,
    fetchingData,
    setRowsPerPage,
    fetchDataSuccess,
    fetchingSuccess,
    fetchDataFail,
    setStartDate,
    setEndDate,
    setStartDateForCommittee,
    setEndDateForCommittee,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageTractCodeFilter,
    setDueDateFilter,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState
} = depositSlice.actions;

export default depositSlice.reducer;