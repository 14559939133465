import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";
import {
  // setData,
  fetchingData,
  fetchDataSuccess,
  fetchDataFail,

  // Create
  submittingData,
  submitDataSuccess,
  submitDataFail,
  reloadDialogState,
  setCurrentPage,
  setSearchTerm,
  setRowsPerPage,
  setCategoryFilter,
  setStatusFilter,
} from "./_reducers";
import { useNoti } from "../../providers";

const useProductCatalogActions = () => {
  const { showNoti } = useNoti();
  const dispatch = useDispatch();
  //   const data = useSelector(state => state.productCategory.data);
  const currentPage = useSelector((state) => state.productCatalog.currentPage);
  const rowsPerPage = useSelector((state) => state.productCatalog.rowsPerPage);
  const searchTerm = useSelector((state) => state.productCatalog.searchTerm);
  const categoryFilter = useSelector(
    (state) => state.productCatalog.filters.category_id
  );

  const statusFilter = useSelector(
    (state) => state.productCatalog.filters.status
  );
  
  async function getClusterList() {
      try {
          const url = API_URL.Cluster;
          const res = await ApiRequest.get(url);
          return res.data.payload.data;
      }
      catch(err) {
          console.error(err);
          return [];
      }
  }

  
  function getProductCatalogList(orderType, available, clusterId = "") {
    dispatch(fetchingData());
    let status = available ? true :  statusFilter === 'notAvailable' ? false : true;

    let get_catalog_url = `${API_URL.ProductCatalog}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[category_id]=${categoryFilter ?? ''}&filters[stock_available]=${status}&filters[order_type]=${orderType}&filters[cluster_id]=${clusterId}`;

    ApiRequest.get(get_catalog_url)
      .then((res) => {
        const data = res.data.payload.data;
        const { total_items } = res.data.payload.metadata;
        dispatch(fetchDataSuccess({ data, total_items }));
      })
      .catch((err) => {
        dispatch(fetchDataFail(err.message));
      });
  }

  // function getProductCatalogListByCategory(categoryId) {
  //   console.log("categoryId", categoryId)
  //   dispatch(fetchingData());
  //   // let status = available ? true :  statusFilter === 'notAvailable' ? false : true;

  //   let get_catalog_url = `${API_URL.ProductCatalog}/${categoryId}/cotegory`;

  //   ApiRequest.get(get_catalog_url)
  //     .then((res) => {
  //       const data = res.data.payload.data;
  //       const { total_items } = res.data.payload.metadata;
  //       dispatch(fetchDataSuccess({ data, total_items }));
  //     })
  //     .catch((err) => {
  //       dispatch(fetchDataFail(err.message));
  //     });
  // }

  async function getProductCatalogListByCategory(categoryId, orderType, clusterId = "") {
    const url = `${API_URL.ProductCatalog}/${categoryId}/category?filters[order_type]=${orderType}&filters[cluster_id]=${clusterId}`;
    try {
      const res = await ApiRequest.get(url);
      const catalogs = res.data.payload.data;
      return catalogs;
    } catch (err) {
      console.error(err);
      const errMsg = err.response?.data.errors?.message ?? err.message;
      showNoti(errMsg);
      //     return Promise.reject(errMsg);
    }
  }

  function getAllProductCatalog() {
    dispatch(fetchingData());

    let get_catalog_url = `${API_URL.ProductCatalog}`;

    ApiRequest.get(get_catalog_url)
      .then((res) => {
        const data = res.data.payload.data;
        const { total_items } = res.data.payload.metadata;
        dispatch(fetchDataSuccess({ data, total_items }));
      })
      .catch((err) => {
        dispatch(fetchDataFail(err.message));
      });
  }

  async function getCatalogCode(categoryId) {
    const url = `${API_URL.ProductCatalog}/${categoryId}/code`;
    try {
      const res = await ApiRequest.get(url);
      const catalog_code = res.data.payload.data;
      return catalog_code;
    } catch (err) {
      console.error(err);
      const errMsg = err.response?.data.errors?.message ?? err.message;
      showNoti(errMsg);
      //     return Promise.reject(errMsg);
    }
  }

  async function createProductCatalog(data) {
    dispatch(submittingData());
    const url = API_URL.ProductCatalog;
    try {
      // Log the error and display a notification.
      const res = await ApiRequest.post(url, data);
      showNoti("Product product created!", "success");
      dispatch(submitDataSuccess());
      getProductCatalogList("list");
      
      return res.data.payload.data;
    } catch (err) {
      dispatch(submitDataFail(err.message ?? "Creating product Failed"));
      showNoti(err.message, "error");
      const errors = {};
      errors.data = err.response?.data.errors.data;
      // !err.response && showNoti(err.message, 'error');
      return Promise.reject(errors);
    }
  }

  async function getProductDetails(id) {
    dispatch(submittingData());
    try {
      const url = API_URL.ProductCatalog + "/" + id;
      const res = await ApiRequest.get(url);
      dispatch(submitDataSuccess());
      return res.data.payload.data;
    } catch (error) {
      dispatch(submitDataFail(error.message ?? "Getting product details failed"));
      const errors = {};
      errors.data = error.response?.data.errors.data;
      return Promise.reject(errors);
    }
  }

  async function updateProductCatalog(catalog) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.ProductCatalog}/update`;
      const res = await ApiRequest.post(url, catalog);
      showNoti("Product Catalog updated!", "success");
      const editedCatalog = res.data.payload.data;
      dispatch(submitDataSuccess());
      getProductCatalogList("list");
      return editedCatalog;
    } catch (err) {
      const errors = {};
      errors.data = err.response?.data.errors.data;
      !err.response && showNoti(err.message, "error");
      dispatch(submitDataFail());
      return Promise.reject(errors);
    }
  }

  async function uploadProductImage(fileData){
    try {
      const url = `${API_URL.ProductCatalog}/upload_image`;
      const res = await ApiRequest.post(url, fileData);
      const uploadedImage = res.data.payload.data.image_key;
      return Promise.resolve(uploadedImage);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async function deleteProduct(id) {
    dispatch(submittingData());
    try {
      const url = `${API_URL.ProductCatalog}/delete`;
      await ApiRequest.post(url, {product_id: id});
      showNoti("Product is deleted!", "success");
      dispatch(submitDataSuccess());
      getProductCatalogList("list");
    } catch (err) {
      const errors = {};
      errors.data = err.response?.data.errors.data;
      !err.response && showNoti(err.message, "error");
      dispatch(submitDataFail());
      return Promise.reject(errors);
    }
  }

  function dataSubmitting() {
    dispatch(submittingData());
  }

  function changeCategoryFilter(category) {
    dispatch(setCurrentPage(0));
    dispatch(setCategoryFilter(category));
  }

  function changeSearchTerm(search_key) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTerm(search_key));
  }
  function changeCurrentPage(pageNo) {
    dispatch(setCurrentPage(pageNo));
  }

  function changeRowsPerPage(rowsPerPage) {
    dispatch(setCurrentPage(0));
    dispatch(setRowsPerPage(rowsPerPage));
  }
  function refreshDialogState() {
    dispatch(reloadDialogState());
  }

  function changeStatusFilter(status) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTerm(""));
    dispatch(setStatusFilter(status));
}

  return {
    // Actions
    getProductCatalogListByCategory,
    getAllProductCatalog,
    getProductCatalogList,
    createProductCatalog,
    getProductDetails,
    getCatalogCode,
    updateProductCatalog,
    refreshDialogState,
    changeCategoryFilter,
    uploadProductImage,
    changeStatusFilter,
    deleteProduct,
    getClusterList,

    // Paganation
    changeSearchTerm,
    changeCurrentPage,
    changeRowsPerPage,
    dataSubmitting,
  };
};

export default useProductCatalogActions;
