import { useDispatch } from 'react-redux';
import { changeThemeMode, openDrawerRedu, toggleSideBar } from './_reducers';

const useThemeSettingActions = () => {
      const dispatch = useDispatch();

      function changeMode() {
            dispatch(changeThemeMode());
      }
      function toggleLeftSideBar(bool) {
            dispatch(toggleSideBar(bool));
      }
      function openDrawer(bool) {
            dispatch(openDrawerRedu(bool))
      }

      return {
            changeMode,
            toggleLeftSideBar,
            openDrawer
      };

};

export default useThemeSettingActions;