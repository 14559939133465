import { useSelector } from "react-redux";
import useVillageTractActions from "./_actions";

export const useVillageTract = () => {
    const {
        fetchVillageTractList,
        getVillageTractList,
        addVillageTract,
        changeCurrentPage, 
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        refreshDialogState,
        editVillageTract,
        downloadVillageTractFileUploadTemplate,
        createMultipleVillageTracts
    } = useVillageTractActions();

    const villageTracts = useSelector(state => state.village_tract.data);
    const currentPage = useSelector(state => state.village_tract.currentPage);
    const totalRows = useSelector(state => state.village_tract.totalRows);
    const rowsPerPage = useSelector(state => state.village_tract.rowsPerPage);
    const isLoading = useSelector(state => state.village_tract.isLoading);
    const isError = useSelector(state => state.village_tract.isError);
    const errorMessage = useSelector(state => state.village_tract.errorMessage);
    const searchTerm = useSelector(state => state.village_tract.searchTerm);
    const regionCodeFilter = useSelector(state => state.village_tract.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.village_tract.filters.township_code);
    const apiAction = useSelector(state => state.village_tract.action);

    return {
        //state
        villageTracts,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        regionCodeFilter,
        townshipCodeFilter,
        apiAction,

        //actions
        fetchVillageTractList,
        getVillageTractList,
        addVillageTract,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        refreshDialogState,
        editVillageTract,
        downloadVillageTractFileUploadTemplate,
        createMultipleVillageTracts
    }
}

export {default as villageTractReducer} from './_reducers';