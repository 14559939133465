import React from "react"; 
 import { useTheme } from "@emotion/react";


const ThemeLogo = (props) => {
      const theme = useTheme();
      return (
            theme.palette.mode === 'dark'
                  ? <img src="/images/logo.svg" height={props.size} alt="logo1" onClick={() => props.onClick} />
                  : <img src="/images/logo.svg" height={props.size} alt="logo2" onClick={() => props.onClick} />
      )
};

export default ThemeLogo;
