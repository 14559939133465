import { Navigate } from 'react-router-dom';
import ROUTES from '../configs/_routes';
import { useUser } from '../features';
import React from 'react';
import { useSocket } from "../features";

const ProtectedRoutes = ({ children }) => {
      const { isAuth } = useUser();
      const { connectSocket, disconnectSocket } = useSocket();

      React.useEffect(() => {
            if(isAuth) {
                  connectSocket();
            }

            return isAuth ? disconnectSocket : undefined;
      }, []);
      
      if (!isAuth) {
            return <Navigate to={ROUTES.LANDING}/>
      }
      return children;
};

export default ProtectedRoutes;