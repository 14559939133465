import { createSlice } from "@reduxjs/toolkit";

const memberSlice = createSlice({
    name: 'member',
    initialState: {
        data: [],
        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        searchTerm: '',
        searchTermForCommittee: '',
        filters: {
            status: 'active',
            member_type: '',
            statusForCommittee: '',
            smsStatusForCommittee: '',
            region_code: '',
            township_code: '',
            village_tract_code: '',
            village_code: '',
            position_id: '',
            created_date: null,
        },
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        },

        isLoading: false,
        isError: false,
        errorMessage: ''
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload?.trim();
        },
        setSearchTermForCommittee: (state, action) => {
            state.searchTermForCommittee = action.payload?.trim();
        },
        setStatusFilter: (state, action) => {
            state.filters.status = action.payload;
        },
        setStatusFilterForCommittee: (state, action) => {
            state.filters.statusForCommittee = action.payload;
        },
        setSmsStatusFilterForCommittee: (state, action) => {
            state.filters.smsStatusForCommittee = action.payload;
        },
        setRegionCodeFilter: (state, action) => {
            state.filters.region_code = action.payload;
        },
        setTownshipCodeFilter: (state, action) => {
            state.filters.township_code = action.payload;
        },
        setVillageTractCodeFilter: (state, action) => {
            state.filters.village_tract_code = action.payload;
        },
        setVillageCodeFilter: (state, action) => {
            state.filters.village_code = action.payload;
        },
        setPositionFilter: (state, action) => {
            state.filters.position_id = action.payload;
        },
        setCreatedDateFilter: (state, action) => {
            state.filters.created_date = action.payload;
        },
        setMemberTypeFilter: (state, action) => {
            state.filters.member_type = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;

            action.payload?.data && (state.data = action.payload.data);
            action.payload?.total_items && (state.totalRows = action.payload.total_items);
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
        dialogActionStarted: (state) => {
            state.action = { isLoading: true, isError: false, errorMessage: '' }
        },
        dialogActionSuccess: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
        dialogActionFail: (state, action) => {
            state.action = { isLoading: false, isError: true, errorMessage: action.payload }
        },
    }
});

export const {
    setData,

    setCurrentPage,
    setRowsPerPage,
    setTotalRows,

    setSearchTerm,
    setSearchTermForCommittee,
    setStatusFilter,
    setStatusFilterForCommittee,
    setSmsStatusFilterForCommittee,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageTractCodeFilter,
    setVillageCodeFilter,
    setPositionFilter,
    setCreatedDateFilter,
    setMemberTypeFilter,

    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState,
    dialogActionStarted,
    dialogActionSuccess,
    dialogActionFail
} = memberSlice.actions;

export default memberSlice.reducer;