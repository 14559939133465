import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import {
    setData,
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    setSearchTerm,
    setRegionIdFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    reloadDialogState,
    dialogActionStarted,
    dialogActionSuccess,
    dialogActionFail
} from "./_reducers";
import { API_URL } from '../../configs/_api';

const useOfficeActions = () => {
    const dispatch = useDispatch();
    const currentPage = useSelector(state => state.office.currentPage);
    const rowsPerPage = useSelector(state => state.office.rowsPerPage);
    const searchTerm = useSelector(state => state.office.searchTerm);
    const regionIdFilter = useSelector(state => state.office.filters.region_id);


    function fetchOfficeList() {
        dispatch(fetchingData());

        let get_office_url = `${API_URL.Office}?search_key=${searchTerm}&region=${regionIdFilter}&page_no=${currentPage + 1}&page_size=${rowsPerPage}`;
        ApiRequest.get(get_office_url).then(res => {
            const { data, total_items } = res.data;
            dispatch(setData(data));
            dispatch(setTotalRows(total_items ?? 0));
            dispatch(fetchDataSuccess());
        }).catch(err => {
            console.log(err);
            dispatch(fetchDataFail(err.response?.data));
        });
    }

    async function getOfficeListByLocation(region_id) {
        let url = `${API_URL.Office}?region=${region_id}`;
        try {
            const res = await ApiRequest.get(url);
            return res.data.data;
        }
        catch(err) {
            return Promise.reject(err);
        }
    }

    async function addOffice(office_data) {
        dispatch(dialogActionStarted())
        let add_office_url = API_URL.Office + "/add";
        try {
            const res = await ApiRequest.post(add_office_url, office_data,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            const newOffice = res.data;
            dispatch(dialogActionSuccess());
            return newOffice;
        } catch (err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            let errorMsg = !err.response ? err.message : 'Creating new office failed'
            dispatch(dialogActionFail(errorMsg));
            return Promise.reject(errors);
        }
    }

    async function updateOffice(office_data) {
        let edit_office_url = API_URL.Office + "/edit";

        try {
            const res = await ApiRequest.post(edit_office_url, office_data,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            const editOffice = res.data;
            return editOffice;
        } catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `${err.message}. Fail to Edit ${office_data.en_name}!`;
            return Promise.reject(errMsg);
        }
    }


    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }
    function changeRegionIdFilter(region_id) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(""));
        dispatch(setRegionIdFilter(region_id));
    }
    function refreshDialogState(){
        dispatch(reloadDialogState());
    }


    return {
        getOfficeListByLocation,
        fetchOfficeList,
        addOffice,
        updateOffice,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionIdFilter,
        refreshDialogState
    }
}

export default useOfficeActions;