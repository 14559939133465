import { createSlice } from "@reduxjs/toolkit";

const productCatalogSlice = createSlice({
  name: "productCatalog",
  initialState: {
    data: [],

    totalRows: 0,
    currentPage: 0,
    rowsPerPage: 5,

    filters: {
      category_id: "",
      status: "",
    },

    searchTerm: "",
    isLoading: false,
    isError: false,
    errorMessage: "",
    action: {
      isLoading: false,
      isError: false,
      errorMessage: "",
    },
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    // Fetching Data
    fetchingData: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchDataSuccess: (state, action) => {
      action.payload.data && (state.data = action.payload.data);
      action.payload.total_items &&
        (state.totalRows = action.payload.total_items);
      state.isLoading = false;
      state.isError = false;
    },
    fetchDataFail: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },

    // Create New
    submittingData: (state) => {
      state.action.isLoading = true;
      state.action.isError = false;
    },
    submitDataSuccess: (state) => {
      state.action.isLoading = false;
      state.action.isError = false;
      state.action.errorMessage = "";
    },
    submitDataFail: (state, action) => {
      state.action.isLoading = false;
      state.action.isError = true;
      state.action.errorMessage = action.payload;
    },
    setCategoryFilter: (state, action) => {
      state.filters.category_id = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    reloadDialogState: (state) => {
      state.action = { isLoading: false, isError: false, errorMessage: "" };
    },
    setStatusFilter: (state, action) => {
      state.filters.status = action.payload
  },
  },
});

export const {
  // Fetching List
  fetchingData,
  fetchDataSuccess,
  fetchDataFail,

  // Create New
  submittingData,
  submitDataSuccess,
  submitDataFail,
  reloadDialogState,

  setCategoryFilter,
  setData,
  setSearchTerm,
  setCurrentPage,
  setRowsPerPage,
  setStatusFilter
} = productCatalogSlice.actions;

export default productCatalogSlice.reducer;
