import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryIdFilter,
  setCurrentPage,
  setSearchTerm,
  setRowsPerPage,
  submittingData,
  submitDataFail,
  submitDataSuccess,
  fetchingData,
  fetchDataSuccess,
  fetchDataFail,
  reloadDialogState,
} from "./_reducers";
import ApiRequest from "../../utils/apiRequest";
import { API_URL } from "../../configs/_api";

const useArticleActions = () => {
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.article.currentPage);
  const rowsPerPage = useSelector((state) => state.article.rowsPerPage);
  const searchTerm = useSelector((state) => state.article.searchTerm);
  const categoryIdFilter = useSelector((state) => state.article.filters.category_id);



  function getArticleByFilter() {
      dispatch(fetchingData());
      const getArticleListUrl = `${API_URL.Article}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[category_id]=${categoryIdFilter}`;

      ApiRequest.get(getArticleListUrl)
        .then(res => {
          const data = res.data.payload.data;
          const {total_items} = res.data.payload.metadata;  

          dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
          const errMsg = err.response?.data.errors?.message??  `${err.message}`
          dispatch(fetchDataFail(errMsg))
        })
  }

  async function getArticleDetail (id) {
    dispatch(fetchingData());
    const url = `${API_URL.Article}/${id}`;

    try {
      const res = await ApiRequest.get(url);
      const data = res.data.payload.data;
      dispatch(fetchDataSuccess({}));
      return data;
    } catch (err) {
      const errMsg = err.response?.data.errors?.message ??  `${err.message}`
      dispatch(fetchDataFail(errMsg))
      return Promise.reject(errMsg);
    }
  }

  async function uploadArticleImage(fileData) {

    dispatch(submittingData());

    const url = `${API_URL.Article}/upload_image`;

    try {
        const res = await ApiRequest.post(url, fileData);
          const data = res?.data?.payload?.data;
          dispatch(submitDataSuccess());
          return data;
    } catch (err) {
        const errors = {};
        errors.data = err?.response?.data?.errors?.data;
        dispatch(submitDataFail(err.response.data.errors.message ?? "Uploading image failed!"));
        return Promise.reject(errors);
    }
}

 async function createArticle (data) {
    dispatch(submittingData());
    const url = `${API_URL.Article}`;

    try {
        const res = await ApiRequest.post(url, data);
          const article = res.data
          dispatch(submitDataSuccess())
          return article
    } catch (err) {
      const errors = {};
        errors.data = err?.response?.data?.errors?.data;
        dispatch(submitDataFail(err.response.data.errors.message ?? "Uploading image failed!"));
        return Promise.reject(errors);
    }
 }

 async function updateArtcle (data) {
  dispatch(submittingData());
  const url = `${API_URL.Article}/edit`;

  try {
      const res = await ApiRequest.post(url, data);
        const article = res.data
        dispatch(submitDataSuccess())
        return article
  } catch (err) {
    const errors = {};
      errors.data = err?.response?.data?.errors?.data;
      dispatch(submitDataFail(err.response.data.errors.message?? "Uploading image failed!"));
      return Promise.reject(errors);
  }
 }


  function changeArticleCategoryFilter(category) {
    dispatch(setCurrentPage(0));
    dispatch(setCategoryIdFilter(category));
  }

  function changeSearchTerm(search_key) {
    dispatch(setCurrentPage(0));
    dispatch(setSearchTerm(search_key));
  }

  function changeCurrentPage(pageNo) {
    dispatch(setCurrentPage(pageNo));
  }

  function changeRowsPerPage(rowsPerPage) {
    dispatch(setCurrentPage(0));
    dispatch(setRowsPerPage(rowsPerPage));
  }

  function refreshDialogState() {
    dispatch(reloadDialogState());
}

  return {
    // Actions
    getArticleByFilter,
    getArticleDetail,
    uploadArticleImage,
    createArticle,
    updateArtcle,
    changeArticleCategoryFilter,
    changeSearchTerm,
    changeCurrentPage,
    changeRowsPerPage,
    refreshDialogState
  };
};

export default useArticleActions;
