import { useSelector } from 'react-redux';
import useRelatedRevenueActions from './_actions';

export const useRelatedRevenue = () => {
    const {
        getRelatedRevenue,
    } = useRelatedRevenueActions();

    const relatedRevenue = useSelector(state => state.related_revenue.data);

    return {
        getRelatedRevenue,
        relatedRevenue,
    }
}

export { default as relatedRevenueReducer } from './_reducers';