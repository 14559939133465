import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ChangeThemeButton from '../components/Buttons/ChangeThemeButton';
import AccountMenuPopper from '../components/Menu/AccountMenuPopper';
import { useUser } from '../features';

const TopNavigation = () => {
      const { userData } = useUser();
      const varExt = process.env.REACT_APP_ENV == "development" ? '-dev' : process.env.REACT_APP_ENV == 'staging' ? '-stag' : ''
      return (
            <AppBar position={'sticky'} color="default" elevation={0} sx={{ display: 'flex', pl: '12px', pr: '12px', bgcolor: 'background.default' }}>
                  <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left', color: 'text.primary' }}>
                              SuBuu
                        </Typography>
                        <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }} >
                              <Typography sx={{ mr: 2, color: 'gray', fontSize: 10 }}>
                                    {`Logged in as '${userData?.role}' role @ ${userData?.office}`}
                              </Typography>
                              <Typography sx={{ mr: 2 }}>
                                    v{process.env.REACT_APP_VERSION + varExt}
                              </Typography>
                              <ChangeThemeButton />
                              <AccountMenuPopper />
                        </Stack>
                  </Toolbar>
            </AppBar>
      )
};

export default TopNavigation;