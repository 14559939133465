import { useSelector } from "react-redux";
import useShopActions from "./_actions";

export const useShop = () => {
    const {
        getShopListByFilter,
        getShopDetail,
        addShop,
        editShop,
        editShopPic,
        changeShopPhoneNumber,
        getUnsettledInvoices,
        getInvoicesOnDraftSettlement,
        deactivateShop,
        activateShop,
        changeStatusFilter,
        changeCurrentPage, 
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionIdFilter,
        changeTownshipIdFilter,
        resetShopPin,
        refreshDialogState,
    } = useShopActions();

    const shops = useSelector(state => state.shop.data);
    const currentPage = useSelector(state => state.shop.currentPage);
    const totalRows = useSelector(state => state.shop.totalRows);
    const rowsPerPage = useSelector(state => state.shop.rowsPerPage);
    const isLoading = useSelector(state => state.shop.isLoading);
    const isError = useSelector(state => state.shop.isError);
    const errorMessage = useSelector(state => state.shop.errorMessage);
    const searchTerm = useSelector(state => state.shop.searchTerm);
    const statusFilter = useSelector(state => state.shop.filters.status);
    const regionIdFilter = useSelector(state => state.shop.filters.region_id);
    const townshipIdFilter = useSelector(state => state.shop.filters.township_id);
    const apiAction = useSelector(state => state.shop.action);

    return {
        //state
        shops,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        statusFilter,
        regionIdFilter,
        townshipIdFilter,
        apiAction,

        //actions
        getShopListByFilter,
        getShopDetail,
        addShop,
        editShop,
        editShopPic,
        changeShopPhoneNumber,
        getUnsettledInvoices,
        getInvoicesOnDraftSettlement,
        deactivateShop,
        activateShop,
        changeStatusFilter,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionIdFilter,
        changeTownshipIdFilter,
        resetShopPin,
        refreshDialogState
    }
}

export {default as shopReducer} from './_reducers';