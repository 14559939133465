import { useSelector } from "react-redux";
import useArticleActions from "./_actions";

export const useArticle = () => {
  const {
    getArticleByFilter,
    getArticleDetail,
    uploadArticleImage,
    createArticle,
    updateArtcle,
    changeArticleCategoryFilter,
    changeSearchTerm,
    changeRowsPerPage,
    changeCurrentPage,
    refreshDialogState
  } = useArticleActions();

  const data = useSelector((state) => state.article.data);
  const currentPage = useSelector((state) => state.article.currentPage);
  const totalRows = useSelector((state) => state.article.totalRows);
  const rowsPerPage = useSelector((state) => state.article.rowsPerPage);
  const searchTerm = useSelector((state) => state.article.searchTerm);
  const isError = useSelector((state) => state.article.isError);
  const isLoading = useSelector((state) => state.article.isLoading);
  const errorMessage = useSelector((state) => state.article.errorMessage);
  const isSubmitLoading = useSelector((state) => state.article.action.isLoading);

  const categoryIdFilter = useSelector((state) => state.article.filters.category_id);

  return {
    // state
    data,
    currentPage,
    totalRows,
    rowsPerPage,
    searchTerm,
    isError,
    isLoading,
    isSubmitLoading,
    errorMessage,
    categoryIdFilter,

    // actions
    getArticleByFilter,
    getArticleDetail,
    uploadArticleImage,
    createArticle,
    updateArtcle,
    changeArticleCategoryFilter,
    changeSearchTerm,
    changeCurrentPage,
    changeRowsPerPage,
    refreshDialogState
  };
};

export { default as articleReducer } from "./_reducers";