import { useSelector } from "react-redux";
import usePortalUserActions from "./_actions";

export const usePortalUser = () => {
    const {
        fetchPortalUserList,
        addPortalUser,
        changeCurrentPage, 
        changeRowsPerPage,
        changeSearchTerm,
        refreshDialogState,
        changeStatusFilter,
        activateUser,
        deactivateUser,
        getPortalUserDetail
    } = usePortalUserActions();

    const portalUsers = useSelector(state => state.portal_user.data);
    const currentPage = useSelector(state => state.portal_user.currentPage);
    const totalRows = useSelector(state => state.portal_user.totalRows);
    const rowsPerPage = useSelector(state => state.portal_user.rowsPerPage);
    const isLoading = useSelector(state => state.portal_user.isLoading);
    const isError = useSelector(state => state.portal_user.isError);
    const errorMessage = useSelector(state => state.portal_user.errorMessage);
    const searchTerm = useSelector(state => state.portal_user.searchTerm);
    const apiAction = useSelector(state => state.portal_user.action);
    const statusFilter = useSelector(state => state.portal_user.filters.status);

    return {
        //state
        portalUsers,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        apiAction,
        statusFilter,
        

        //actions
        fetchPortalUserList,
        addPortalUser,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        refreshDialogState,
        changeStatusFilter,
        activateUser,
        deactivateUser,
        getPortalUserDetail
    }
}

export {default as portalUserReducer} from './_reducers';