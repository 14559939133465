import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        admin: {
            user: {
                committee: {
                    active: 0,
                    inactive: 0,
                    draft: 0,
                    verifying: 0
                },
                member: {
                    unverified: 0,
                    active: 0,
                    inactive: 0
                },
                shop: {
                    active: 0,
                    inactive: 0
                },
                portalUser: {
                    active: 0,
                    inactive: 0
                }
            },
            ledger: {
                totalFundedAmount: 0,
                allocatedAmount: 0,
                unallocatedAmount: 0,
                officeCashInHand: 0,
                committeeCashInHand: 0,
                committeeCurrentAccount: 0,
                committeeLost: 0,
                ARFromFarmer: 0
            },
            issuedPoint: {
                committee: 0,
                member: 0,
                shop: 0
            },
            totalLedgerBalanceV2: [],
            totalLedgerBalanceV3: []
        },
        financeHO: {
            ledger: {
                totalFundedAmount: 0,
                allocatedAmount: 0,
                unallocatedAmount: 0,
                officeCashInHand: 0,
                committeeCashInHand: 0,
                committeeCurrentAccount: 0,
                committeeLost: 0,
                ARFromFarmer: 0
            },
            issuedPoint: {
                committee: 0,
                member: 0,
                shop: 0
            },
            totalLedgerBalanceV2: [],
            totalLedgerBalanceV3: []
        },
        isLoading: false,
        isError: false, 
        errorMessage: "",

        dialogStatus: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    },
    reducers: {
        setAdminData: (state, action) => {
            const { user, ledger, issuedPoint, totalLedgerBalanceV2, totalLedgerBalanceV3 } = action.payload;

            const { committee, member, shop, portalUser } = user;

            state.admin.user.committee.active = committee.active;
            state.admin.user.committee.inactive = committee.inactive;
            state.admin.user.committee.draft = committee.draft;
            state.admin.user.committee.verifying = committee.verifying;
            state.admin.user.member.unverified = member.unverified;
            state.admin.user.member.active = member.active;
            state.admin.user.member.inactive = member.inactive;
            state.admin.user.shop.active = shop.active;
            state.admin.user.shop.inactive = shop.inactive;
            state.admin.user.portalUser.active = portalUser.active;
            state.admin.user.portalUser.inactive = portalUser.inactive ?? 0;

            const { totalFundedAmount, officeCashInHand, committeeCashInHand, committeeLost, ARFromFarmer, allocatedAmount, unallocatedAmount, committeeCurrentAccount } = ledger;

            state.admin.ledger.totalFundedAmount = totalFundedAmount;
            state.admin.ledger.allocatedAmount = allocatedAmount;
            state.admin.ledger.unallocatedAmount = unallocatedAmount;
            state.admin.ledger.officeCashInHand = officeCashInHand;
            state.admin.ledger.committeeCashInHand = committeeCashInHand;
            state.admin.ledger.committeeCurrentAccount = committeeCurrentAccount;
            state.admin.ledger.committeeLost = committeeLost;
            state.admin.ledger.ARFromFarmer = ARFromFarmer;

            state.admin.issuedPoint.member = issuedPoint.member;
            state.admin.issuedPoint.committee = issuedPoint.committee;
            state.admin.issuedPoint.shop = issuedPoint.shop;

            state.admin.totalLedgerBalanceV2 = totalLedgerBalanceV2;
            state.admin.totalLedgerBalanceV3 = totalLedgerBalanceV3;
        },

        setFinanceHOData: (state, action) => {
            const { ledger, issuedPoint, totalLedgerBalanceV2, totalLedgerBalanceV3 } = action.payload;

            const { totalFundedAmount, officeCashInHand, committeeCashInHand, committeeLost, ARFromFarmer, allocatedAmount, unallocatedAmount, committeeCurrentAccount } = ledger;

            state.financeHO.ledger.totalFundedAmount = totalFundedAmount;
            state.financeHO.ledger.allocatedAmount = allocatedAmount;
            state.financeHO.ledger.unallocatedAmount = unallocatedAmount;
            state.financeHO.ledger.officeCashInHand = officeCashInHand;
            state.financeHO.ledger.committeeCashInHand = committeeCashInHand;
            state.financeHO.ledger.committeeCurrentAccount = committeeCurrentAccount;
            state.financeHO.ledger.committeeLost = committeeLost;
            state.financeHO.ledger.ARFromFarmer = ARFromFarmer;

            state.financeHO.issuedPoint.member = issuedPoint.member;
            state.financeHO.issuedPoint.committee = issuedPoint.committee;
            state.financeHO.issuedPoint.shop = issuedPoint.shop;

            state.financeHO.totalLedgerBalanceV2 = totalLedgerBalanceV2;
            state.financeHO.totalLedgerBalanceV3 = totalLedgerBalanceV3;
        },

        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.errorMessage = "";
        },
        fetchDataSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        reCalculatingLedger: (state) => {
            state.dialogStatus.isLoading = true;
            state.dialogStatus.isError = false;
            state.dialogStatus.errorMessage = "";
        },
        reCalculatingSuccess: (state) => {
            state.dialogStatus.isLoading = false;
            state.dialogStatus.isError = false;
        },
        reCalculatingFail: (state, action) => {
            state.dialogStatus.isLoading = false;
            state.dialogStatus.isError = true;
            state.dialogStatus.errorMessage = action.payload;
        },
        resetDialogStatus: (state) => {
            state.dialogStatus.isLoading = false,
            state.dialogStatus.isError = false,
            state.dialogStatus.errorMessage = ''
        }
    }
});

export const {
    setAdminData,
    setFinanceHOData,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    reCalculatingLedger,
    reCalculatingSuccess,
    reCalculatingFail,
    reCalculatingLedgerSuccess,
    resetDialogStatus
} = dashboardSlice.actions;

export default dashboardSlice.reducer;