import { useDispatch } from 'react-redux';
import { setAsMobile, setOpenDrawer } from './_reducers';

const useLayoutSettingActions = () => {
      const dispatch = useDispatch();

      function setAsMobileSize(bool) {
            dispatch(setAsMobile(bool));
      }
      function openDrawer(bool) {
            dispatch(setOpenDrawer(bool));
      }

      return {
            setAsMobileSize,
            openDrawer
      };

};

export default useLayoutSettingActions;