import React from 'react';
import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTabs = styled((props) => <Tabs id="StyledTab" {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />)(
      ({ theme }) => ({
            '& .MuiTabs-indicator': {
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'transparent',
            },
            '& .MuiTabs-indicatorSpan': {
                  maxWidth: 40,
                  width: '100%',
                  backgroundColor: theme.palette.primary.main,
            },
      }),
);



export default StyledTabs;