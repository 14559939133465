import ApiRequest from "../../utils/apiRequest";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import { 
    setCurrentPage, 
    setRowsPerPage, 
    setSearchTerm,
    setStatusFilter,

    fetchingData, 
    fetchDataSuccess, 
    fetchDataFail,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState
} from "./_reducers";
import { useNoti } from "../../providers";
import { asymmetricEncrypt } from '../../utils/crypt';

const usePortalUserActions = () => {
    const dispatch = useDispatch();
    const {showNoti} = useNoti();

    const currentPage = useSelector(state => state.portal_user.currentPage);
    const rowsPerPage = useSelector(state => state.portal_user.rowsPerPage);
    const searchTerm = useSelector(state => state.portal_user.searchTerm);
    const statusFilter = useSelector(state => state.portal_user.filters.status);

    function fetchPortalUserList() {
        dispatch(fetchingData());

        const getPortalUserListUrl = `${API_URL.PortalUser}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}`;
        ApiRequest.get(getPortalUserListUrl).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;

            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

    async function addPortalUser(data) {
        const addPortalUserUrl = API_URL.PortalUser;
        const submitData = {
            ...data,
            password: asymmetricEncrypt(data.password),
        }
        dispatch(submittingData());
        try {
            let id = null; 
            await ApiRequest.post(addPortalUserUrl, submitData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => id = res.data.payload.data._id);
            showNoti('New portal user created successfully!', 'success');
            dispatch(submitDataSuccess());
            fetchPortalUserList();
            return Promise.resolve(id);
        }
        catch(err) {
            const errors = {};
            errors.data = err.response?.data.errors.data;
            dispatch(submitDataFail(err.response?.data.errors.message ?? 'Creating portal user failed'));
            return Promise.reject(errors);
        }
    }

    async function activateUser(userId) {
        const url = `${API_URL.PortalUser}/${userId}/activate`;
        dispatch(submittingData());
        try {
            await ApiRequest.patch(url);
            showNoti(`User activated successfully`, 'success');
            dispatch(submitDataSuccess());
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            dispatch(submitDataFail());
            showNoti(`Error to activate user. ${err.message}`, 'error');
            return Promise.reject();
        }
    }

    async function deactivateUser(userId) {
        const url = `${API_URL.PortalUser}/${userId}/deactivate`;
        dispatch(submittingData());
        try {
            await ApiRequest.patch(url);
            dispatch(submitDataSuccess());
            showNoti(`User deactivated successfully`, 'success');
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            dispatch(submitDataFail());
            showNoti(`Error to deactivate user. ${err.message}`, 'error');
            return Promise.reject();
        }
    }

    async function getPortalUserDetail(portaluserid,  apiOptions={}) {
        dispatch(fetchingData());
        try {
            const url = `${API_URL.PortalUser}/${portaluserid}`;
            const res = await ApiRequest.get(url, {...apiOptions});
            const portalUser = res.data.payload.data;
            dispatch(fetchDataSuccess());
            return portalUser;
        }
        catch(err) {
            console.error(err);
            dispatch(fetchDataFail(`Failed to get user detail! ${err.message}`));
            return Promise.reject();
        }
    }


    function changeStatusFilter(status) {
        dispatch(setStatusFilter(status));
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }

    function refreshDialogState(){
        dispatch(reloadDialogState());
    }

    return {
        fetchPortalUserList,
        addPortalUser,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        refreshDialogState,
        changeStatusFilter,
        activateUser,
        deactivateUser,
        getPortalUserDetail
    }
}

export default usePortalUserActions;