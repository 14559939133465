import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import ApiRequest from "../../utils/apiRequest";

import {
      // setData,
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,

      // Create
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState,

      setCurrentPage,
      setSearchTerm,
      setRowsPerPage,
      setStartDate,
      setEndDate,
      setStatusFilter,
      setOfficeFilter,
      setStartDateForCommittee,
      setEndDateForCommittee,

} from "./_reducers";
import { useNoti } from "../../providers";
import { requestOneTimeToken } from "../../utils/helper";
import useDataExportActions from "../data_exports/_actions";
import { fundingTransferColumns } from "../../configs/_dataColumnConfig"
import dayjs from "dayjs";
import { writeOutExcelFile } from "../../utils/xlsxWorkBook";



const useFundingHandoverActions = () => {
      const { showNoti } = useNoti();
      const dispatch = useDispatch();
      const { moduleParam, getFilteredData } = useDataExportActions();


      const currentPage = useSelector(state => state.fundingHandover.currentPage);
      const rowsPerPage = useSelector(state => state.fundingHandover.rowsPerPage);
      const searchTerm = useSelector(state => state.fundingHandover.searchTerm);
      const start_date = useSelector(state => state.fundingHandover.filters.start_date);
      const end_date = useSelector(state => state.fundingHandover.filters.end_date);
      const statusFilter = useSelector(state => state.fundingHandover.filters.status);
      const officeFilter = useSelector(state => state.fundingHandover.filters.office_id);

      function fetchFundingHandoverList() {
            dispatch(fetchingData());

            let url = `${API_URL.FundingHandover}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}&filters[status]=${statusFilter}&filters[office_id]=${officeFilter}`;

            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      function fetchFundTransferedByCommitteeId(committee_id, office_id) {
            dispatch(fetchingData());
            let url = `${API_URL.FundingHandover}?search_term=${searchTerm}&page_no=${currentPage + 1}&page_size=${rowsPerPage}&filters[date_from]=${start_date ?? ''}&filters[date_to]=${end_date ?? ''}&filters[office_id]=${office_id}&filters[committee_id]=${committee_id}`;
            ApiRequest.get(url).then(res => {
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ data, total_items }));
            }).catch(err => {
                  dispatch(fetchDataFail(err.response?.data));
            });
      }

      async function getFundingHandoverDetail(fundHandoverId) {
            try {
                  const url = `${API_URL.FundingHandover}/${fundHandoverId}`;
                  const res = await ApiRequest.get(url);
                  return res.data.payload.data;
            }
            catch(err) {
                  console.error(err);
                  showNoti("Error to get fund transfer detail", "error");
            }
            // return data.filter((d) => d._id == id)[0]
      }

      async function createFundingHandover(data) {
            dispatch(submittingData());
            try {
                  const ott = await requestOneTimeToken();
                  const url = API_URL.FundingHandover;
                  const res = await ApiRequest.post(url, { ...data, ott });
                  showNoti('Record created!', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundingHandoverList();
                  return res.data.payload.data;
            }
            catch (err) {
                  dispatch(submitDataFail(err.message ?? 'Creating funding transfer failed!'));
                  // showNoti(err.message, 'error');
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  // !err.response && showNoti(err.message, 'error');
                  return Promise.reject(errors);
            }
      }

      async function updateFundingHandover(fundHandoverId, data) {
            dispatch(submittingData());
            try {
                  const url = `${API_URL.FundingHandover}/${fundHandoverId}`;
                  await ApiRequest.put(url, data);
                  showNoti('Funding Handover updated successfully', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundingHandoverList();
            }
            catch(err) {
                  dispatch(submitDataFail('Updating Funding Handover failed'));
                  const errors = {};
                  errors.data = err.response?.data.errors.data;
                  if(!errors.data) {
                        showNoti(err.message, 'error');
                        return Promise.reject({});
                  }

                  return Promise.reject(errors);
            }
      }

      async function getFundingHandoverTransaction(fundHandoverId) {
            try {
                  const url = `${API_URL.FundingHandover}/${fundHandoverId}/transaction-history?page_no=${currentPage+1}&page_size=${rowsPerPage}`;
                  const res = await ApiRequest.get(url);
                  const data = res.data.payload.data;
                  const { total_items } = res.data.payload.metadata;
                  dispatch(fetchDataSuccess({ total_items }));
                  return data;
            }
            catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  showNoti(errMsg, 'error');
            }
      }

      async function submitFundingHandOver(fundingHandoverId) {
            dispatch(submittingData());
            try {
                  const url = `${API_URL.FundingHandover}/${fundingHandoverId}/submit`;
                  await ApiRequest.post(url);
                  dispatch(submitDataSuccess());
                  showNoti('Fund Transfer submitted successfully', 'success');
            }
            catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  showNoti(errMsg, 'error');
                  dispatch(submitDataFail());
            }
      }

      async function approveFundingHandover(fundingHandoverId) {
            dispatch(submittingData());
            try {
                  const url = `${API_URL.FundingHandover}/${fundingHandoverId}/approve`;
                  await ApiRequest.post(url);
                  dispatch(submitDataSuccess());
                  showNoti('Fund Transfer approved successfully', 'success');
            }
            catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  showNoti(errMsg, 'error');
                  dispatch(submitDataFail());
            }
      }

      async function rejectFundingHandover(fundingHandoverId) {
            dispatch(submittingData());
            try {
                  const url = `${API_URL.FundingHandover}/${fundingHandoverId}/reject`;
                  await ApiRequest.post(url);
                  dispatch(submitDataSuccess());
                  showNoti('Fund Transfer rejected successfully', 'success');
            }
            catch(err) {
                  const errMsg = err.response?.data.errors.message || err.message;
                  showNoti(errMsg, 'error');
                  dispatch(submitDataFail());
            }
      }

      async function transferFundingToCommittee(fundingHandoverId) {
            dispatch(submittingData());
            try {
                  const url = `${API_URL.FundingHandover}/${fundingHandoverId}/transfer`;
                  await ApiRequest.post(url);
                  showNoti('Transfer fund successfully', 'success');
                  dispatch(submitDataSuccess());
                  fetchFundingHandoverList();
            }
            catch(err) {
                  dispatch(submitDataFail(err.message ?? 'Insufficient office cash!'));
                  const errors = {};
                  errors.data = err.response?.data.errors.message || err.message;
                  return Promise.reject(errors);
            }
      }

      const exportFundHandingOverData = async (office, start_date, end_date) => {
            try {
                  dispatch(submittingData());
                  const fundTransfer = moduleParam.FUNDING_TRANSFER;
                  const query = `module=${fundTransfer}&filter[office]=${office}&filter[start_date]=${start_date}&filter[end_date]=${end_date}`
                  const url = `${API_URL.DataExport}?${query}`
                  const res = await ApiRequest.post(url);
                  const response = res.data;
                  if(response.status === 'success') {
                      const data = response.payload.data;
                      const filterData = getFilteredData(data, fundingTransferColumns)
                      const sheetHeader = [fundingTransferColumns.map(item => item.label)]
      
                      let fileName = ''
      
                      fileName += fundTransfer?.charAt(0).toUpperCase() + fundTransfer.slice(1);
                      if( start_date || end_date){
                          fileName += `(${start_date} ~ ${end_date || dayjs().format('YYYY-MM-DD')})`;
                      }
                      fileName += `.xlsx`;
      
                      writeOutExcelFile(sheetHeader, filterData, fileName);
                      dispatch(submitDataSuccess());
                      showNoti('Fund Transfer list data exported successfully', 'success');
                  }
            } catch (err) {
                  const errorMessage = err.response?.data.errors.message || err.message;
                  dispatch(submitDataFail(errorMessage));
                  showNoti('Error occurred while exporting data', 'error')
                  return Promise.reject(errorMessage);
            }
      }

      function changeSearchTerm(search_key) {
            dispatch(setCurrentPage(0));
            dispatch(setSearchTerm(search_key));
      }
      function changeCurrentPage(pageNo) {
            dispatch(setCurrentPage(pageNo));
      }

      function changeRowsPerPage(rowsPerPage) {
            dispatch(setCurrentPage(0));
            dispatch(setRowsPerPage(rowsPerPage));
      }

      function changeStartDate(date) {
            dispatch(setStartDate(date));
      }
      function changeEndDate(date) {
            dispatch(setEndDate(date));
      }
      function refreshDialogState() {
            dispatch(reloadDialogState());
      }

      function changeStatusFilter(status) {
            dispatch(setStatusFilter(status));
      }

      function changeOfficeFilter(officeName) {
            dispatch(setCurrentPage(0));
            dispatch(setOfficeFilter(officeName));
      }

      function changeStartDateForCommittee(date) {
            dispatch(setStartDateForCommittee(date))
      }

      function changeEndDateForCommittee(date) {
            dispatch(setEndDateForCommittee(date))
      }

      return {
            // Actions
            fetchFundingHandoverList,
            getFundingHandoverDetail,
            getFundingHandoverTransaction,
            createFundingHandover,
            updateFundingHandover,
            refreshDialogState,
            submitFundingHandOver,
            approveFundingHandover,
            rejectFundingHandover,
            transferFundingToCommittee,
            fetchFundTransferedByCommitteeId,
            exportFundHandingOverData,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeStatusFilter,
            changeOfficeFilter,
            changeStartDateForCommittee,
            changeEndDateForCommittee,
      }
}

export default useFundingHandoverActions;