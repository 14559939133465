import { createSlice } from "@reduxjs/toolkit";

const settlementSlice = createSlice({
      name: 'settlement',
      initialState: {
            data: [],
            totalRows: 0,
            currentPage: 0,
            rowsPerPage: 5,
            searchTerm: '',
            filters: {
                  region_code: '',
                  township_code: '',
                  startDate: null,
                  endDate: null,
                  committee_id: '',
                  status: '',
            },
            isLoading: false,
            isError: false,
            errorMessage: '',
            action: {
                  isLoading: false,
                  isError: false,
                  errorMessage: ''
            },
      },
      reducers: {
            setData: (state, action) => {
                  state.data = action.payload;
            },
            resetData: (state) => {
                  state.data = [];
                  state.totalRows = 0;
            },
            setCurrentPage: (state, action) => {
                  state.currentPage = action.payload;
            },
            setRowsPerPage: (state, action) => {
                  state.rowsPerPage = action.payload;
            },
            setTotalRows: (state, action) => {
                  state.totalRows = action.payload;
            },
            setSearchTerm: (state, action) => {
                  state.searchTerm = action.payload;
            },
            setStartDate: (state, action) => {
                  state.filters.startDate = action.payload;
            },
            setEndDate: (state, action) => {
                  state.filters.endDate = action.payload;
            },
            setRegionCodeFilter: (state, action) => {
                  state.filters.region_code = action.payload;
            },
            setTownshipCodeFilter: (state, action) => {
                  state.filters.township_code = action.payload;
            },
            setStatusFilter: (state, action) => {
                  state.filters.status = action.payload;
            },
            setCommitteeFilter: (state, action) => {
                  state.filters.committee_id = action.payload;
            },
            fetchingData: (state) => {
                  state.isLoading = true;
                  state.isError = false;
            },
            fetchDataFail: (state, action) => {
                  state.isLoading = false;
                  state.isError = true;
                  state.errorMessage = action.payload;
            },
            fetchDataSuccess: (state, action) => {
                  action?.payload?.data && (state.data = action.payload.data);
                  action?.payload?.total_items && (state.totalRows = action.payload.total_items);
                  state.isLoading = false;
                  state.isError = false;
            },
            submittingData: (state) => {
                  state.action.isLoading = true;
                  state.action.isError = false;
            },
            submitDataSuccess: (state) => {
                  state.action.isLoading = false;
                  state.action.isError = false;
            },
            submitDataFail: (state, action) => {
                  state.action.isLoading = false;
                  state.action.isError = true;
                  state.action.errorMessage = action.payload;
            },
            reloadDialogState: (state) => {
                  state.action = { isLoading: false, isError: false, errorMessage: '' }
            }
      }
});

export const {
      setData,
      resetData,
      setCurrentPage,
      setRowsPerPage,
      setTotalRows,
      setSearchTerm,
      setStartDate,
      setEndDate,
      setRegionCodeFilter,
      setTownshipCodeFilter,
      setStatusFilter,
      setCommitteeFilter,
      // Fetch List View
      fetchingData,
      fetchDataSuccess,
      fetchDataFail,
      // Dialog Actions
      submittingData,
      submitDataSuccess,
      submitDataFail,
      reloadDialogState
} = settlementSlice.actions;

export default settlementSlice.reducer;