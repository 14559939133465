// Libries
import { useSelector } from 'react-redux';
// Local
import useLayoutSettingActions from './_actions';

// Register all state here from the reducer to export
export const useLayoutSetting = () => {
      const { setAsMobileSize, openDrawer } = useLayoutSettingActions();

      const isMobile = useSelector(state => state.layout.isMobile)
      const variant = useSelector(state => state.layout.variant)
      const width = useSelector(state => state.layout.width)
      const isCollapse = useSelector(state => state.layout.isCollapse)
      const isOpenDrawer = useSelector(state => state.layout.isOpenDrawer)

      return {
            // Actions
            setAsMobileSize,
            openDrawer,
            // States
            isMobile,
            variant,
            width,
            isCollapse,
            isOpenDrawer
      }
};


export { default as layoutSettingReducer } from './_reducers';