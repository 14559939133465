import { useDispatch, useSelector } from "react-redux";
import ApiRequest from "../../utils/apiRequest";
import {
    setData,
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    setSearchTerm,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail
} from "./_reducers";
import { API_URL } from '../../configs/_api';

const useRolesActions = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector(state => state.portalUserRole.currentPage);
    const rowsPerPage = useSelector(state => state.portalUserRole.rowsPerPage);

    function fetchRolesList() {
        dispatch(fetchingData());

        let get_roles_url = `${API_URL.PortalUserRole}?page_no=${currentPage + 1}&page_size=${rowsPerPage}`;
        ApiRequest.get(get_roles_url).then(res => {
            const { data, total_items } = res.data;
            dispatch(setData(data));
            dispatch(setTotalRows(total_items ?? 0));
            dispatch(fetchDataSuccess({ data, total_items: total_items ?? 0 }));
        }).catch(err => {
            console.log(err);
            dispatch(fetchDataFail(err.response?.data));
        });
    }

    async function getSchemaForCreate() {
        let get_schema_for_create = `${API_URL.PortalUserRole}/getSchemaForRole`;
        try {
            let res = await ApiRequest.get(get_schema_for_create);
            const schema = res.data.payload.data;
            return schema;
        } catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `Fill To fetch Standard Schema!`;
            return Promise.reject(errMsg);
        }
    }

    async function addRole(role_data) {
        let add_role_url = API_URL.PortalUserRole + "/add";
        try {
            let res = await ApiRequest.post(add_role_url, role_data,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            const roleAdded = res.data;
            return roleAdded;
        } catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `Fill To add user role!`;
            return Promise.reject(errMsg);
        }
    }

    async function updateRole(role_data) {
        let update_role_url = API_URL.PortalUserRole + "/edit";
        try {
            let res = await ApiRequest.post(update_role_url, role_data,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            return res;
        } catch (err) {
            const errMsg = err.response?.data.errors?.message ?? `Fill To add user role!`;
            return Promise.reject(errMsg);
        }
    }


    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setSearchTerm(searchTerm));
    }

    return {
        fetchRolesList,
        getSchemaForCreate,
        addRole,
        updateRole,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm
    }
}

export default useRolesActions;