import { createSlice } from "@reduxjs/toolkit";
import localStorage from 'redux-persist/lib/storage'
import tokenConfig from '../../configs/_token';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {},
        isAuth: false,
        permission_schema: {},
        permitted_actions: {},
        feRoutes: {},
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMsg: '',
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        loginSuccess: (state, action) => {
            state.data = action.payload.userData;
            state.permission_schema = action.payload.permission_schema;
            state.permitted_actions = action.payload.actions;
            state.feRoutes = action.payload.feRoutes;
            localStorage.setItem(tokenConfig.ACCESS_TOKEN_NAME, action.payload.access_token);
            localStorage.setItem(tokenConfig.REFRESH_TOKEN_NAME, action.payload.refresh_token);
            state.defaultPage = action.payload.permission_schema.filter(i => i.isDefault == true)[0].fe_route
            state.isAuth = true;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
        },
        fetchDataSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
        },
        verifyMailSuccess: (state)=>{
            state.isLoading = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.errorMsg = action.payload
        }
    }
});

export const {
    setData,
    setAuth,
    fetchingData, 
    fetchDataSuccess, 
    fetchDataFail,
    loginSuccess,
    verifyMailSuccess
} = userSlice.actions;

export default userSlice.reducer;