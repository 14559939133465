import ApiRequest from "../../utils/apiRequest";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../configs/_api";
import { 
    setCurrentPage, 
    setRowsPerPage, 
    setSearchTerm,
    setRegionCodeFilter,
    setTownshipCodeFilter,
    setVillageTractCodeFilter,
    fetchingData, 
    fetchDataSuccess, 
    fetchDataFail,

    fetchingDataForDownload,
    fetchingDataForDownloadSuccess,
    fetchingDataForDownloadFail,

    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState,
} from "./_reducers";
import { useRegion } from "../regions";
import { useTownship } from "../township";
import { useVillageTract } from "../village_tracts";
import * as XLSX from 'xlsx';

const useVillageActions = () => {
    const dispatch = useDispatch();
    const { getRegionList } = useRegion();
    const { getTownshipList } = useTownship();
    const { getVillageTractList } = useVillageTract();

    const currentPage = useSelector(state => state.village.currentPage);
    const rowsPerPage = useSelector(state => state.village.rowsPerPage);
    const searchTerm = useSelector(state => state.village.searchTerm);
    const regionCodeFilter = useSelector(state => state.village.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.village.filters.township_code);
    const villageTractCodeFilter = useSelector(state => state.village.filters.villageTract_code);

    function fetchVillageList() {
        dispatch(fetchingData());

        const getVillageUrl = `${API_URL.Village}?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[region_code]=${regionCodeFilter}&filters[township_code]=${townshipCodeFilter}&filters[villageTract_code]=${villageTractCodeFilter}`;
        ApiRequest.get(getVillageUrl).then(res => {
            const data = res.data.payload.data;
            const {total_items} = res.data.payload.metadata;
            
            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        });
    }

    async function getVillageList() {
        dispatch(fetchingDataForDownload());
        try {
            const url = API_URL.Village;
            const res = await ApiRequest.get(url);
            dispatch(fetchingDataForDownloadSuccess());
            return res.data.payload.data;
        }
        catch(err) {
            console.error(err);
            dispatch(fetchingDataForDownloadFail(err.message ?? "Error occur while downloading data"));
            return [];
        }
    }

    async function downloadVillageFileUploadTemplate(columns=[]) {
        const workBook = XLSX.utils.book_new();

        const dataSheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(dataSheet, [columns]);
        XLSX.utils.book_append_sheet(workBook, dataSheet, 'data');

        const regionMetaSheet = await getRegionMetaSheet();
        XLSX.utils.book_append_sheet(workBook, regionMetaSheet, '(meta) region');

        const townshipMetaSheet = await getTownshipMetaSheet();
        XLSX.utils.book_append_sheet(workBook, townshipMetaSheet, '(meta) township');

        const villageTractMetaSheet = await getVillageTractMetaSheet();
        XLSX.utils.book_append_sheet(workBook, villageTractMetaSheet, '(meta) village tract');

        XLSX.writeFile(workBook, 'villageUploadTemplate.xlsx');

        async function getRegionMetaSheet() {
            const regionSheet = XLSX.utils.json_to_sheet([]);
            const regions = (await getRegionList()) || [];
            XLSX.utils.sheet_add_aoa(regionSheet, [['id', 'mm_name', 'en_name', 'code']]);
            const filteredTownship = regions.map(rg=> ({ id: rg._id, mm_name: rg.mm_name, en_name: rg.en_name, code: rg.code }));
            XLSX.utils.sheet_add_json(regionSheet, filteredTownship, { origin: 'A2', skipHeader: true });

            return regionSheet;
        }

        async function getTownshipMetaSheet() {
            const townshipSheet = XLSX.utils.json_to_sheet([]);
            const townships = (await getTownshipList()) || [];
            XLSX.utils.sheet_add_aoa(townshipSheet, [['id', 'mm_name', 'en_name','region', 'code']]);
            const filteredVillageTract = townships.map(rg=> ({ id: rg._id, mm_name: rg.mm_name, en_name: rg.en_name, region: rg.region_mm, code: rg.code,  }));
            XLSX.utils.sheet_add_json(townshipSheet, filteredVillageTract, { origin: 'A2', skipHeader: true });

            return townshipSheet;
        }

        async function getVillageTractMetaSheet() {
            const villageTractSheet = XLSX.utils.json_to_sheet([]);
            const villageTracts = (await getVillageTractList()) || [];
            XLSX.utils.sheet_add_aoa(villageTractSheet, [['id', 'name', 'region', 'township']]);
            const filteredVillageTracts = villageTracts.map(vt => ({ id: vt._id, name: vt.mm_name, region: vt.region.mm_name, township: vt.township.mm_name }));
            XLSX.utils.sheet_add_json(villageTractSheet, filteredVillageTracts, { origin: 'A2', skipHeader: true });

            return villageTractSheet;
        }

    }

    async function createMultipleVillage(file) {
        const url = `${API_URL.Village}/batch`;
        const data = new FormData();
        data.append('file', file);

        try {
              dispatch(submittingData());
              const res = await ApiRequest.post(url, data);
              dispatch(submitDataSuccess());
              return res.data;
          }
          catch(err) {
              const errMsg = err.response?.data.errors.message || err.message;
              dispatch(submitDataFail());
              return Promise.reject(errMsg);
          }
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }

    function changeRegionCodeFilter(code) {
        dispatch(setSearchTerm(''));
        dispatch(setRegionCodeFilter(code));
    }

    function changeTownshipCodeFilter(code) {
        dispatch(setSearchTerm(''));
        dispatch(setTownshipCodeFilter(code));
    }

    function changeVillageTractCodeFilter(code) {
        dispatch(setSearchTerm(''));
        dispatch(setVillageTractCodeFilter(code));
    }

    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        fetchVillageList,
        getVillageList,
        downloadVillageFileUploadTemplate,
        createMultipleVillage,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        refreshDialogState,
    }
}

export default useVillageActions;