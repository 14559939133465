import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
      Chart as ChartJS,
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
    } from 'chart.js';

ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );

function BarChart({data, option}) {
      const options = {
            responsive: true,
            plugins: {
                  legend: { position: 'top' },
            },
            ...option,
      };

      return (
            <Bar
                  data={data}
                  options={options}
            />
      )
}

export default BarChart;