// Libries
import { useSelector } from 'react-redux';
// Local
import useThemeSettingActions from './_actions';

// Register all state here from the reducer to export
export const useThemeSetting = () => {
      const { changeMode, toggleLeftSideBar, openDrawer } = useThemeSettingActions();

      const mode = useSelector(state => state.theme.mode)
      const isCollapseSideBar = useSelector(state => state.theme.collapseSideBar)
      const isOpenDrawer = useSelector(state => state.theme.isOpenDrawer)
      const width = useSelector(state => state.theme.width)

      return {
            // Actions
            changeMode,
            toggleLeftSideBar,
            openDrawer,
            // States
            mode,
            isCollapseSideBar,
            isOpenDrawer,
            width
      }
};


export { default as themeSettingReducer } from './_reducers';