import { useSelector } from "react-redux";
import useCommitteeActions from "./_actions";

export const useCommittee = () => {
    const {
        fetchSettlementListByCommitteeId,
        fetchCommitteeList,
        getCommitteeListByLocation,
        getCommitteeBalance,
        getCommitteeActiveMemberCount,
        addCommittee,
        createMultipleCommittees,
        getRelatedMemberList,
        resetCommitteePin,
        getCommitteeDetail,
        activateCommittee,
        changeToVerifying,
        deactivateCommittee,
        updateCommittee,
        downloadCommitteeFileUploadTemplate,
        changeCommitteePhoneNumber,
        getCommitteeDashboardData,
        exportCommitteeData,

        changeCurrentPage, 
        changeRowsPerPage,

        changeSearchTerm,
        changeStatusFilter,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        changeVillageCodeFilter,
        refreshDialogState,

        changeSettlementSearchTerm,
        changeSettlementCurrentPage,
        changeSettlementRowsPerPage,
    } = useCommitteeActions();

    const committees = useSelector(state => state.committee.data);
    const currentPage = useSelector(state => state.committee.currentPage);
    const totalRows = useSelector(state => state.committee.totalRows);
    const rowsPerPage = useSelector(state => state.committee.rowsPerPage);
    const isLoading = useSelector(state => state.committee.isLoading);
    const isError = useSelector(state => state.committee.isError);
    const errorMessage = useSelector(state =>state.committee.errorMessage);
    const searchTerm = useSelector(state => state.committee.searchTerm);
    const statusFilter = useSelector(state => state.committee.filters.status);
    const regionCodeFilter = useSelector(state => state.committee.filters.region_code);
    const townshipCodeFilter = useSelector(state => state.committee.filters.township_code);
    const villageTractCodeFilter = useSelector(state => state.committee.filters.village_tract_code);
    const villageCodeFilter = useSelector(state => state.committee.filters.village_code);
    const apiAction = useSelector(state => state.committee.action);

    const settlements = useSelector(state => state.committee.settlement.data);
    const settlementCurrentPage = useSelector(state => state.committee.settlement.currentPage);
    const settlementTotalRows = useSelector(state => state.committee.settlement.totalRows);
    const settlementRowsPerPage = useSelector(state => state.committee.settlement.rowsPerPage);
    const settlementSearchTerm = useSelector(state => state.committee.settlement.searchTerm);

    return {
        //state
        committees,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        statusFilter,
        regionCodeFilter,
        townshipCodeFilter,
        villageTractCodeFilter,
        villageCodeFilter,
        apiAction,

        settlements,
        settlementCurrentPage,
        settlementTotalRows,
        settlementRowsPerPage,
        settlementSearchTerm,

        //actions
        getCommitteeDashboardData,
        changeCommitteePhoneNumber,
        fetchCommitteeList,
        getCommitteeListByLocation,
        addCommittee,
        updateCommittee,
        createMultipleCommittees,
        getCommitteeActiveMemberCount,
        getRelatedMemberList,
        getCommitteeBalance,
        resetCommitteePin,
        getCommitteeDetail,
        deactivateCommittee,
        activateCommittee,
        changeToVerifying,
        changeCurrentPage,
        changeRowsPerPage,
        changeSearchTerm,
        changeStatusFilter,
        changeRegionCodeFilter,
        changeTownshipCodeFilter,
        changeVillageTractCodeFilter,
        changeVillageCodeFilter,
        refreshDialogState,
        downloadCommitteeFileUploadTemplate,
        exportCommitteeData,

        fetchSettlementListByCommitteeId,
        changeSettlementCurrentPage,
        changeSettlementRowsPerPage,
        changeSettlementSearchTerm
    }
}

export {default as committeeReducer} from './_reducers';