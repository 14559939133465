import { useSelector } from "react-redux";
import useProductCatalogActions from './_actions';

export const useProductCatalog = () => {
      const {
            getAllProductCatalog,
            getProductCatalogList,
            createProductCatalog,
            getProductDetails,
            getCatalogCode,
            updateProductCatalog,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState,
            changeCategoryFilter,
            uploadProductImage,
            changeStatusFilter,
            dataSubmitting,
            deleteProduct ,
            getProductCatalogListByCategory,
            getClusterList
      } = useProductCatalogActions();

      const productCatalog = useSelector(state => state.productCatalog.data);
      const currentPage = useSelector(state => state.productCatalog.currentPage);
      const totalRows = useSelector(state => state.productCatalog.totalRows);
      const rowsPerPage = useSelector(state => state.productCatalog.rowsPerPage);
      const searchTerm = useSelector(state => state.productCatalog.searchTerm);

      const isLoading = useSelector(state => state.productCatalog.isLoading);
      const isError = useSelector(state => state.productCatalog.isError);
      const errorMessage = useSelector(state => state.productCatalog.errorMessage);
      const apiAction = useSelector(state => state.productCatalog.action);
      const dialogStates = useSelector(state => state.productCatalog.dialogStates);
      const categoryFilter = useSelector(state => state.productCatalog.filters.category_id);
      const statusFilter = useSelector(state => state.productCatalog.filters.status);

      return {
            //state
            productCatalog,
            isLoading,
            isError,
            errorMessage,
            currentPage,
            rowsPerPage,
            totalRows,
            searchTerm,
            dialogStates,
            apiAction,
            categoryFilter,
            statusFilter,
            
            //actions
            getAllProductCatalog,
            getProductCatalogList,
            createProductCatalog,
            getProductDetails,
            getCatalogCode,
            updateProductCatalog,
            changeCurrentPage,
            changeRowsPerPage,
            changeSearchTerm,
            refreshDialogState,
            changeCategoryFilter,
            uploadProductImage,
            changeStatusFilter,
            dataSubmitting,
            deleteProduct,
            getProductCatalogListByCategory,
            getClusterList
      }
}

export { default as productCatalogReducer } from './_reducers';