
import { useDispatch } from 'react-redux';
import { API_URL } from '../../configs/_api';
import { useNoti } from '../../providers';
import ApiRequest from '../../utils/apiRequest';
import {
    fetchingData,
    fetchDataFail,
    fetchDataSuccess,
    submittingData,
    submitDataFail,
    submitDataSuccess,
    reloadDialogState
} from './_reducers';

const useAppVersionActions = () => {
    const dispatch = useDispatch();
    const { showNoti } = useNoti();

    async function fetchAppVersionList() {
        dispatch(fetchingData());
        try {
            const url = API_URL.AppVersion;
            const res = await ApiRequest.get(url);
            dispatch(fetchDataSuccess(res.data.payload.data));
        }
        catch(err) {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        }
    }

    async function updateAppVersionInfo(versionId, version) {
        dispatch(submittingData());
        try {
            const url = `${API_URL.AppVersion}/${versionId}`;
            await ApiRequest.put(url, version);
            dispatch(submitDataSuccess());
            fetchAppVersionList();
            showNoti('App version info updated successfully', 'success');
            return Promise.resolve();
        }
        catch(err) {
            console.error(err);
            dispatch(submitDataFail(`Update fail! ${err.message}`));
            return Promise.reject();
        }
    }

    function refreshDialogState() {
        dispatch(reloadDialogState());
    }

    return {
        fetchAppVersionList,
        updateAppVersionInfo,
        refreshDialogState
    }
}

export default useAppVersionActions;