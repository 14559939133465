export const API_URL = Object.freeze({
      Region: `v1/regions`,
      Township: `v1/townships`,
      Locations: `v1/locations`,
      Office: `v1/offices`,
      VillageTract: `v1/village-tracts`,
      Village: `v1/villages`,
      Committee: `v1/committees`,
      StandardSchema: `v1/schema`,
      PortalUserRole: `v1/protalUserRole`,
      Member: `v1/members`,
      Deposit: `v1/deposits`,
      FundReceivingInvoice: `v1/fundReceivingInvoice`,
      FundAllocation: `v1/fundAllocation`,

      Login: `v1/portal-users/login`,
      ChangePassword: `v1/portal-users/change-password`,
      SendMail: `v1/portal-users/forgot-password/send-mail`,
      VerifyToken: `v1/portal-users/forgot-password/verify-token`,
      ResetPassword: `v1/portal-users/reset-password`,
      PortalUser: `v1/portal-users`,
      Role: `v1/protalUserRole`,
      Shop: `v1/shops`,
      AppVersion: `v1/app-versions`,
      Loan: `v1/loans`,
      Otp: `v1/otps`,
      Settlement: `v1/settlements`,
      Invoice: `v1/invoices`,
      ProductCategory: `v1/productCategory`,
      ProductCatalog: `v1/productCatalog`,
      RevenueTransfers: `v1/revenue-transfers`,
      Dashboard: `v1/dashboards`,
      FundReceiving: `v1/fundingReceived`,
      FundingHandover: `v1/fundingHandover`,
      FundingUser: `v1/fundingUser`,
      Logout: 'v1/portal-users/logout',
      OneTimeToken: 'v1/one-time-tokens',
      Log: 'v1/logs',
      DataExport: 'v1/data-exports',
      StaticPage: 'v1/static-pages',
      Donor: 'v1/donors',
      Article: 'v1/articles',
      ArticleCategory: 'v1/article_categories',
      Maintenance : 'v1/maintenance',
      EcommerceOrder: 'v1/ecommerce-orders',
      Cluster: 'v1/clusters'
});

export const API_BASE_URLS = {
      local: 'http://localhost:3000/',
      production: 'https://api.tunyat.com/',
      staging: 'https://api4test.tunyat.com/',
      development: 'http://api4dev.tunyat.com/'
}

export const API_BASE_URL = API_BASE_URLS[process.env.REACT_APP_ENV];