
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../configs/_api";
import ApiRequest from "../../../utils/apiRequest";
import {
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setStatusFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    // submittingData,
    // submitDataFail,
    // submitDataSuccess
} from "./_reducers";

const useLoanContractDetailActions = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector(state => state.loan_detail.currentPage);
    const rowsPerPage = useSelector(state => state.loan_detail.rowsPerPage);
    const searchTerm = useSelector(state => state.loan_detail.searchTerm);
    const statusFilter = useSelector(state => state.loan_detail.filters.status);

    function fetchLoanContractDetailList(mainContractId) {
        const url = `${API_URL.Loan}/${mainContractId}/contracts?search_term=${searchTerm}&page_no=${currentPage+1}&page_size=${rowsPerPage}&filters[status]=${statusFilter}`;
        dispatch(fetchingData());
        ApiRequest.get(url).then(res => {
            const {data} = res.data.payload;
            const {total_items} = res.data.payload.metadata;
            dispatch(fetchDataSuccess({data, total_items}));
        }).catch(err => {
            console.error(err);
            dispatch(fetchDataFail(err.message));
        })
    }

    function changeSearchTerm(searchTerm) {
        dispatch(setCurrentPage(0));
        dispatch(setSearchTerm(searchTerm));
    }

    function changeStatusFilter(status) {
        dispatch(setStatusFilter(status));
    }

    function changeCurrentPage(pageNo) {
        dispatch(setCurrentPage(pageNo));
    }

    function changeRowsPerPage(rowsPerPage) {
        dispatch(setCurrentPage(0));
        dispatch(setRowsPerPage(rowsPerPage));
    }

    return {
        fetchLoanContractDetailList,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
        changeStatusFilter
    }
}

export default useLoanContractDetailActions;