import { createSlice } from "@reduxjs/toolkit";

const donorSlice = createSlice({
    name: 'donor',
    initialState: {
        data: [],

        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,

        searchTerm: '',

        isLoading: false,
        isError: false,
        errorMessage: '',
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    },

    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            action.payload.data && (state.data = action.payload.data);
            action.payload.total_items && (state.totalRows = action.payload.total_items);
            state.isLoading = false;
            state.isError = false;
        },
        fetchingSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
            state.action.errorMessage = '';
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        }
    }
});

export const {
    setData,
    setSearchTerm,
    setRowsPerPage,
    setCurrentPage,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataSuccess,
    submitDataFail
} = donorSlice.actions;

export default donorSlice.reducer;