import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EmptyBox from "../Box/EmptyBox";
import ErrorBox from "../Box/ErrorBox";
import { Icon, IconButton, Skeleton, Tooltip } from "@mui/material";
// import { Box } from '@mui/system';
// import Lottie from 'react-lottie-player'
// import AnimiJSON from './rippleAnimiJson.json';
import { useNewRows } from "../../features";

const TableSkeleton = () => {
  return (
    <>
      <Skeleton height={60} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
};

/**
 * # Table
 * @param {Boolean} isError
 * @param {String} errorMsg
 * @param {Event} onError
 * @param {Number} rowsPerPage
 * @param {Number} page
 * @param {Number} totalRow
 * @param {Object} columnsConfig
 * @param {Object} rowData
 * @param {Event} handleChangePage
 * @param {Event} handleChangeRowsPerPage
 * @param {Event} handleOnRowClick
 */
export default function StickyHeadTable(props) {
  const _handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };
  const _handleChangeRowsPerPage = (event) => {
    props.handleChangeRowsPerPage(+event.target.value, 0);
  };
  const { newRows, removeNewRow } = useNewRows();

  function compare(id) {
    const res = newRows.filter((i) => i == id).length;
    return res > 0 ? true : false;
  }
  function handleOnRowClick(e, row) {
    e.stopPropagation();
    removeNewRow(row._id);
    props.handleOnRowClick(row);
  }
  return (
    <Paper elevation={0} sx={{ width: "100%", overflow: "hidden" }}>
      {/* <NewRowIndicator /> */}
      {props.isError ? (
        <ErrorBox onError={props.onError} errorMsg={props.errorMsg} />
      ) : props.isLoading ? (
        <TableSkeleton />
      ) : props?.rowData?.length > 0 ? (
        <>
          <Table stickyHeader aria-label="data table">
            <TableHead>
              <TableRow>
                {props.columnsConfig.map((column, idx) => (
                  <TableCell
                    key={"table_header_cell_" + idx + "_" + column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody sx={{ position: "relative" }}>
              {props.rowData.map((row, index) => {
                return (
                  <React.Fragment key={"table_body_row_" + index}>
                    {compare(row._id) && (
                      <img
                        style={{
                          position: "absolute",
                          marginTop: 0,
                          marginRight: 0,
                          right: 0,
                        }}
                        width={45}
                        src={"/images/new-tag.png"}
                      />
                    )}
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={"tableRow_" + index}
                      onClick={(e) => handleOnRowClick(e, row)}
                      sx={{ cursor: "pointer" }}
                    >
                      {props.columnsConfig.map((column, idx) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={"tableCell_" + index + "_" + idx}
                            align={column.align}
                          >
                            {column.id !== "actions" &&
                            column.format &&
                            typeof value === "number"
                              ? column.format(value)
                              : value}
                            {column.id === "actions" &&
                              column.actions &&
                              column.actions.length > 0 &&
                              column.actions.map((action, index) => (
                                <Tooltip
                                  key={"tooltip_" + index.toString()}
                                  title={action.tootlTip}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      action.onActionClick(row);
                                    }}
                                  >
                                    <Icon>{action.icon}</Icon>
                                  </IconButton>
                                </Tooltip>
                              ))}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
          {!props.noPagination ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={props.totalRow}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={_handleChangePage}
              onRowsPerPageChange={_handleChangeRowsPerPage}
            />
          ) : null}
        </>
      ) : (
        <EmptyBox />
      )}
    </Paper>
  );
}
