import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Tooltip, Button } from "@mui/material";
import React from "react";

/**
 * 
 * @param {Function} onClearFilter
 * @returns 
 */

const ClearFilterButton = (props) => {
    return (
        <Tooltip title={'Clear All Filter'}>
            <Button onClick={props.onClearFilter}>
                      <FilterAltOffIcon color='red' />
            </Button>
        </Tooltip>
    );
}

export default ClearFilterButton;