import { useSelector } from "react-redux";
import useFundingHandoverActions from "./_actions";

export const useFundingHandover = () => {
      const {
            // Actions
            fetchFundingHandoverList,
            getFundingHandoverDetail,
            getFundingHandoverTransaction,
            createFundingHandover,
            updateFundingHandover,
            refreshDialogState,
            submitFundingHandOver,
            approveFundingHandover,
            rejectFundingHandover,
            transferFundingToCommittee,
            fetchFundTransferedByCommitteeId,
            exportFundHandingOverData,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeStatusFilter,
            changeOfficeFilter,
            changeStartDateForCommittee,
            changeEndDateForCommittee,
      } = useFundingHandoverActions();

      const fundingHandover = useSelector(state => state.fundingHandover.data);
      const currentPage = useSelector(state => state.fundingHandover.currentPage);
      const totalRows = useSelector(state => state.fundingHandover.totalRows);
      const rowsPerPage = useSelector(state => state.fundingHandover.rowsPerPage);
      const isLoading = useSelector(state => state.fundingHandover.isLoading);
      const isError = useSelector(state => state.fundingHandover.isError);
      const errorMessage = useSelector(state => state.fundingHandover.errorMessage);
      const searchTerm = useSelector(state => state.fundingHandover.searchTerm);
      const start_date = useSelector(state => state.fundingHandover.filters.start_date);
      const end_date = useSelector(state => state.fundingHandover.filters.end_date);
      const statusFilter = useSelector(state => state.fundingHandover.filters.status);
      const officeFilter = useSelector(state => state.fundingHandover.filters.office_id);
      const apiAction = useSelector(state => state.fundingHandover.action);

      const dialogStates = useSelector(state => state.fundingHandover.dialogStates);

      const startDateForCommittee = useSelector(state => state.fundingHandover.filters.startDateForCommittee);
      const endDateForCommittee = useSelector(state => state.fundingHandover.filters.endDateForCommittee);

      return {
            //state
            fundingHandover,
            currentPage,
            totalRows,
            rowsPerPage,
            isLoading,
            isError,
            errorMessage,
            searchTerm,

            start_date,
            end_date,
            statusFilter,
            officeFilter,
            apiAction,
            dialogStates,
            startDateForCommittee,
            endDateForCommittee,

            //actions
            fetchFundingHandoverList,
            getFundingHandoverDetail,
            getFundingHandoverTransaction,
            createFundingHandover,
            updateFundingHandover,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            changeStatusFilter,
            changeOfficeFilter,
            refreshDialogState,
            submitFundingHandOver,
            approveFundingHandover,
            rejectFundingHandover,
            transferFundingToCommittee,
            fetchFundTransferedByCommitteeId,
            changeStartDateForCommittee,
            changeEndDateForCommittee,
            exportFundHandingOverData
      }
}

export { default as fundingHandoverReducers } from './_reducers';