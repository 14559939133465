import { createSlice } from "@reduxjs/toolkit";

const shopSlice = createSlice({
    name: 'shop',
    initialState: {
        data: [],
        totalRows: 0,
        currentPage: 0, 
        rowsPerPage: 5,
        searchTerm: '',
        filters: {
            status: 'active',
            region_id: '',
            township_id: '',
        },
        isLoading: false,
        isError: false,
        errorMessage: '',
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        }
    }, 
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setRegionIdFilter: (state, action) => {
            state.filters.region_id = action.payload;
        },
        setTownshipIdFilter: (state, action) => {
            state.filters.township_id = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.filters.status = action.payload
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state, action) => {
            action.payload.data && (state.data = action.payload.data);
            action.payload.total_items && (state.totalRows = action.payload.total_items);

            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMessage = action.payload;
        },
        submittingData: (state) => {
            state.action.isLoading = true;
            state.action.isError = false;
            state.action.errorMessage = '';
        },
        submitDataSuccess: (state) => {
            state.action.isLoading = false;
            state.action.isError = false;
        },
        submitDataFail: (state, action) => {
            state.action.isLoading = false;
            state.action.isError = true;
            state.action.errorMessage = action.payload;
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' };
        }
    }
});

export const {
    setData,
    setTotalRows,
    setCurrentPage,
    setRowsPerPage,
    setSearchTerm,
    setRegionIdFilter,
    setTownshipIdFilter,
    setStatusFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    submittingData,
    submitDataSuccess,
    submitDataFail,
    reloadDialogState
} = shopSlice.actions;

export default shopSlice.reducer;