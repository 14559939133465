import { useSelector } from "react-redux";
import useDonorActions from "./_actions";

export const useDonor = () => {
    const {
        fetchDonorList,
        getDonorDetail,
        createNewDonor,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage,
    } = useDonorActions();

    const donors = useSelector(state => state.donor.data);
    const currentPage = useSelector(state => state.donor.currentPage);
    const totalRows = useSelector(state => state.donor.totalRows);
    const rowsPerPage = useSelector(state => state.donor.rowsPerPage);
    const isLoading = useSelector(state => state.donor.isLoading);
    const isError = useSelector(state => state.donor.isError);
    const errorMessage = useSelector(state => state.donor.errorMessage);
    const searchTerm = useSelector(state => state.donor.searchTerm);
    const apiAction = useSelector(state => state.donor.action);

    return {
        donors,
        currentPage,
        totalRows,
        rowsPerPage,
        isLoading,
        isError,
        errorMessage,
        searchTerm,
        apiAction,

        fetchDonorList,
        getDonorDetail,
        createNewDonor,
        changeSearchTerm,
        changeCurrentPage,
        changeRowsPerPage
    }
}

export { default as donorReducer } from './_reducers'