import { createSlice } from "@reduxjs/toolkit";

const officeSlice = createSlice({
    name: 'office',
    initialState: {
        data: [],
        totalRows: 0,
        currentPage: 0,
        rowsPerPage: 5,
        searchTerm: '',
        filters: {
            region_id: '',
        },
        action: {
            isLoading: false,
            isError: false,
            errorMessage: ''
        },
        isLoading: false,
        isError: false,
        errorMsg: '',
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setRegionIdFilter: (state, action) => {
            state.filters.region_id = action.payload;
        },
        fetchingData: (state) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchDataSuccess: (state) => {
            state.isLoading = false;
            state.isError = false;
        },
        fetchDataFail: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.errorMsg = action.payload
        },
        reloadDialogState: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
        dialogActionStarted: (state) => {
            state.action = { isLoading: true, isError: false, errorMessage: '' }
        },
        dialogActionSuccess: (state) => {
            state.action = { isLoading: false, isError: false, errorMessage: '' }
        },
        dialogActionFail: (state, action) => {
            state.action = { isLoading: false, isError: true, errorMessage: action.payload }
        },
    }
});

export const {
    setData,
    setCurrentPage,
    setRowsPerPage,
    setTotalRows,
    setSearchTerm,
    setRegionIdFilter,
    fetchingData,
    fetchDataSuccess,
    fetchDataFail,
    reloadDialogState,
    dialogActionStarted,
    dialogActionSuccess,
    dialogActionFail
} = officeSlice.actions;

export default officeSlice.reducer;