import { useSelector } from "react-redux";
import useFundReceivingInvoiceActions from "./_actions";

export const useFundReceivingInvoice = () => {
      const {
            // Actions
            getFundReceivingInvoiceListByContractId,
            fetchFundReceivingInvoiceList,
            fetchFundReceivingInvoiceTransaction,
            getFundReceivingInvoiceDetail,
            createFundReceivingInvoice,
            editFundReceivingInvoice,
            refreshDialogState,

            // Paganation
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
      } = useFundReceivingInvoiceActions();

      const fundReceivingInvoice = useSelector(state => state.fundReceivingInvoice.data);
      const currentPage = useSelector(state => state.fundReceivingInvoice.currentPage);
      const totalRows = useSelector(state => state.fundReceivingInvoice.totalRows);
      const rowsPerPage = useSelector(state => state.fundReceivingInvoice.rowsPerPage);
      const isLoading = useSelector(state => state.fundReceivingInvoice.isLoading);
      const isError = useSelector(state => state.fundReceivingInvoice.isError);
      const errorMessage = useSelector(state => state.fundReceivingInvoice.errorMessage);
      const searchTerm = useSelector(state => state.fundReceivingInvoice.searchTerm);
      const start_date = useSelector(state => state.fundReceivingInvoice.filters.start_date);
      const end_date = useSelector(state => state.fundReceivingInvoice.filters.end_date);
      const apiAction = useSelector(state => state.fundReceivingInvoice.action);

      const dialogStates = useSelector(state => state.deposit.dialogStates);

      return {
            //state
            fundReceivingInvoice,
            currentPage,
            totalRows,
            rowsPerPage,
            isLoading,
            isError,
            errorMessage,
            searchTerm,

            start_date,
            end_date,
            apiAction,
            dialogStates,

            //actions
            getFundReceivingInvoiceListByContractId,
            fetchFundReceivingInvoiceList,
            fetchFundReceivingInvoiceTransaction,
            getFundReceivingInvoiceDetail,
            createFundReceivingInvoice,
            editFundReceivingInvoice,
            changeSearchTerm,
            changeCurrentPage,
            changeRowsPerPage,
            changeStartDate,
            changeEndDate,
            refreshDialogState
      }
}

export { default as fundReceivingInvoiceReducers } from './_reducers';