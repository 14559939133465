import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,  Slider, Stack, Typography, } from '@mui/material'
import Cropper from 'react-easy-crop'
import getCroppedImg from './CropImage.util';
import UpdateIcon from '@mui/icons-material/Update';
import StyledIconButton from '../../Buttons/StyledIconButton'; 
import TextBox from '../TextBox';


/**
 * # Image Resizer Dialog
 * @augments File file
 * @returns file
 */

const ImageResizerDialog = React.memo(function ImageResizerDialog(props) {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [imageUrl, setImageUrl] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.file) {
            const objectURL = URL.createObjectURL(props.file);
            setImageUrl(objectURL);
            return () => {
                URL.revokeObjectURL(objectURL);
            };
        } else {
            setImageUrl(null);
        }
        setZoom(1);
        setRotation(0);
    }, [props.file]);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        setLoading(true);
        try {
            const croppedImage = await getCroppedImg(
                imageUrl,
                croppedAreaPixels,
                rotation
            );
            props.croppedImage(croppedImage);
            props.handleClose();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }, [imageUrl, croppedAreaPixels, rotation, props]);

    const handleOnZoomChange = useCallback((event, value) => {
        setZoom(value);
    }, []);
    
    const handleOnRotationChange = useCallback((event) => {
        const value = event.target.value;
        if(value >= 360 || !value) {
            setRotation(0);
        }else {
            setRotation(value);
        }
    }, []);
    
    const rotate90OnClick = useCallback(() => {
        let rotate = rotation + 90;
        if(rotate >= 360) {
            rotate -= 360;
        }
        setRotation(rotate)
    }, [rotation])

    return (
        <Dialog fullWidth open={props.isOpen}>
            {/* Dialog Content */}
            <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"} sx={{padding: '10px 20px'}}>
                <DialogTitle sx={{padding: 0}}>Image Resizer</DialogTitle>
                <StyledIconButton onClick={rotate90OnClick} label={"rotate 90°"}>
                    <UpdateIcon/>
                </StyledIconButton>
            </Stack>
            <Box id="app-container" sx={{ position: "relative", height: 400 }}>
                <DialogContent sx={{ height: '100%' }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box id="app" sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
                            <Box id="crop-container" sx={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
                            {imageUrl && (
                                    <Cropper
                                        image={imageUrl}
                                        crop={crop}
                                        zoom={zoom}
                                        rotation={rotation}
                                        minZoom={1}
                                        maxZoom={10}
                                        aspect={props.aspect}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={handleOnZoomChange}
                                        onRotationChange={handleOnRotationChange}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Box>

            <DialogActions sx={{ p: 8 }}>
                <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', flexGrow: 1}} gap={8}>
                        
                        <Box flexGrow={2}>
                            {/* Zoom */}
                            <Typography id="non-linear-slider-zoom" margin={0} >
                                Zoom
                            </Typography>
                            <Slider
                                id='zoom_slider'
                                size="small"
                                value={zoom}
                                min={1}
                                max={10}
                                step={0.1}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                onChange={handleOnZoomChange}
                            />
                        </Box>

                        <Box flexGrow={1}>
                            {/* Rotate */}
                            {/* <Typography id="non-linear-slider-rotate" margin={0} >
                                Rotate
                            </Typography> */}
                            {/* <Slider
                                id='rotate_slider'
                                size="small"
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                onChange={handleOnRotationChange} 
                            /> */}
                            <TextBox
                                label="Rotation"
                                placeholder="Enter degrees to rotate"
                                type="number"
                                onChange={handleOnRotationChange}
                            />
                        </Box>

                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1}} gap={4}>
                        <Button variant='outlined' onClick={props.handleClose}>Close</Button>
                        <Button variant='contained' disabled={loading} onClick={showCroppedImage}>OK</Button>
                    </Box>
                </Box>

            </DialogActions>
        </Dialog>
    );
});


export default ImageResizerDialog;